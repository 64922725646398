const TextArea = ({ label = '', value='', onChange = () => {}, placeholder = '', style = {} }) => {
  return(
    <>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className='mt-2'>{label}</div>}
      <textarea value={value} onChange={(e) => onChange(e.target.value)} className='dash-input' placeholder={placeholder} style={{ height: 222, paddingTop: 8, ...style }}>
      </textarea>
    </>
  )
}

export default TextArea;
