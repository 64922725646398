import { IoAlertCircleOutline, IoOpenOutline } from "react-icons/io5";
import { useMeasure } from "react-use";
import {
  StaticGoogleMap,
  Marker,
} from 'react-static-google-map';

export const DetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div className="dash-table-item" style={{ color: "#64666B" }}>
        {title}
      </div>
      <div className="dash-table-item" style={{ fontWeight: 600, ...style }}>
        {value || "Unknown"}
      </div>
    </div>
  );
};

export const EmailDetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div className="dash-table-item" style={{ color: "#64666B" }}>
        {title}
      </div>
      {value && (
        <a href={`mailto:${value}`} className="dash-table-item" style={{ fontWeight: 600, ...style }}>
          {value}
        </a>
      )}
      {!value && (
        <div className="dash-table-item" style={{ fontWeight: 600, ...style }}>
          Unknown
        </div>
      )}
    </div>
  );
};

export const PhoneDetailItem = ({ title, value, style = {} }) => {
  return (
    <div className="jb-ac mt-3 mb-1">
      <div className="dash-table-item" style={{ color: "#64666B" }}>
        {title}
      </div>
      {value && (
        <a href={`tel:${value}`} className="dash-table-item" style={{ fontWeight: 600, ...style }}>
          {value}
        </a>
      )}
      {!value && (
        <div className="dash-table-item" style={{ fontWeight: 600, ...style }}>
          Unknown
        </div>
      )}
    </div>
  );
};

export const MapDetailItem = ({ title, value = {}, style = {} }) => {
  const [ref, { width }] = useMeasure();
  const coords = value?.coords || {};
  const fullAddress = value?.fullAddress;
  const coordinates = coords?.coordinates || [];
  const lat = coordinates[1];
  const lng = coordinates[0];
  const showMap = fullAddress && width;

  const showInMapClicked = () => {
    window.open("https://maps.google.com?q="+lat+","+lng );
  };

  return (
    <div className="mt-3 mb-1" ref={ref}>
      <div className='jb-ac'>
        <div className="dash-table-item" style={{ color: "#64666B" }}>
          {title}
        </div>
        <div className="dash-table-item" style={{ fontWeight: 600, ...style }}>
          {fullAddress || "Unknown"}
        </div>
      </div>
      {showMap && (
        <div style={{ position: 'relative' }} className='mt-2'>
          <StaticGoogleMap size={`${Math.round(width)}x150`} className='brelly-static-map' apiKey="AIzaSyB0qZRRUt8uHwvt28io-NgWIB5xTWQMfxI">
            <Marker size="small" location={fullAddress} color="0x0066cc" label="" />
          </StaticGoogleMap>
          <div onClick={showInMapClicked} style={{ position: 'absolute', right: 8, top: 8, width: 32, height: 32, background: 'white', border: "1px solid #00ACB0", color: '#00ACB0', borderRadius: 4, zIndex: 1000, fontSize: 18 }} className='jc-ac shadow-sm'>
            <IoOpenOutline />
          </div>
        </div>
      )}
    </div>
  );
};

export const EmptyState = ({ type, cb, arr = [] }) => {
  if (arr?.length === 0) {
    return (
      <div className="jc-ac p-2 empty-state-warning" onClick={() => cb(true)}>
        <div style={{ cursor: "pointer" }}>
          <div className="jc-ac">
            No {type} Found
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
