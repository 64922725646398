import { BsHouseDoor } from "react-icons/bs";

const ClaimImg = ({ claim }) => {
  if (claim?.coverPhoto?.downloadUrl) {
    return (
      <img
        alt=""
        style={{
          width: 95,
          height: 95,
          marginRight: 12,
          borderRadius: 10,
          objectFit: "cover",
          flexShrink: 0,
          transition: "all 200ms linear",
        }}
        src={claim?.coverPhoto?.downloadUrl}
      />
    );
  } else {
    return (
      <div
        style={{ width: 95, height: 95, marginRight: 12, borderRadius: 10, background: "#F5F5F5", flexShrink: 0 }}
        className="jc-ac"
      >
        <BsHouseDoor />
      </div>
    );
  }
};

export default ClaimImg;
