import { BsCheck } from 'react-icons/bs';

const ChecklistCheckbox = ({
  value,
  onChange = () => {},
  falseInner,
  styles = {},
  contStyle = {}
}: any) => {
  return(
    <div className='js-ac mb-3' style={{ width: 250, ...contStyle }}>
      <div onClick={(e) => onChange(!value, e)} style={{ marginRight: 12, flexShrink: 0, border: '1px solid #00ACB0', background: value ? '#00ACB0' : 'transparent', width: 28, height: 28, borderRadius: '50%', ...styles }} className='jc-ac'>
        <BsCheck style={{ color: 'white', display: value ? '' : 'none' }} />
      </div>
      <div style={{  }}>{falseInner}</div>
    </div>
  )
}

export default ChecklistCheckbox;
