import brelly from "@brelly/sdk";
import { useApp } from "context";
import { format } from "date-fns";
import Button from "components/Button";
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from "./components";
import { useOnboarding } from './context';

let header = "Great! Is the claim with your insurance company one of the claims below?";
let preheader = "Getting Started";
let boxHeader = "";

const ExistingClaims = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { claims, properties, searchParams, setError, setLoading } = useApp();
  const getLossTypesString = (claim) => claim?.lossTypes?.map((clt) => clt?.type)?.join(", ");

  const handleNone = () => {
    if (properties?.length > 0) {
      setOnboardTab("existingpolicies");
    } else {
      handleUpdate("choosecarrier");
    }
  };

  const handleClaimSelect = async (claim) => {
    try {
      setLoading(true);
      handleUpdate({ claimId: claim?.id, propertyId: claim?.propertyId, policyId: claim?.policyId });
      setOnboardTab("mailingaddress");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue updating your porject, please try again");
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        {claims?.map((claim, key) => (
          <div
            key={key}
            className="jb-ac itemhover"
            style={{ marginBottom: 12, cursor: "pointer" }}
            onClick={() => handleClaimSelect(claim)}
          >
            <DetailItem style={{ width: "20%" }} label="Claim Number" value={claim?.claimNumber || "-"} />
            <DetailItem
              style={{ width: "29%" }}
              label="Address"
              value={claim?.property?.address?.fullAddress || "-"}
            />
            <DetailItem
              style={{ width: "17%" }}
              label="Carrier"
              value={claim?.policy?.insuranceCompany?.name || "-"}
            />
            <LossDate claim={claim} />
            <DetailItem
              style={{ width: "17%" }}
              label="Loss Type"
              value={getLossTypesString(claim) || "-"}
            />
          </div>
        ))}
        <div onClick={handleNone} className="mt-2 brelly-text-btn">
          No, none of these
        </div>
      </OnboardingBox>
      <OnboardingTracker percent="10%" />
    </>
  );
};

export default ExistingClaims;

const LossDate = ({ claim }) => {
  if (claim?.dateOfLoss) {
    return (
      <DetailItem
        style={{ width: "17%" }}
        label="Date of Loss"
        value={format(new Date(claim?.dateOfLoss), "MM/dd/yyyy")}
      />
    );
  } else {
    return <DetailItem style={{ width: "17%" }} label="Date of Loss" value="-" />;
  }
};
