export const LossTypeItem = ({ type }) => {
  return (
    <div
      className="mt-2 brelly-content me-2 dash-table-item"
      style={{
        borderRadius: 10,
        lineHeight: 1,
        fontWeight: 600,
        padding: "10px 16px 8px 16px",
      }}
    >
      {type}
    </div>
  );
};
