import { useState } from "react";
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import Button from "components/Button";
import ChecklistCheckbox from "components/ChecklistCheckbox";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from "./components";
import { useOnboarding } from './context';

let header = "Which best describes the ownership of this property?";
let preheader = "Claim Property";

const Ownership = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { setLoading, setError } = useApp();
  const [ownership, setOwnership] = useState("mortgage");

  const handleOutright = () => {
    setOwnership("outright");
  };

  const handleMortgage = () => {
    setOwnership("mortgage");
  };

  const handleComplete = async () => {
    let propertyId = progress?.propertyId;
    let status = ownership === "outright" ? true : false;

    try {
      setLoading(true);
      await brelly.properties.update(propertyId, { ownedOutright: status });
      handleUpdate({ ownedOutright: status });
      setOnboardTab("lossdate");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox>
        <ChecklistCheckbox
          value={ownership === "outright"}
          onChange={handleOutright}
          falseInner="It is owned outright"
          contStyle={{ width: 500 }}
        />
        <ChecklistCheckbox
          value={ownership === "mortgage"}
          onChange={handleMortgage}
          falseInner="There is a mortgage or deed-of-trust on the property"
          contStyle={{ width: 500 }}
        />
        <Button onClick={handleComplete} styles={{ width: 500, marginTop: 32 }}>
          Confirm
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="60%" />
    </>
  );
};

export default Ownership;
