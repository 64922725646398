import { useEffect, useState } from "react";
import Menu, { MobileMenu } from "./Menu";
import Chat from "./Chat";
import Profile from "./Profile";
import HomePage from "./Claims";
import { useApp } from "context";
import { useWindowSize } from "react-use";
import SearchBar from "components/SearchBar";
import { useHistory } from "react-router-dom";
import { IoMenuOutline } from 'react-icons/io5';

const PHDashboard = () => {
  const history = useHistory();
  const { handleUser, searchParams, setLoading } = useApp();
  const { width, height } = useWindowSize();
  const contentWidth = width > 991 ? width - 260 : width;
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    if (!searchParams.get("tab")) {
      searchParams.set("tab", "home");
      searchParams.delete("project");
      history.replace({ search: searchParams.toString() });
    }

    handleUser();
  }, []);

  return (
    <div
      style={{ width: width, height: height, background: "#ffffff" }}
      className="js-as"
    >
      <Menu />
      <MobileMenu open={mobileMenu} setOpen={setMobileMenu} />
      <div
        style={{ width: contentWidth, height: height, position: "relative" }}
      >
        <Header setMobileMenu={setMobileMenu} />
        <HomePage />
        <Profile />
      </div>
    </div>
  );
};

export default PHDashboard;

const Header = ({ setMobileMenu }) => {
  return (
    <div
      style={{
        height: 80,
        width: '100%',
        cursor: "pointer",
      }}
      className="jb-ac ps-3 ps-lg-0 pe-3 pe-lg-5"
    >
      
      <div style={{ width: 50, flexShrink: 0, height: 44 }} className='je-ac d-lg-none' onClick={() => setMobileMenu(true)}>
        <IoMenuOutline style={{ fontSize: 32, color: 'rgb(0, 172, 176)' }} />
      </div>
    </div>
  );
};
