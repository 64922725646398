import { useState, useEffect } from 'react';
import { useApp } from '../context';
import { BrellyBtn } from 'components/Button';
import { CenterModal } from 'components/Modals';

const Errors = () => {
  const { error, setError } = useApp();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!!error) {
      setTimeout(() => {
        setShow(true)
      }, 300);
    } else {
      setShow(false)
    }
  }, [error])

  const handleDismiss = () => {
    setError('')
  }

  return(
    <CenterModal open={show} handleClose={handleDismiss} blurStyle={{ zIndex: 1999 }} style={{ zIndex: 2000, width: 400  }}>
      <h1>Uh Oh!</h1>
      <div>{error}</div>
      <BrellyBtn className='mt-4' onClick={handleDismiss}>OK</BrellyBtn>
    </CenterModal>
  )
}

export default Errors;

// <IonAlert
//   isOpen={error?.length > 0}
//   onDidDismiss={handleDismiss}
//   header='Uh-oh!'
//   subHeader=''
//   message={error}
//   buttons={['OK']}
// />
