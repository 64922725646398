import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";
import { currentToFloat } from "utils";
import { useClaim } from "pages/Dash/Claims/Claim/context";

export const EditModal = ({ asset, open, handleClose }) => {
  const { setLoading } = useApp();
  const { handleClaim } = useClaim();
  const [address, setAddress] = useState("");
  const [replacement, setReplacement] = useState("");
  const [built, setBuilt] = useState("");
  const [remodeled, setRemodeled] = useState("");
  const [sqft, setSqft] = useState("");

  useEffect(() => {
    if (open) {
      setReplacement(asset?.replacementCost || "");
      setBuilt(asset?.yearBuilt || "");
      setRemodeled(asset?.lastRenovationDate || "");
      setSqft(asset?.sqft || "");
      if (asset?.address) {
        let addy = asset?.address;
        setAddress({ value: { placeId: addy?.placeId }, label: addy?.fullAddress, brellyId: 1 });
      }
    }
  }, [open]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      if (address?.lat) {
        let addressData = await brelly.addresses.create(address);
        await brelly.properties.update(asset?.id, {
          sqft: sqft,
          yearBuilt: built,
          addressId: addressData?.id,
          replacementCost: currentToFloat(replacement),
          lastRenovationDate: remodeled,
        });
      } else {
        await brelly.properties.update(asset?.id, {
          sqft: sqft,
          yearBuilt: built,
          replacementCost: currentToFloat(replacement),
          lastRenovationDate: remodeled,
        });
      }
      handleClaim(false);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Property</div>
      <div style={{ width: 400 }}>
        <LocationPicker value={address} setValue={setAddress} label="Address" />
        <Input
          value={replacement}
          onChange={setReplacement}
          label="Replacement Cost"
          type="number"
          placeholder="$400,000"
        />
        <Input value={built} onChange={setBuilt} label="Year Built" />
        <Input value={remodeled} onChange={setRemodeled} label="Year Last Renovated" />
        <Input value={sqft} onChange={setSqft} label="Square Footage" />
      </div>
      <BrellyBtn className="mt-4" onClick={handleEdit}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
