import { DashPage } from "../components";
import { useEffect } from 'react';
import { useApp } from "context";
import Claims from "./Claims";
import Claim from "./Claim";

const ClaimsPage = () => {
  const { tab, claimId, handleUser } = useApp();

  useEffect(() => {
    handleUser()
  }, [])

  return (
    <DashPage tab={tab} value="home">
      {claimId && <Claim />}
      {!claimId && <Claims />}
    </DashPage>
  );
};

export default ClaimsPage;
