import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { useState } from "react";
import Button from 'components/Button';
import LocationPicker from 'components/LocationPicker2';
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'Last question. What is your preferred mailing address?';
let preheader = 'Mailing Address';
let boxHeader = '';

const MailingAddress = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { setLoading, setError } = useApp();
  const [mailingAddress, setMailingAddress] = useState("");

  const handleNext = async () => {
    try {
      setLoading(true);
      let location = await brelly.addresses.create(mailingAddress);
      handleUpdate({ mailingAddressId: location?.id })
      setOnboardTab("done");
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message)
    }
  }

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        <LocationPicker
          label='Prefered Mailing Address'
          value={mailingAddress}
          style={{ width: 400 }}
          setValue={setMailingAddress}
        />
        <Button onClick={handleNext} styles={{ width: 400, marginTop: 32 }}>
          Confirm
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="95%" />
    </>
  )
}

export default MailingAddress;
