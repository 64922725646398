import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { format } from 'date-fns';
import { useEffect } from 'react';
import Button from 'components/Button';
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'Great! Is the claim with your insurance company one of the claims below ?';
let preheader = 'Getting Started';
let boxHeader = '';

const CanopyPolicies = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setLoading, setError, handleUser } = useApp();
  const properties = progress?.properties;
  let policies = properties?.map((a) => a?.policies?.map((ap) => ({ ...ap, address: a?.address })))?.flat()

  const handleNone = () => {
    setOnboardTab("notcanopypolicy");
  }

  const handleClaimSelect = async (policy) => {
    try {
      setLoading(true);
      let userClaims = await brelly.users.getClaims();
      let policyClaims = userClaims?.filter((up) => up?.policyId === policy?.id)
      handleUpdate({ policyClaims, policyId: policy?.id })
      if (policyClaims?.length > 0) {
        setOnboardTab("canopyclaims");
      } else {
        setOnboardTab("lossdate");
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again")
    }
  }

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        {policies?.map((policy, key) => (
          <div key={key} className='jb-ac itemhover' style={{ marginBottom: 12, cursor: 'pointer' }} onClick={() => handleClaimSelect(policy)}>
            <DetailItem label='Carrier' value={policy?.insuranceCompany?.name} style={{ width: '40%' }} />
            <EffectiveDate policy={policy} />
            <DetailItem label='Policy Type' value={policy?.type || "-"} style={{ width: '25%' }} />
          </div>
        ))}
        <div onClick={handleNone} className='brelly-text-btn'>No, none of these</div>
      </OnboardingBox>
      <OnboardingTracker percent="25%" />
    </>
  )
}

export default CanopyPolicies;

const EffectiveDate = ({ policy }) => {
  if (policy?.effectiveDate) {
    return <DetailItem label='Effective Date' value={format(new Date(policy?.effectiveDate), 'MM/dd/yyyy')} style={{ width: '35%' }} />
  } else {
    return <DetailItem label='Effective Date' value="-" style={{ width: '35%' }} />
  }
}
