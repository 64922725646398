import { CenterModal } from 'components/Modals';
import { BrellyBtn } from 'components/Button';
import { useState, useEffect } from 'react';
import { useClaim } from 'pages/Dash/Claims/Claim/context';
import Attachment from 'components/AttachmentItem';
import { Uploader } from './Simple';
import { useApp } from 'context';

export const AddFileModal = ({
  open,
  onSave,
  accept = ".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg",
  multiple = true,
  handleClose,
}) => {
  const { projectId } = useApp()
  const [fileType, setFileType] = useState('device');
  const [media, setMedia] = useState([]);
  const localMedia = media?.filter((m) => !m?.downloadUrl)

  const handleRemove = (mtr) => {
    let remaining = media?.filter((m) => m?.id !== mtr);
    setMedia(remaining);
  };

  useEffect(() => {
    if (open) {
      setMedia([]);
    }
  }, [open]);

  const handleSave = () => {
    onSave(media)
    handleClose()
  }

  return(
    <CenterModal open={open} handleClose={handleClose} style={{ width: 600 }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Choose Your Files</div>
      <div className='js-ac mt-2'>
        <TabBtn title='From Your Device' value="device" fileType={fileType} onClick={() => setFileType("device")} />
        {projectId && <TabBtn title='From Claim Files' value="media" fileType={fileType} onClick={() => setFileType("media")} />}
      </div>
      {fileType === 'device' && <Uploader media={media} accept={accept} setMedia={setMedia} multiple={multiple} />}
      {fileType === 'media' && <Media value={media} accept={accept} setMedia={setMedia} multiple={multiple} />}

      {fileType === 'device' && (
        <div className='row mt-2'>
          {localMedia?.map((m) => (
            <Attachment key={m?.id} file={m} handleDelete={() => handleRemove(m?.id)} />
          ))}
        </div>
      )}

      <BrellyBtn className='mt-3' onClick={handleSave}>Save</BrellyBtn>
    </CenterModal>
  )
}

export default AddFileModal;

const Media = ({ value, accept, setMedia, multiple }) => {
  const { media } = useClaim();
  const imageSize = (500 - 60) / 6;

  const isSelected = (img) => {
    let valuesId = value?.map((vimg) => vimg?.id);
    return !!valuesId?.includes(img?.id)
  }

  const handleSelect = (img) => {
    if (isSelected(img)) {
      let remaining = value?.filter((eimg) => eimg?.id !== img?.id);
      setMedia(remaining)
    } else {
      setMedia([...value, { ...img, notConnected: true }])
    }
  }

  return(
    <div className='row mt-3'>
      {media?.map((img, key) => <Attachment file={img} key={img?.id} onClick={handleSelect} isSelected={isSelected(img)} />)}
    </div>
  )
}

const TabBtn = ({ value, fileType, onClick, title }) => {
  let isSelected = value === fileType;

  return(
    <div className='me-3' style={{ fontSize: 12, cursor: 'pointer', color: isSelected ? '#0D72C5' : '#64666B', fontWeight: isSelected ? 700 : '' }} onClick={() => onClick(value)}>{title}</div>
  )
}
