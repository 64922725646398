// External Imports
import { useState, useContext, createContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import brelly from "@brelly/sdk";
import { useApp } from "context";

const ProjectContext = createContext();

export default function ProjectContextWrapper({ children }) {
  const { claimId } = useApp();
  const [files, setFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [media, setMedia] = useState([]);
  const [claim, setClaim] = useState({});
  const [reports, setReports] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [payments, setPayments] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [coverages, setCoverages] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [claimTasks, setClaimTasks] = useState([]);
  const [claimEvents, setClaimEvents] = useState([]);
  const [deductibles, setDeductibles] = useState([]);
  const [personalItems, setPersonalItems] = useState([]);

  const policy = claim?.policy;
  const asset = claim?.property;
  const policyId = claim?.policyId;
  const propertyId = claim?.propertyId;
  const policyholder = claim?.policyholder || {};
  console.log(claim);
  const handleItems = async (_propertyId = propertyId) => {
    if (_propertyId) {
      try {
        let { data } = await brelly.properties.getInventoryItems(_propertyId);
        setPersonalItems(data);
      } catch (err) {}
    }
  };

  const handleExpenses = async () => {
    try {
      let { data } = await brelly.claims.getExpenses(claimId);
      setExpenses(data);
    } catch (err) {}
  };

  const handlePayments = async () => {
    try {
      let { data } = await brelly.claims.getPayments(claimId);
      setPayments(data);
    } catch (err) {}
  };

  const handleCoverages = async (_policyId = policyId) => {
    if (_policyId) {
      try {
        let { data } = await brelly.policies.getCoverages(_policyId);
        setCoverages(data?.filter((c) => !c?.deductible));
        setDeductibles(data?.filter((c) => c?.deductible));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClaim = async (all = true) => {
    try {
      let { data } = await brelly.claims.get(claimId);
      setClaim(data);
      if (all) {
        handleItems(data?.propertyId);
        handleCoverages(data?.policyId);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimTasks = async () => {
    try {
      let { data } = await brelly.claims.getTasks(claimId);
      let formatted = data?.map((t) => ({ ...t, type: "TASK" }));
      setClaimTasks(formatted);
    } catch (err) {
      console.log(err);
    }
  };

  const handleClaimEvents = async () => {
    try {
      let { data } = await brelly.claims.getEvents(claimId);
      let formatted = data?.map((t) => ({ ...t, type: "EVENT" }));
      setClaimEvents(formatted);
    } catch (err) {
      console.log(err);
    }
  };

  const handleFiles = async () => {
    try {
      let { data } = await brelly.claims.getFiles(claimId);
      setFiles(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (claimId) {
      handleClaim();
      handleFiles();
      handleExpenses();
      handlePayments();
      // handleClaimTasks();
      // handleClaimEvents();
    }
  }, [claimId]);

  const projectLocalState = {
    asset,
    files,
    links,
    propertyId,
    claim,
    media,
    policy,
    policyId,
    reports,
    coverages,
    contacts,
    expenses,
    payments,
    documents,
    claimTasks,
    claimEvents,
    deductibles,
    personalItems,
    policyholder,
    handleItems,
    handleExpenses,
    handlePayments,
    handleCoverages,
    handleClaim,
    handleFiles,
  };

  return <ProjectContext.Provider value={projectLocalState}>{children}</ProjectContext.Provider>;
}

export function useClaim() {
  return useContext(ProjectContext);
}
