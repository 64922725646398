import { useState } from 'react';
import {coverages} from 'constants';
import { subYears } from 'date-fns';
import { BrellyBtn } from '../../components';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { DropdownSearchBar } from 'components/SearchBar';

import brelly from "@brelly/sdk";
import { useApp } from 'context';

export const ExpenseReportModal = ({ open, handleClose }) => {
  const { setLoading } = useApp();
  const [selectedCoverages, setSelectedCoverages] = useState([]);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const handleCreate = async () => {
    try {
      setLoading(true);
      await brelly.projects.createInventoryDamageReport({ from: from, to: to, selectedCoverages: selectedCoverages })
      setLoading(false);
      handleClose()
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Create Report</div>
      <div style={{ fontSize: 13, paddingLeft: 4, fontFamily: 'Lato' }} className='mt-2'>Select Coverage</div>
      <DropdownSearchBar value={selectedCoverages} onChange={setSelectedCoverages} options={coverages} />
      <DateSelect value={from} onChange={setFrom} label='From' />
      <DateSelect value={to} onChange={setTo} label='To' />
      <BrellyBtn onClick={handleClose} className='mt-4'>Save</BrellyBtn>
    </CenterModal>
  )
}
