import Button from 'components/Button';
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from './components';
import { useOnboarding } from './context';

const NotCanopyPolicy = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const handleConfirm = () => {
    setOnboardTab('policytype')
  }

  return(
    <>
      <OnboardingHeader header="" preheader="" />
      <OnboardingBox>
        <h1 style={{ textAlign: 'center' }}>No problem. With a few additional questions we can easily create a new policy.</h1>
        <Button styles={{ width: 400, marginTop: 32 }} onClick={handleConfirm}>
          Got It
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="20%" />
    </>
  )
}

export default NotCanopyPolicy;
