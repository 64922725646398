const AuthImage = () => {
  return(
    <div
      style={{
        padding: "29px 32px",
        overflow: "hidden",
        width: "50%",
        height: "100vh",
        position: "relative",
      }}
      className="auth-bg d-none d-lg-block"
    >
      <img alt="" src="/assets/logos/brelly-logo-white.png" style={{ width: 80, marginBottom: 24 }} />
      <h1 style={{ color: "white", textShadow: "1px 1px 4px rgba(0,0,0,.1)" }}>
        Insurance claims help has arrived!
      </h1>
      <h3
        style={{
          color: "white",
          maxWidth: 440,
          lineHeight: "24px",
          textShadow: "1px 1px 3px rgba(0,0,0,.1)",
        }}
      >
        Brelly enables policyholders and adjusters to seamlessly collaborate for fast and effective claims.
      </h3>
    </div>
  )
}

const AuthPage = ({ children, title }) => {
  const width = window.innerWidth
  return(
    <div className="jc-ac">
      <AuthImage />
      <div style={{ width: width > 991 ? "50%" : "100%", height: "100vh", background: "white" }} className="jc-ac">
        <div style={{ width: 340, maxWidth: '90%' }}>
          <h1 style={{ color: 'black' }}>{title}</h1>
          {children}
        </div>
      </div>
    </div>
  )
}

export default AuthPage;
