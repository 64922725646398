import { BrellyBtn } from '../../components';
import { CenterModal } from 'components/Modals';

export const ConfirmDeleteModal = ({ open, handleClose }) => {
  const handleDelete = () => {
    handleClose()
  }

  return(
    <CenterModal open={open} handleClose={handleDelete}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Delete</div>
      <div className='dash-table-item mt-3'>Are you sure? This cannot be undone.</div>
      <div className='jb-ac mt-4'>
        <BrellyBtn onClick={handleDelete} style={{ width: '48%', minWidth: 0 }}>Cancel</BrellyBtn>
        <BrellyBtn onClick={handleDelete} style={{ width: '48%', minWidth: 0, background: '#F43428' }}>Delete</BrellyBtn>
      </div>
    </CenterModal>
  )
}
