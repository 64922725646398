import Button from 'components/Button';
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from './components';
import { useOnboarding } from './context';

const CanopyFails = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();

  const handleConfirm = () => {
    setOnboardTab('policytype')
  }

  return(
    <>
      <OnboardingHeader header="" preheader="" />
      <OnboardingBox>
        <h1 style={{ textAlign: 'center' }}>Sorry, it looks like there was an issue accessing your insurance account. No worries though -- with a few additional questions we can easily get your claim up and running.</h1>
        <Button styles={{ width: 400, marginTop: 32 }} onClick={handleConfirm}>
          Got It
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="20%" />
    </>
  )
}

export default CanopyFails;
