import { getFileTypeIcon } from 'components/FileIcons';
import { BsX } from "react-icons/bs";

const Attachment = ({ file, onClick = () => {}, handleDelete = () => {}, isSelected = false, deletable = false }) => {
  if (file?.downloadUrl) {
    return <RemoteAttachment file={file} onClick={onClick} isSelected={isSelected} deletable={deletable} handleDelete={handleDelete} />
  } else {
    return <LocalAttachment file={file} onClick={onClick} deletable={deletable} handleDelete={handleDelete} />
  }
}

export default Attachment;

const RemoteAttachment = ({ file, onClick, isSelected, deletable, handleDelete }) => {
  const size = file?.size || 0;
  const mbs = size / 1000000;

  return(
    <div className='col-3'>
      <div onClick={() => onClick(file)} style={{ width: '100%', borderRadius: 10, padding: 4, position: 'relative', boxShadow: isSelected ? '0px 0px 0px 1px #0D72C5' : '' }} className='brelly-content mt-2'>
        {file?.contentType?.includes('image') && (
          <img alt='' src={file?.downloadUrl} style={{ width: '100%', height: 50, borderRadius: 8, objectFit: 'cover' }} />
        )}
        {!file?.contentType?.includes('image') && (
          <div style={{ width: '100%', height: 50, borderRadius: 8 }} className='jc-ac'>
            {getFileTypeIcon(file?.contentType, { fontSize: 32 })}
          </div>
        )}
        <div className='p-1'>
          <div className='mt-1 truncate1' style={{ fontWeight: 600, fontSize: 12, lineHeight: 1.2 }}>{file?.name}</div>
          <div className='mt-1 mb-1' style={{ fontSize: 10, lineHeight: 1 }}>{mbs.toFixed(2)}mb</div>
        </div>
        {deletable && <DeleteBtn handleDelete={() => handleDelete(file)} />}
      </div>
    </div>
  )
}

const LocalAttachment = ({ file, onClick, deletable, handleDelete }) => {
  const jsFile = file?.file;
  const size = file?.size || 0;
  const mbs = size / 1000000;

  return(
    <div className='col-3'>
      <div onClick={() => onClick(file)} style={{ width: '100%', borderRadius: 10, padding: 4, position: 'relative' }} className='brelly-content mt-2'>
        {jsFile?.type?.includes('image') && (
          <img alt='' src={URL.createObjectURL(jsFile)} style={{ width: '100%', height: 50, borderRadius: 8, objectFit: 'cover' }} />
        )}
        {!jsFile?.type?.includes('image') && (
          <div style={{ width: '100%', height: 50, borderRadius: 8 }} className='jc-ac'>
            {getFileTypeIcon(jsFile?.type, { fontSize: 32 })}
          </div>
        )}
        <div className='p-1'>
          <div className='mt-1 truncate1' style={{ fontWeight: 600, fontSize: 12, lineHeight: 1.2 }}>{jsFile?.name}</div>
          <div className='mt-1 mb-1' style={{ fontSize: 10, lineHeight: 1 }}>{mbs.toFixed(2)}mb</div>
        </div>
        {deletable && <DeleteBtn handleDelete={() => handleDelete(file)} />}
      </div>
    </div>
  )
}

const DeleteBtn = ({ handleDelete }) => {
  return(
    <div
      onClick={handleDelete}
      style={{
        position: "absolute",
        right: 4,
        top: 3,
        width: 20,
        height: 20,
        borderRadius: "50%",
        color: "#0D72C5",
      }}
      className="jc-ac shadow-sm brelly-content"
    >
      <BsX />
    </div>
  )
}
