import { useApp } from 'context';
import brelly from "@brelly/sdk";
import AuthModal from './AuthModal';
import ProPic from 'components/ProPic';
import Button from 'components/Button';
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'Welcome to Brelly!';
let subheader = 'You will use this platform to share files and communicate with your adjuster.';
let boxHeader = 'Please confirm that you would like to share information with the business below';

const ConfirmShare = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const history = useHistory();
  const { user, claims, properties, setLoading, searchParams, setError } = useApp();
  const [authModal, setAuthModal] = useState(false);
  const code = searchParams.get("code");

  let invitation = progress?.invitation || {};
  let project = invitation?.project || {};
  let organization = project?.organization || {};

  const handleInvitation = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.projectInvites.getByCode(code);
      if (data?.cancelled) {
        history.push('/dash?tab=home');
        setError("This onboarding link is no longer valid, pleas request a new one from your professional");
      } else if (data?.completed) {
        history.push('/dash?tab=home');
        setError("Onboarding for this claim has already been completed!");
      } else {
        handleUpdate({ invitation: data, code: code });
        searchParams.set("pid", data?.projectId);
        history.replace({ search: searchParams.toString() });
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError(err?.message);
      if (err?.message === 'This invite has already been completed') {
        history.push('/dash?tab=home');
      }
    }
  }

  useEffect(() => {
    if (code) {
      handleInvitation()
    }

  }, [code])

  const handleNext = async () => {
    if (user?.id) {
      await brelly.projectInvites.accept(invitation?.id, progress?.code);
      if (claims?.length > 0) {
        setOnboardTab('existingclaims')
      } else if (properties?.length > 0) {
        setOnboardTab('existingpolicies')
      } else {
        setOnboardTab('choosecarrier')
      }
    } else {
      setAuthModal(true)
    }
  }

  return(
    <>
      <OnboardingHeader header={header} subheader={subheader} />
      <OnboardingBox header={boxHeader}>
        <ProPic img={organization?.logo?.downloadUrl} user={{ firstName: organization?.name }} size={70} className='mb-1 mt-3' style={{ fontSize: 38, paddingTop: 3, borderRadius: 10 }} />
        <h2 style={{ fontWeight: 600 }}>{organization?.name}</h2>
        <div className='ja-ac mt-3'>
          <Button styles={{ marginRight: 12, width: 280, color: 'red' }} onClick={() => setOnboardTab("incorrect")}>
            Decline
          </Button>
          <Button styles={{ marginLeft: 12, width: 280 }} onClick={handleNext}>
            Correct, let’s proceed!
          </Button>
        </div>
      </OnboardingBox>
      <OnboardingTracker percent="5%" />
      <AuthModal open={authModal} progress={progress} handleUpdate={handleUpdate} handleClose={() => setAuthModal(false)} setOnboardTab={setOnboardTab} />
    </>
  )
}

export default ConfirmShare;
