import { TasksIcon } from "components/Icons";
import { IoCheckmark } from "react-icons/io5";
import { CenterModal } from "components/Modals";
import { format } from "date-fns";
import { useState } from "react";
import { useClaim } from './context';
import brelly from "@brelly/sdk";

const D1 = () => {
  return(
    <div>
      <div className='mb-2'>After the immediate danger has passed, start taking photos and videos of any damage. Before you make repairs. <span style={{ fontWeight: 500, fontStyle: 'italic' }}>Before you clean up.</span> Take both wide-shots and close-ups.</div>
      <div>Preserve any damaged materials – it may be important later in your claim.</div>
    </div>
  )
}

const tasks = [
  { name: 'Document Your Loss', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#document-loss', inAppFeature: true, allApplicable: true, id: 1 },
  { name: 'Notify Your Insurance Company', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#notify-insurer', inAppFeature: false, allApplicable: true, id: 2 },
  { name: 'Make Temporary Repairs', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#prevent-damage', inAppFeature: false, allApplicable: false, id: 3 },
  { name: 'Get Your Complete Policy', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#full-policy', inAppFeature: true, allApplicable: false, id: 4 },
  { name: 'Start Tracking Your Expenses', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#track-expenses', inAppFeature: true, allApplicable: false, id: 5 },
  { name: 'Create Inventory of Damaged Belongings', description: <D1 />, link: 'https://brelly.com/claim-resources/home-inventory-app-insurance-claim/', inAppFeature: true, allApplicable: false, id: 6 },
  { name: 'Insurance Company Inspection', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#insurance-inspection', inAppFeature: false, allApplicable: false, id: 7 },
  { name: 'Connect with Contractors', description: <D1 />, link: 'https://brelly.com/claim-resources/policyholder-guide-to-selecting-contractors/', inAppFeature: true, allApplicable: false, id: 8 },
  { name: 'Update Your Insurance Company as Needed', description: <D1 />, link: 'https://brelly.com/claim-resources/proof-of-loss-explainer/', inAppFeature: false, allApplicable: true, id: 9 },
  { name: 'Get Your Payment; Make Your Repairs', description: <D1 />, link: 'https://brelly.com/claim-resources/property-insurance-claim-guide/#get-paid', inAppFeature: false, allApplicable: true, id: 10 },
]

const ChecklistBox = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 mb-3">
          <div className="dash-box jb-ac">
            <div className="dash-box-title">
              <TasksIcon style={{ marginRight: 8 }} stroke="#00ACB0" />
              Claim Checklist
            </div>
          </div>
        </div>
        <div className="col-12">
          {tasks.map((task, key) => (
            <ChecklistItem key={key} task={task} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ChecklistBox;

const ChecklistItem = ({ task }) => {
  const { claim, handleClaim } = useClaim();
  const [modal, setModal] = useState(false);
  const checklist = claim?.additionalInfo?.checklist || [];
  const taskItem = checklist?.find((t) => t?.id === task?.id);
  const status = taskItem?.status;

  const handleChange = async (e) => {
    e.stopPropagation();

    try {
      let otherAdditional = claim?.additionalInfo || [];
      let otherTasks = checklist?.filter((t) => t?.id !== task?.id);

      await brelly.claims.update(claim?.id, {
        additionalInfo: { ...otherAdditional, checklist: [...otherTasks, { id: task?.id, status: !status }] }
      })
      handleClaim(false)
    } catch(err) {
      console.log(err);
    }
  }

  return (
    <>
      <div
        onClick={() => setModal(true)}
        className="mt-1 brelly-content p-3"
        style={{ borderRadius: 10, background: "#CEF2F3" }}
      >
        <div className="js-ac">
          <div style={{ width: 44, flexShrink: 0 }}>
            <div onClick={handleChange} style={{ width: 28, height: 28, borderRadius: '50%', border: status ? '1px solid rgb(0, 172, 176)' : '1px solid black', background: status ? 'rgb(0, 172, 176)' : '' }} className='jc-ac'>
              {status && <IoCheckmark style={{ fontSize: 24, color: 'white' }} />}
            </div>
          </div>
          <div style={{ width: '100%' }}>{task?.name}</div>
        </div>
      </div>
      <CenterModal open={modal} handleClose={() => setModal(false)}>
        <div className='jc-ac flex-col' style={{ height: "90%" }}>
          <h1 style={{ width: '100%', maxWidth: 400 }}>{task?.name}</h1>
          <div style={{ width: '100%', maxWidth: 400 }}>{task?.description}</div>
          <a style={{ width: '100%', maxWidth: 400 }} className='mt-2' href={task?.link} target='_blank'>Learn More</a>
        </div>
      </CenterModal>
    </>
  );
};

const TableItem = ({ children, width }) => {
  return (
    <div style={{ width: width }} className="dash-table-item">
      {children}
    </div>
  );
};
