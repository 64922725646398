import { IoShareSocialOutline, IoChevronDownOutline } from "react-icons/io5";
import { addYears, addMonths, addWeeks, addHours } from "date-fns";
import { SingleSelect, MultiSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { BrellyBtn } from "components/Button";
import { AddTeamMemberModal } from "pages/Dash/Modals";
import { ConfirmDeleteModal } from "pages/Dash/Modals";
import { useState, useEffect, useRef } from "react";
import { users, contactRoles } from "constants";
import { TrashIcon } from "components/Icons";
import { format, subDays } from "date-fns";
import { useWindowSize } from 'react-use';
import Input from "components/Input";
import Inventory from "./Inventory";
// import Timeline from "./Timeline";
import Expenses from "./Expenses";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import Details from "./Details";
import Files from "./Files";
import Checklist from "./Checklist";
import ProjectContextWrapper, { useClaim } from "./context";
import ClaimImg from 'components/ClaimImg';

const Claim = () => {
  const { claimTab } = useApp();
  const { width } = useWindowSize();
  const headerHeight = width > 991 ? 244 : 208

  return (
    <div style={{ position: "relative", paddingTop: 24 }}>
      <div style={{ width: "100%", background: "#F5F5F5" }}>
        <div className="dash-box">
          <ClaimDashHeader />
        </div>
        <div className="jb-ac mt-3 ps-2 pe-2 brelly-h4" style={{ paddingBottom: 14, overflowX: 'auto' }}>
          {claimTabBtns.map((btn) => (
            <ClaimTabBtn value={btn.value} key={btn.value} title={btn.title} />
          ))}
        </div>
      </div>
      <div
        style={{
          height: `calc(100vh - ${headerHeight}px)`,
          overflowY: "auto",
          overflowX: "hidden",
          paddingBottom: 50,
        }}
      >
        {claimTab === "checklist" && <Checklist />}
        {claimTab === "inventory" && <Inventory />}
        {claimTab === "expenses" && <Expenses />}
        {claimTab === "details" && <Details />}
        {claimTab === "files" && <Files />}
      </div>
    </div>
  );
};

const WrappedClaim = () => {
  return (
    <ProjectContextWrapper>
      <Claim />
    </ProjectContextWrapper>
  );
};

export default WrappedClaim;

const ClaimDashHeader = () => {
  const { claim, asset, handleClaim, setError } = useClaim();
  const { setLoading, setTab } = useApp();
  const [claimStatus, setClaimStatus] = useState("Active");
  const [teamMemberModal, setTeamMemberModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const modalBtnRef = useRef(null);
  const statusModalBtnRef = useRef(null);

  const _teamMembers =
    claim?.contributors?.map((tm, idx) => ({
      ...tm,
      label: `${tm?.first} ${tm?.last}`,
      value: idx + 1,
    })) || [];
  const [teamMembers, setTeamMembers] = useState(_teamMembers);
  const allTeam =
    users?.map((tm, idx) => ({
      ...tm,
      label: `${tm?.first} ${tm?.last}`,
      value: idx + 1,
    })) || [];
  const [deleteModal, setDeleteModal] = useState(false);

  const handleStatus = (newStatus) => {
    setStatusModal(false);
    setClaimStatus(newStatus);
  };

  // const handleDeleteModal = () => {
  //   setActionsModal(false);
  //   setDeleteModal(true);
  // };
  //
  // const handleDelete = async () => {
  //   try {
  //     setLoading(true);
  //     await brelly.claims.delete(claim?.id);
  //     setLoading(false);
  //     setDeleteModal(true);
  //     setTab("claims");
  //   } catch (err) {
  //     console.log(err);
  //     setLoading(false);
  //   }
  // };

  const claimNumber = claim?.claimNumber ? claim.claimNumber : "Unknown";
  const dateOfLoss = claim?.dateOfLoss
    ? format(new Date(claim?.dateOfLoss), "MMM dd, yyyy")
    : "Unknown";

  const handleCoverPhoto = async (ev) => {
    let file = ev.target.files[0];

    if (file) {
      try {
        setLoading(true);
        await brelly.claims.uploadCoverPhoto(claim?.id, file);
        handleClaim()
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    }
  };

  return (
    <div className="jb-as">
      <div className="js-ac" style={{ fontWeight: 600, fontSize: 20 }}>
        <div style={{ position: "relative" }} className='d-none d-lg-block'>
          <ClaimImg claim={claim} />
          <input
            type="file"
            accept="image/*"
            onChange={handleCoverPhoto}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              opacity: 0,
            }}
          />
        </div>
        <div>
          <div style={{ maxWidth: '100%' }} className='truncate1'>{asset?.address?.fullAddress || "ADDRESS"}</div>
          <div style={{ fontSize: 14, fontWeight: 400 }} className='d-none d-lg-block'>
            <div className="js-ac">
              <div>Claim Number:</div>
              <div className="ms-1" style={{ fontWeight: 600 }}>
                {claimNumber}
              </div>
            </div>
            <div className="js-ac">
              <div>Loss Date:</div>
              <div className="ms-1" style={{ fontWeight: 600 }}>
                {dateOfLoss}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ShareClaimModal
        open={shareModal}
        handleClose={() => setShareModal(false)}
      />



    </div>
  );
};

// <BrellyBtn style={{ flexShrink: 0 }} onClick={() => {}} className="ms-3">
//   <div className="jc-ac">
//     <div>{phases?.find((p) => p?.id === project?.currentPhaseId)?.name || "PHASE"}</div>
//     <IoChevronDownOutline style={{ color: "white", marginLeft: 2 }} />
//   </div>
// </BrellyBtn>

// <BrellyBtn
//   onClick={() => setShareModal(true)}
//   style={{
//     color: "rgb(0, 172, 176)",
//     background: "transparent",
//     border: "1px solid rgb(0, 172, 176)",
//     paddingLeft: 4,
//     paddingRight: 4,
//     minWidth: 110,
//   }}
// >
//   <IoShareSocialOutline
//     style={{
//       fontSize: 24,
//       color: "rgb(0, 172, 176)",
//       marginRight: 5,
//     }}
//   />
//   Share
// </BrellyBtn>

const ClaimTabBtn = ({ value, title }) => {
  const { claimTab, setClaimTab } = useApp();
  let isSelected = value === claimTab;

  return (
    <div
      style={{
        cursor: "pointer",
        color: isSelected ? "#00ACB0" : "#64666B",
        fontWeight: isSelected ? 700 : "",
        flexShrink: 0
      }}
      className='me-3 me-lg-0'
      onClick={() => setClaimTab(value)}
    >
      {title}
    </div>
  );
};

const shareTypes = [
  { label: "All", value: 1, all: true },
  { label: "Timeline", value: 2 },
  { label: "Tasks & Events", value: 3 },
  { label: "Claim Details", value: 4 },
  { label: "Claim Contacts", value: 5 },
  { label: "Documents", value: 6 },
  { label: "Photos & Videos", value: 7 },
  { label: "Contents Inventory", value: 8 },
  { label: "Claim Finances", value: 9 },
  { label: "Reports", value: 10 },
  { label: "Claim Emails", value: 11 },
];
const shareLengths = [
  { label: "2 Years", value: 1 },
  { label: "1 Year", value: 2 },
  { label: "6 Months", value: 3 },
  { label: "1 Month", value: 4 },
  { label: "48 Hours", value: 5 },
  { label: "24 Hours", value: 6 },
  { label: "1 Week", value: 7 },
];

const ShareClaimModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [type, setType] = useState([{ label: "All", value: 1, all: true }]);
  const [end, setEnd] = useState({ label: "2 Years", value: 1 });
  const [role, setRole] = useState({ label: "Choose", value: 0 });

  const [shares, setShares] = useState([
    {
      id: 1,
      email: "reed@brelly.com",
      start: "May 28, 2023",
      end: "Dec 28, 2023",
      active: true,
      type: "All",
    },
    {
      id: 2,
      email: "harry@brelly.com",
      start: "May 14, 2023",
      end: "Dec 14, 2023",
      active: false,
      type: "Loss Type",
    },
  ]);

  const reset = () => {
    setEmail("");
    setType([{ label: "All", value: 1 }]);
    setEnd({ label: "2 Years", value: 1 });
  };

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);
  //20 on over 10.5 wins
  const addShare = () => {
    const getEnd = () => {
      if (end?.value === 1) {
        return format(addYears(new Date(), 2), "MMM dd, yyyy");
      } else if (end?.value === 2) {
        return format(addYears(new Date(), 1), "MMM dd, yyyy");
      } else if (end?.value === 3) {
        return format(addMonths(new Date(), 6), "MMM dd, yyyy");
      } else if (end?.value === 4) {
        return format(addMonths(new Date(), 1), "MMM dd, yyyy");
      } else if (end?.value === 5) {
        return format(addWeeks(new Date(), 1), "MMM dd, yyyy");
      } else if (end?.value === 6) {
        return format(addHours(new Date(), 48), "MMM dd, yyyy");
      } else if (end?.value === 7) {
        return format(addHours(new Date(), 24), "MMM dd, yyyy");
      }
    };

    setShares([
      {
        email: email,
        start: format(new Date(), "MMM dd, yyyy"),
        end: getEnd(),
        active: true,
        type: type?.label,
        id: shares?.length + 1,
      },
      ...shares,
    ]);
    reset();
  };

  const deleteShare = (shareId) => {
    let remaining = shares?.filter((s) => s?.id !== shareId);
    setShares(remaining);
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Share Claim Info</div>
      <div className="jb-ac" style={{ width: 700 }}>
        <div style={{ width: "49%" }}>
          <Input
            value={email}
            onChange={setEmail}
            placeholder="bill.williams@abcroofers.com"
            label="Add Email"
          />
        </div>
        <div style={{ width: "49%" }}>
          <SingleSelect
            label="Role"
            value={role}
            onChange={setRole}
            options={contactRoles}
          />
        </div>
      </div>
      <div className="jb-ac">
        <div style={{ width: "49%" }}>
          <MultiSelect
            label="Select claim info to share"
            value={type}
            onChange={setType}
            options={shareTypes}
            style={{ width: "100%", maxWidth: "100%" }}
          />
        </div>
        <div style={{ width: "49%" }}>
          <SingleSelect
            label="Set expiration date"
            value={end}
            onChange={setEnd}
            options={shareLengths}
            style={{ width: "100%", maxWidth: "100%" }}
          />
        </div>
      </div>
      <BrellyBtn onClick={addShare} className="mt-4">
        Share
      </BrellyBtn>
      <div
        style={{ width: "100%", borderTop: "1px solid #eeeeee" }}
        className="mt-4"
      >
        <div className="js-ac mt-3">
          <div style={{ width: "30%" }} className="dash-table-header">
            Email
          </div>
          <div style={{ width: "20%" }} className="dash-table-header">
            Shared
          </div>
          <div style={{ width: "20%" }} className="dash-table-header">
            Link Expiration
          </div>
          <div style={{ width: "20%" }} className="dash-table-header">
            Shared Info
          </div>
          <div style={{ width: "10%" }} className="dash-table-header">
            Active
          </div>
        </div>

        {shares?.map((s, key) => (
          <ShareItem share={s} key={key} deleteShare={deleteShare} />
        ))}
      </div>
    </CenterModal>
  );
};

const ShareItem = ({ share, deleteShare }) => {
  return (
    <div className="js-ac mt-2">
      <div
        style={{ width: "30%", textTransform: "lowercase" }}
        className="dash-table-item"
      >
        {share?.email}
      </div>
      <div style={{ width: "20%" }} className="dash-table-item">
        {share?.start}
      </div>
      <div style={{ width: "20%" }} className="dash-table-item">
        {share?.end}
      </div>
      <div style={{ width: "20%" }} className="dash-table-item">
        {share?.type}
      </div>
      <div style={{ width: "10%" }} className="dash-table-item js-ac">
        <MiniCheckBox value={share?.active} />
        <div
          style={{ marginLeft: 12, paddingBottom: 1 }}
          onClick={() => deleteShare(share?.id)}
        >
          <TrashIcon stroke="#ff0000" size={18} />
        </div>
      </div>
    </div>
  );
};

const MiniCheckBox = ({ value, onChange = () => {} }) => {
  return (
    <div
      style={{
        width: 26,
        height: 16,
        background: value ? "#00ACB0" : "",
        borderRadius: 10,
        padding: 1,
        border: value ? "1px solid transparent" : "1px solid #00ACB0",
      }}
      className={value ? "je-ac" : "js-ac"}
    >
      <div
        style={{
          background: value ? "white" : "#00ACB0",
          borderRadius: "50%",
          height: 12,
          width: 12,
        }}
        className="shadow-sm"
      />
    </div>
  );
};

const claimTabBtns = [
  { value: "checklist", title: "Claim Checklist" },
  // { value: "timeline", title: "Timeline" },
  { value: "details", title: "Claim Details" },
  { value: "files", title: "Files" },
  { value: "inventory", title: "Inventory Builder" },
  { value: "expenses", title: "Expense Builder" },
];

const statusColors = {
  Active: "#00ACB0",
  "On Hold": "rgb(255, 168, 0)",
  "Needs Attention": "rgb(205, 0, 0)",
  Archived: "#64666B",
};
