import { useClaim } from "pages/Dash/Claims/Claim/context";
import { lossTypes as lossTypesOptions } from "constants";
import { MultiSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { DateSelect } from "components/Select";
import { BrellyBtn } from "components/Button";
import { useState, useEffect } from "react";
import Input from "components/Input";
import brelly from "@brelly/sdk";
import { useApp } from "context";

export const EditModal = ({ open, lossTypes, handleClose }) => {
  const { claim, handleClaim } = useClaim();
  const { setLoading } = useApp();
  const [claimNum, setClaimNum] = useState("");
  const [dol, setDol] = useState(new Date());

  useEffect(() => {
    if (open) {
      setClaimNum(claim?.claimNumber)
      if (claim?.dateOfLoss) {
        setDol(new Date(claim?.dateOfLoss))
      }
    }
  }, [open])

  let starting = lossTypesOptions?.filter((lto) =>
    lossTypes?.includes(lto?.label)
  );
  const [localValue, setLocalValue] = useState(starting);

  const handleSave = async () => {
    try {
      setLoading(true);
      let formatted = localValue?.map((lt) => lt?.label);
      let str = formatted?.toString(", ");
      await brelly.claims.update(claim?.id, { perilType: str, claimNumber: claimNum, dateOfLoss: dol });
      handleClaim();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20, marginBottom: 12 }}>
        Edit Claim Details
      </div>
      <Input label="Claim Number" value={claimNum} onChange={setClaimNum} />
      <DateSelect
        value={dol}
        onChange={setDol}
        label="Date of Loss"
      />
      <MultiSelect
        options={lossTypesOptions}
        label="Loss Types"
        value={localValue}
        onChange={setLocalValue}
      />
      <BrellyBtn style={{ width: 400 }} className="mt-4" onClick={handleSave}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
