import { useApp } from "context";
import brelly from "@brelly/sdk";
import { bytesToMb, isDocument } from 'utils';
import { BsX, BsFiletypePdf, BsFiletypeJpg, BsFiletypeMp4, BsFiletypePng, BsFiletypePpt, BsFiletypeTxt, BsFiletypeSvg, BsFiletypeMov, BsFiletypeDoc, BsFiletypeDocx } from "react-icons/bs";
import { IoGridOutline, IoListOutline } from "react-icons/io5";
import { Portal } from "react-portal";
import { useClaim } from "./context";
import { useMeasure } from "react-use";
import { useState, useRef } from "react";
import CheckBox from "components/CheckBox";
import { PhotoIcon, TrashIcon } from "components/Icons";
import { InlineModal } from "components/Modals";
import { SimpleFileModal, ConfirmDeleteModal } from "pages/Dash/Modals";
import {
  IoPlay,
  IoCloudDownloadOutline,
  IoChevronDownOutline,
} from "react-icons/io5";

const initialFilters = ["docs", "photos", "videos"]

const Files = () => {
  const { files, claim, handleFiles } = useClaim();
  const { setLoading } = useApp();
  const [filterModal, setFilterModal] = useState(false);
  const [filterType, setFilterType] = useState(initialFilters);
  const [addModal, setAddModal] = useState(false);
  const modalBtnRef = useRef(null);
  const [viewType, setViewType] = useState("grid");

  const getFilteredImgs = () => {
    let preFiltered = files || [];

    if (!filterType?.includes("docs")) {
      preFiltered = preFiltered?.filter((f) => !isDocument(f))
    }

    if (!filterType?.includes("photos")) {
      preFiltered = preFiltered?.filter((f) => !f.contentType.includes("image"))
    }

    if (!filterType?.includes("videos")) {
      preFiltered = preFiltered?.filter((f) => !f.contentType.includes("video"))
    }

    return preFiltered
  };

  const handleNewMedia = async (newMedia) => {
    try {
      setLoading(true);
      await brelly.claims.uploadFiles(claim?.id, newMedia);
      await handleFiles();
      setLoading(false);
      setAddModal(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const ItemSwitch = ({file}) => {
    let { contentType } = file;

    if (viewType === 'grid') {
      if (contentType?.includes("image")) {
        return <ImgItem img={file} />
      } else if (isDocument(file)) {
        return <DocItem doc={file} />
      } else if (contentType?.includes("video")) {
        return <VideoItem vid={file} />
      } else {
        return null;
      }
    } else {
      return <ListItem file={file} />
    }
  }

  const handleFilter = (type) => {
    if (filterType?.includes(type)) {
      let remaining = filterType?.filter((ft) => ft !== type);
      setFilterType(remaining)
    } else {
      setFilterType([...filterType, type])
    }
  }

  return (
    <>
      <div className="dash-box">
        <div className="jb-ac">
          <div className="dash-box-title">
            <PhotoIcon
              style={{ marginRight: 8, marginBottom: 2 }}
              stroke="#00adb0"
            />
            Files
          </div>
          <div className="jc-ac d-none d-lg-flex" style={{ width: 80, height: 40, background: "#f5f5f5", borderRadius: 10 }}>
            <div onClick={() => setViewType('grid')} style={{ height: 38, width: 38, fontSize: 22, color: viewType === 'grid' ? "#00adb0" : "", background: viewType === 'grid' ? 'white' : '', borderRadius: 9, boxShadow: viewType === 'grid' ? '0px 0px 3px rgba(0,0,0,.15)' : '' }} className='jc-ac'>
              <IoGridOutline />
            </div>
            <div onClick={() => setViewType('list')} style={{ height: 38, width: 38, fontSize: 22, color: viewType === 'list' ? "#00adb0" : "", background: viewType === 'list' ? 'white' : '', borderRadius: 9, boxShadow: viewType === 'list' ? '0px 0px 3px rgba(0,0,0,.15)' : '' }} className='jc-ac'>
              <IoListOutline />
            </div>
          </div>
          <div className="je-ac">
            <div
              ref={modalBtnRef}
              className="brelly-text-btn me-3 js-ac d-none d-lg-flex"
              style={{ color: "#00adb0" }}
              onClick={() => setFilterModal(true)}
            >
              File Type
              <IoChevronDownOutline />
            </div>
            <div
              className="brelly-text-btn"
              style={{ color: "#00adb0" }}
              onClick={() => setAddModal(true)}
            >
              Add New
            </div>
          </div>
        </div>
      </div>
      <div className={viewType === "grid" ? 'row' : "container"}>
        {getFilteredImgs().map((file, key) => (
          <ItemSwitch key={key} file={file} />
        ))}
      </div>
      <SimpleFileModal
        onSave={handleNewMedia}
        open={addModal}
        handleClose={() => setAddModal(false)}
        accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, .xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg"
      />
      <InlineModal
        btnRef={modalBtnRef}
        open={filterModal}
        handleClose={() => setFilterModal(false)}
      >
        <CheckBox
          title="Documents"
          value={filterType?.includes("docs")}
          setValue={() => handleFilter("docs")}
          className="mb-3 pe-3"
        />
        <CheckBox
          title="Photos"
          value={filterType?.includes("photos")}
          setValue={() => handleFilter("photos")}
          className="mb-3 pe-3"
        />
        <CheckBox
          title="Videos"
          value={filterType?.includes("videos")}
          setValue={() => handleFilter("videos")}
          className="pe-3"
        />
      </InlineModal>
    </>
  );
};

export default Files;

const ListItem = ({ file = {} }) => {
  // const [actionsModal, setActionsModal] = useState(false);
  // const [shareModal, setShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const modalBtnRef = useRef(null);

  const handleDeleteModal = () => {
    setDeleteModal(true);
    // setActionsModal(false);
  }

  return(
    <>
      <div className='mt-2 row' style={{ borderRadius: 15, height: 50, cursor: 'pointer', background: 'white', padding: '10px 20px 10px 0px' }}>
        <div className='dash-table-item col-1 jc-ac' style={{ fontSize: 24 }}>
          {getFileTypeIcon(file)}
        </div>
        <div className='dash-table-item col-7 js-ac'>{file?.name}</div>
        <div className='dash-table-item col-2 je-ac'>{bytesToMb(file?.size)}</div>
        <div className='col-2 je-ac'>
          <div className='me-3'>
            <IoCloudDownloadOutline style={{ fontSize: 22, color: "#00adb0" }} />
          </div>
          <div onClick={handleDeleteModal}>
            <TrashIcon />
          </div>
        </div>
      </div>
      <ConfirmDeleteModal open={deleteModal} handleClose={() => setDeleteModal(false)} />
    </>
  )
}

const VideoItem = ({ vid = {} }) => {
  const [ref, { width }] = useMeasure();
  const { setLoading, setError } = useApp();
  const { handleFiles } = useClaim();
  // const [actionsModal, setActionsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);
  // const modalBtnRef = useRef(null);

  const handleSelect = () => {
    setModal(true);
    setHover(false);
  };

  // const handleActionsModal = (e) => {
  //   e.stopPropagation();
    // setActionsModal(true);
  // };

  const handleDeleteModal = (e) => {
    e.stopPropagation();
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.files.delete(vid?.id);
      await handleFiles()
      setLoading(false);
    } catch (err) {
      setError("There was an issue deleting your file, please try again")
      setLoading(false);
    }
  };

  const closePortal = (e) => {
    e.stopPropagation();
    setModal(false)
  }

  return (
    <div className="col-6 col-lg-3 col-xl-2 mt-3">
      <div
        ref={ref}
        className="shadow-sm"
        style={{ position: "relative", overflow: 'hidden', height: width, position: 'relative' }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
          <IoPlay style={{ fontSize: 32, color: 'rgba(255,255,255,.9)', filter: 'drop-shadow(2px 2px 3px rgba(0,0,0,.3))' }} />
        </div>
        <video
          style={{
            width: "100%",
            height: '100%',
            objectFit: "cover",
            borderRadius: 10,
            marginBottom: 0
          }}
        >
          <source  src={vid?.downloadUrl} type="video/mp4" />
        </video>
        {hover && (
          <div
            onClick={handleSelect}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,.4)",
              borderRadius: 10,
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              color: "white",
              fontFamily: "Lato",
              fontWeight: 600,
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <div className="je-ac" style={{ width: "100%" }}>
              <a href={vid?.downloadUrl} onClick={(e) => e.stopPropagation()} target="_blank" rel="noreferrer" className="jc-ac me-2">
                <IoCloudDownloadOutline style={{ fontSize: 22, color: 'white' }} />
              </a>
              <div className="jc-ac" onClick={handleDeleteModal}>
                <TrashIcon stroke="#fff" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: 10 }}>{bytesToMb(vid?.size)}</div>
              <div className='truncate1' style={{ lineHeight: 1 }}>{vid?.name}</div>
            </div>
          </div>
        )}
      </div>

      {modal && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "black",
            }}
            className="jc-ac"
          >
            <div
              style={{ position: "fixed", right: 6, top: 6, color: "white", padding: 10, zIndex: 1000 }}
              onClick={closePortal}
            >
              <BsX style={{ fontSize: 24 }} />
            </div>
            <video
              controls
              style={{
                height: '100%',
                objectFit: "contain",
                maxWidth: '100%'
              }}
            >
              <source  src={vid?.downloadUrl} type="video/mp4" />
            </video>
          </div>
        </Portal>
      )}
      <ConfirmDeleteModal open={deleteModal} onDelete={handleDelete} handleClose={() => setDeleteModal(false)} />
    </div>
  );
};

const ImgItem = ({ img }) => {
  const [ref, { width }] = useMeasure();
  const { setLoading, setError } = useApp();
  const { handleFiles } = useClaim();
  // const [actionsModal, setActionsModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);
  // const modalBtnRef = useRef(null);

  const handleSelect = () => {
    setModal(true);
    setHover(false);
  };

  // const handleActionsModal = (e) => {
  //   e.stopPropagation();
  //   setActionsModal(true);
  // };

  const handleDeleteModal = (e) => {
    e.stopPropagation();
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.files.delete(img?.id);
      await handleFiles()
      setLoading(false);
    } catch (err) {
      setError("There was an issue deleting your file, please try again")
      setLoading(false);
    }
  };

  return (
    <div className="col-6 col-lg-3 col-xl-2 mt-3">
      <div
        ref={ref}
        style={{ position: "relative" }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img
          src={img.downloadUrl}
          alt=""
          style={{
            width: "100%",
            height: width,
            objectFit: "cover",
            borderRadius: 10,
          }}
          className="shadow-sm"
        />
        {hover && (
          <div
            onClick={handleSelect}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              background: "rgba(0,0,0,.4)",
              borderRadius: 10,
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              color: "white",
              fontFamily: "Lato",
              fontWeight: 600,
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <div className="je-ac" style={{ width: "100%" }}>
              <a href={img?.downloadUrl} rel="noreferrer" onClick={(e) => e.stopPropagation()} target="_blank" className="jc-ac me-2">
                <IoCloudDownloadOutline style={{ fontSize: 22, color: 'white' }} />
              </a>
              <div className="jc-ac" onClick={handleDeleteModal}>
                <TrashIcon stroke="#fff" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: 10 }}>{bytesToMb(img.size)}</div>
              <div className='truncate1' style={{ lineHeight: 1 }}>{img?.name}</div>
            </div>
          </div>
        )}
      </div>

      {modal && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "black",
            }}
            className="jc-ac"
          >
            <div
              style={{ position: "fixed", right: 16, top: 16, color: "white" }}
              onClick={() => setModal(false)}
            >
              <BsX style={{ fontSize: 24 }} />
            </div>
            <img
              src={img.downloadUrl}
              alt=""
              style={{ height: "100%", objectFit: "contain", maxWidth: "100%" }}
            />
          </div>
        </Portal>
      )}
      <ConfirmDeleteModal open={deleteModal} onDelete={handleDelete} handleClose={() => setDeleteModal(false)} />
    </div>
  );
};

const DocItem = ({ doc = {} }) => {
  const [ref, { width }] = useMeasure();
  // const [actionsModal, setActionsModal] = useState(false);
  // const [shareModal, setShareModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const modalBtnRef = useRef(null);
  const { setLoading, setError } = useApp();
  const { handleFiles } = useClaim();

  const handleDeleteModal = () => {
    setDeleteModal(true);
    // setActionsModal(false);
  };

  const showDoc = () => {
    window.open(doc.downloadUrl);
  };

  // const handleActionsModal = (e) => {
  //   e.stopPropagation();
  //   setActionsModal(true);
  // };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await brelly.files.delete(doc?.id);
      await handleFiles()
      setLoading(false);
    } catch (err) {
      setError("There was an issue deleting your file, please try again")
      setLoading(false);
    }
  };

  return (
    <>
      <div onClick={showDoc} className="col-6 col-lg-3 col-xl-2 mt-3">
        <div ref={ref} style={{ width: '100%', height: width, position: 'relative', background: 'white', borderRadius: 10 }} className='shadow-sm'>
          <div className='jc-ac' style={{ width: '100%', height: width - 50, paddingTop: 30 }}>
            <BsFiletypePdf style={{ fontSize: 32 }} />
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              borderRadius: 10,
              padding: 10,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              color: "black",
              fontFamily: "Lato",
              fontWeight: 600,
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <div className="je-ac" style={{ width: "100%" }}>
              <a href={doc?.downloadUrl} rel="noreferrer" onClick={(e) => e.stopPropagation()} target="_blank" className="jc-ac me-2">
                <IoCloudDownloadOutline style={{ fontSize: 22, color: 'black' }} />
              </a>
              <div className="jc-ac" onClick={handleDeleteModal}>
                <TrashIcon stroke="#000" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ fontSize: 10 }}>{bytesToMb(doc.size)}</div>
              <div className='truncate1' style={{ lineHeight: 1 }}>{doc.name}</div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal open={deleteModal} onDelete={handleDelete} handleClose={() => setDeleteModal(false)} />
    </>
  );
};

const getFileTypeIcon = (file) => {
  let { contentType } = file;

  if (contentType?.includes("pdf")) {
    return <BsFiletypePdf />
  } else if (contentType?.includes("jpg") || contentType?.includes("jpeg")) {
    return <BsFiletypeJpg />
  } else if (contentType?.includes("mp4")) {
    return <BsFiletypeMp4 />
  } else if (contentType?.includes("png")) {
    return <BsFiletypePng />
  } else if (contentType?.includes("ppt") || contentType?.includes("pptx")) {
    return <BsFiletypePpt />
  } else if (contentType?.includes("txt")) {
    return <BsFiletypeTxt />
  } else if (contentType?.includes("svg")) {
    return <BsFiletypeSvg />
  } else if (contentType?.includes("mov") || contentType?.includes("quicktime")) {
    return <BsFiletypeMov />
  } else if (contentType?.includes("docx")) {
    return <BsFiletypeDocx />
  } else if (contentType?.includes("doc")) {
    return <BsFiletypeDoc />
  } else {
    return null;
  }
}
