import { useState } from "react";
import Input from 'components/Input';
import Button from 'components/Button';
import ChecklistCheckbox from 'components/ChecklistCheckbox';
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { useOnboarding } from './context';

const Incorrect = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const [option, setOption] = useState("");
  const [custom, setCustom] = useState("");

  const handleOption = (option) => {
    setCustom("")
    setOption(option)
  }

  const handleCustom = (newVal) => {
    setOption("")
    setCustom(newVal)
  }

  const handleConfirm = () => {
    setOnboardTab('policytype')
  }

  return(
    <>
      <OnboardingHeader header="We’re sorry about that." subheader="Please tell us what information is incorrect." />
      <OnboardingBox>
        <ChecklistCheckbox value={option === 'wrongname'} onChange={() => handleOption('wrongname')} falseInner='The name of the adjuster is wrong' contStyle={{ width: 500 }} />
        <ChecklistCheckbox value={option === 'dontshare'} onChange={() => handleOption('dontshare')} falseInner='I don’t want to share my information with my adjuster' contStyle={{ width: 500 }} />
        <ChecklistCheckbox value={option === 'noadjuster'} onChange={() => handleOption('noadjuster')} falseInner='I am not using a public adjuster on my claim' contStyle={{ width: 500 }} />
        <Input
          label='Other'
          value={custom}
          onChange={handleCustom}
          style={{ width: 400 }}
        />
        <div className='ja-ac mt-3'>
          <Button styles={{ marginRight: 12, width: 280 }} onClick={() => setOnboardTab("confirmshare")}>
            Go Back
          </Button>
          <Button styles={{ marginLeft: 12, width: 280 }} onClick={handleConfirm}>
            Submit
          </Button>
        </div>
      </OnboardingBox>
    </>
  )
}

export default Incorrect;
