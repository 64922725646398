// External Imports
import { useState, useContext, createContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePayees } from "hooks";
import { v4 as uuidv4 } from "uuid";

// Internal Imports
import brelly from "@brelly/sdk";

const AppContext = createContext();

export default function AppContextWrapper({ children, outletRef, network, routerRef }) {
  const { payees } = usePayees();
  const { search } = useLocation();
  const history = useHistory();
  const [user, setUser] = useState({});
  const [error, setError] = useState("");
  const [load, setLoading] = useState(false);
  const [toasts, setToasts] = useState([]);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const tab = searchParams.get("tab");
  const [claims, setClaims] = useState([]);
  const [properties, setProperties] = useState([]);
  const claimId = searchParams.get("claim");
  const claimTab = searchParams.get("claimtab");
  const claim = claims?.find((c) => c?.id === claimId);
  const userId = user?.id;

  const addToast = (msg) => {
    setToasts([...toasts, { message: msg, id: uuidv4(), time: new Date(), open: true }]);
  };

  const checkUserAuth = async () => {
    try {
      let { data } = await brelly.users.get();
      if (data?.id) {
        const code = searchParams.get("code");
        if (code) {
          history.push(`/onboarding?code=${code}`);
        } else {
          history.push(`/dash?tab=home`);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUser = async () => {
    try {
      let [userData, claimData, assetData] = await Promise.all([
        brelly.users.get(),
        brelly.users.getClaims(),
        brelly.users.getProperties(),
      ]);
      setUser(userData);
      setClaims(claimData);
      setProperties(assetData);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogout = async () => {
    setUser({});
    await brelly.auth.signout();
  };

  const setTab = (newTab) => {
    searchParams.set("tab", newTab);
    searchParams.delete("claim");
    searchParams.delete("claimtab");
    history.replace({ search: searchParams.toString() });
  };

  const setClaim = (claimId) => {
    searchParams.set("tab", "home");
    searchParams.set("claim", claimId);
    searchParams.set("claimtab", "checklist");
    history.replace({ search: searchParams.toString() });
  };

  const setClaimTab = (claimTab) => {
    searchParams.set("claimtab", claimTab);
    history.replace({ search: searchParams.toString() });
  };

  const appLocalState = {
    // state
    tab,
    user,
    load,
    error,
    claims,
    payees,
    toasts,
    claimId,
    claimTab,
    outletRef,
    properties,
    searchParams,
    // functions
    setTab,
    setUser,
    setError,
    setClaim,
    addToast,
    setToasts,
    handleUser,
    setLoading,
    setClaimTab,
    handleLogout,
    checkUserAuth,
  };

  return <AppContext.Provider value={appLocalState}>{children}</AppContext.Provider>;
}

export function useApp() {
  return useContext(AppContext);
}
