import { useApp } from "context";
import brelly from "@brelly/sdk";
import Button from "components/Button";
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from "./components";
import { useOnboarding } from './context';
import { format } from 'date-fns';

let header = "Is your insurance claim one of the claims listed below?";
let preheader = "Insurance Claims";
let boxHeader = "";

const CanopyClaims = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setLoading, setError } = useApp();
  let claims = progress?.policyClaims;

  const handleNone = () => {
    setOnboardTab("lossdate");
  };

  const handleClaimSelect = async (claim) => {
    try {
      setLoading(true);
      handleUpdate({ claimId: claim?.id, propertyId: claim?.propertyId, policyId: claim?.policyId });
      setOnboardTab("mailingaddress");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again");
    }
  };

  const getLossTypes = (claim) => {
    return claim?.lossTypes?.map((lt) => lt?.type)?.join(", ")
  }

  const getDate = (claim) => {
    if (claim?.dateOfLoss) {
      return format(new Date(claim?.dateOfLoss), "MM/dd/yyyy")
    }
  }

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        {claims?.map((claim, key) => (
          <div
            key={key}
            className="jb-ac itemhover"
            style={{ marginBottom: 12, cursor: "pointer" }}
            onClick={() => handleClaimSelect(claim)}
          >
            <DetailItem label="Claim Number" value={claim?.claimNumber} style={{ width: '20%' }} />
            <DetailItem label="Address" value={claim?.property?.address?.fullAddress} style={{ width: '35%' }} />
            <DetailItem label="Carrier" value={claim?.policy?.insuranceCompany?.name} style={{ width: '15%' }} />
            <DetailItem label="Date of Loss" value={getDate(claim)} style={{ width: '15%' }} />
            <DetailItem label="Loss Type" value={getLossTypes(claim)} style={{ width: '15%' }} />
          </div>
        ))}
        <div onClick={handleNone} className="brelly-text-btn">
          No, none of these
        </div>
      </OnboardingBox>
      <OnboardingTracker percent="60%" />
    </>
  );
};

export default CanopyClaims;
