// External Imports
import { useState, useEffect } from "react";
import { useHistory } from "react-router";

// Internal Imports
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
// import Modal from "components/Modal";
import CheckBox from "components/CheckBox";
import AuthPage from 'components/AuthImage';
import { BrellyBtn } from "components/Button";
import PhonePicker from 'components/PhoneNumber';

const Register = () => {
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'setError' does not exist on type 'unknow... Remove this comment to see the full error message
  const { setError, setLoading } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [tncModal, setTncModal] = useState(false);
  const [ppModal, setPPModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sms, setSms] = useState(true);

  const handleRegister = async () => {
    if (!terms) {
      setError("You must agree to the terms & conditions to register");
    } else if (email?.length < 1) {
      setError("Email cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else if (password?.length < 7) {
      setError("Please create a password with at least 7 characters");
    } else if (firstName?.length < 1) {
      setError("Please add your first name");
    } else if (lastName?.length < 1) {
      setError("Please add your last name");
    } else {
      setLoading(true);

      try {
        const data = await brelly.auth.signup(email.toLowerCase(), password, {
          phoneNumber,
          firstName,
          lastName,
          sms,
        });

        if (data && !data.userConfirmed) {
          //push to verify screen
          setLoading(false);
          history.push("/verify");
        } else {
          setLoading(false);
          setError("There was an issue logging in, please try again");
        }
      } catch (err) {
        setLoading(false);
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        setError(err.message);
      }
    }
  };

  return (
    <AuthPage title='Sign Up'>
      <Input label='First Name' value={firstName} onChange={setFirstName} />
      <Input label='Last Name' value={lastName} onChange={setLastName} />
      <PhonePicker label='Phone Number' value={phoneNumber} onChange={setPhoneNumber} />
      <Input label='Email' value={email} onChange={setEmail} />
      <Input label='Password' value={password} onChange={setPassword} type='password' />
      <Input style={{ marginBottom: 18 }} label='Confirm Password' value={confirmPassword} onChange={setConfirmPassword} type='password' />
      <CheckBox value={terms} setValue={setTerms} title={<div>I agree to Brelly’s <a target="_blank" rel="noreferrer" href='https://brelly.com/terms-of-use/'>Terms of Service</a> and <a target="_blank" rel="noreferrer" href="https://brelly.com/privacy-policy/">Privacy Policy.</a></div>} />
      <CheckBox value={sms} setValue={setSms}
        className="mt-2"
        title="I consent to Brelly sending text messages to the phone number above. Standard messaging rates may apply."
      />
      <BrellyBtn onClick={handleRegister} className="mt-3">Create Account</BrellyBtn>
      <div className="jc-ac">
        <div onClick={() => history?.push('/login')} style={{ fontSize: 14 }} className="brelly-text-btn mt-3">
          Sign In
        </div>
      </div>
    </AuthPage>
  );
};

export default Register;

// const TnCModal = ({ open, setOpen }: any) => {
//   const [tnc, setTnc] = useState(null);
//
//   const getTnC = async () => {
//     let _tnc = await brelly.legal.fetchCurrentTnC();
//     setTnc(_tnc);
//   };
//
//   useEffect(() => {
//     getTnC();
//   }, []);
//
//   return (
//     <Modal open={open} setOpen={setOpen}>
//       <div className="ion-padding">
//         {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | Tr... Remove this comment to see the full error message */}
//         <div dangerouslySetInnerHTML={{ __html: tnc }} />
//       </div>
//     </Modal>
//   );
// };
//
// const PPModal = ({ open, setOpen }: any) => {
//   const [pp, setPP] = useState(null);
//
//   const getTnC = async () => {
//     let _pp = await brelly.legal.fetchCurrentPrivacyPolicy();
//     setPP(_pp);
//   };
//
//   useEffect(() => {
//     getTnC();
//   }, []);
//
//   return (
//     <Modal open={open} setOpen={setOpen}>
//       <div className="ion-padding">
//         {/* @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | Tr... Remove this comment to see the full error message */}
//         <div dangerouslySetInnerHTML={{ __html: pp }} />
//       </div>
//     </Modal>
//   );
// };
