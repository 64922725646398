import { useState } from "react";
import { useApp } from 'context';
import brelly from "@brelly/sdk";
import Button from 'components/Button';
import ChecklistCheckbox from 'components/ChecklistCheckbox';
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { DateSelect } from 'components/Select';
import { useOnboarding } from './context';

let header = 'Okay. What was the exact date when you first notified your insurance company about the loss or damage?';
let preheader = 'Your Claim';

const NotifiedCarrierDate = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, setLoading, setError } = useApp();
  const [notifiedCarrierDate, setNotifiedCarrierDate] = useState(null);

  const handleComplete = async () => {
    const claimId = progress?.claimId;

    try {
      setLoading(true)
      if (notifiedCarrierDate) {
        await brelly.claims.update(claimId, { carrierNotifiedDate: notifiedCarrierDate });
        handleUpdate({ notifiedCarrierDate: notifiedCarrierDate })
      }
      setOnboardTab('claimnumber');
      setLoading(false)
    } catch(err) {
      setError(err?.message)
      setLoading(false)
    }
  };

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox>
        <DateSelect value={notifiedCarrierDate} onChange={setNotifiedCarrierDate} style={{ width: 400 }} label='Date Notified' />
        <Button onClick={handleComplete} styles={{ width: 400, marginTop: 32 }}>
          Confirm
        </Button>
        <div onClick={handleComplete} className='brelly-text-btn mt-2'>I do not know</div>
      </OnboardingBox>
      <OnboardingTracker percent='85%' />
    </>
  )
}

export default NotifiedCarrierDate;
