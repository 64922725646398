import { useState, useEffect } from 'react';
import Input from 'components/Input';
import { BrellyBtn } from '../../components';
import { CenterModal } from 'components/Modals';

export const EditLinkModal = ({ open, link, handleClose }) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    if (open) {
      setName(link?.name || '')
      setUrl(link?.url || '')
    }
  }, [open])

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Link</div>
      <Input value={name} onChange={setName} label='Name' />
      <Input value={url} onChange={setUrl} label='URL' />
      <BrellyBtn className='mt-4' style={{ width: 300 }}>Save</BrellyBtn>
    </CenterModal>
  )
}
