import { PersonIcon } from "components/Icons";

const ProPic = ({ img, user, size = 32, style = {}, className = "" }) => {
  let fontSize = size - 16;

  if (img) {
    return (
      <img
        alt=""
        src={img}
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          objectFit: "cover",
          ...style,
        }}
        className={className}
      />
    );
  } else if (user?.firstName && user?.lastName) {
    let firstInitial = user?.firstName[0];
    let lastInitial = user?.lastName[0];
    let initials = `${firstInitial}${lastInitial}`;

    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          textTransform: "uppercase",
          border: "1px solid #00ACB0",
          background: "rgb(206, 242, 243)",
          color: "#00ACB0",
          fontSize: fontSize,
          fontWeight: 500,
          lineHeight: 1,
          ...style,
        }}
        className={`jc-ac ${className}`}
      >
        {initials}
      </div>
    );
  } else if (user?.firstName) {
    let firstInitial = user?.firstName[0];

    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          textTransform: "uppercase",
          border: "1px solid #00ACB0",
          background: "rgb(206, 242, 243)",
          color: "#00ACB0",
          fontSize: fontSize,
          fontWeight: 500,
          lineHeight: 1,
          ...style,
        }}
        className={`jc-ac ${className}`}
      >
        {firstInitial}
      </div>
    );
  } else {
    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: "50%",
          textTransform: "uppercase",
          border: "1px solid #00ACB0",
          background: "rgb(206, 242, 243)",
          color: "#00ACB0",
          ...style,
        }}
        className={`jc-ac ${className}`}
      >
        <PersonIcon stroke="#00ACB0" size={fontSize} />
      </div>
    );
  }
};

export default ProPic;
