import { DetailItem, MapDetailItem } from 'pages/Dash/Claims/Claim/Details/components';
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { formatCurrency, formatNumber } from "utils";
import { HomeIcon } from "components/Icons";
import { EditModal } from './EditModal';
import { useApp } from "context";
import { useState } from "react";

export const Property = () => {
  const { asset } = useClaim();
  const [editPropertyModal, setEditPropertyModal] = useState(false);

  return (
    <>
      <div className="dash-box mt-2 dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 14 }}>
          <div className="dash-box-title">
            <HomeIcon
              style={{ marginRight: 8, marginBottom: 2 }}
              stroke="#00ACB0"
            />
            Property
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditPropertyModal(true)}
            style={{ color: "#00ACB0" }}
          >
            Edit
          </div>
        </div>
        <DetailItem
          title="Replacement Cost (total value)"
          value={formatCurrency(asset?.replacementCost)}
        />
        <DetailItem title="Year Built" value={asset?.yearBuilt} />
        <DetailItem
          title="Year Last Renovated"
          value={asset?.lastRenovationDate}
        />
        <DetailItem title="Square Footage" value={formatNumber(asset?.sqft)} />
        <MapDetailItem title="Address" value={asset?.address} />
      </div>
      <EditModal
        asset={asset}
        open={editPropertyModal}
        handleClose={() => setEditPropertyModal(false)}
      />
    </>
  );
};
