import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import ChooseCarrier from './ChooseCarrier';
import ConfirmShare from './ConfirmShare';
import CanopyClaims from './CanopyClaims';
import Canopy from './Canopy';
import { useApp } from 'context';
import CanopyPolicies from "./CanopyPolicies";
import ExistingClaims from "./ExistingClaims";
import ExistingPolicies from "./ExistingPolicies";
import ExistingProperty from "./ExistingProperty";
import PolicyType from "./PolicyType";
import PHBasics from './PHBasics';
import LossDate from './LossDate';
import NotifiedCarrier from './NotifiedCarrier';
import NotifiedCarrierDate from './NotifiedCarrierDate';
import ClaimNumber from './ClaimNumber';
import MailingAddress from './MailingAddress';
import Ownership from './Ownership';
import NotCanopyPolicy from './NotCanopyPolicy';
import Done from './Done';
import CanopyFails from './CanopyFails';
import CreateProperty from './CreateProperty';
import Incorrect from './Incorrect';
import Dash from './Dash';
import AreYouAPH from './AreYouAPH';
import { Oval } from 'react-loading-icons'
import { Portal } from 'react-portal';
import { OnboardingPage } from "./components";
import OBContextWrapper, { useOnboarding } from './context';

const Onboarding = () => {
  const history = useHistory();
  const { searchParams, handleUser } = useApp();
  const [fauxLoading, setFauxLoading] = useState(true)

  useEffect(() => {
    if (!searchParams.get("obtab")) {
      searchParams.set("obtab", "confirmshare");
      history.replace({ search: searchParams.toString() });
    }

    handleUser();
    setTimeout(() => setFauxLoading(false), 1500)
  }, []);

  return(
    <div className="brelly-content jc-ac" style={{ height: "100vh", width: '100vw' }}>
      <LoadingSpinner load={fauxLoading} />
      <OnboardingPage tab="canopy">
        <Canopy />
      </OnboardingPage>
      <OnboardingPage tab="policytype">
        <PolicyType />
      </OnboardingPage>
      <OnboardingPage tab="confirmshare">
        <ConfirmShare />
      </OnboardingPage>
      <OnboardingPage tab="canopyclaims">
        <CanopyClaims />
      </OnboardingPage>
      <OnboardingPage tab="canopypolicies">
        <CanopyPolicies />
      </OnboardingPage>
      <OnboardingPage tab="choosecarrier">
        <ChooseCarrier />
      </OnboardingPage>
      <OnboardingPage tab="existingclaims">
        <ExistingClaims />
      </OnboardingPage>
      <OnboardingPage tab="existingpolicies">
        <ExistingPolicies />
      </OnboardingPage>
      <OnboardingPage tab="lossdate">
        <LossDate />
      </OnboardingPage>
      <OnboardingPage tab="notifiedcarrier">
        <NotifiedCarrier />
      </OnboardingPage>
      <OnboardingPage tab="notifiedcarrierdate">
        <NotifiedCarrierDate />
      </OnboardingPage>
      <OnboardingPage tab="claimnumber">
        <ClaimNumber />
      </OnboardingPage>
      <OnboardingPage tab="mailingaddress">
        <MailingAddress />
      </OnboardingPage>
      <OnboardingPage tab="ownership">
        <Ownership />
      </OnboardingPage>
      <OnboardingPage tab="notcanopypolicy">
        <NotCanopyPolicy />
      </OnboardingPage>
      <OnboardingPage tab="done">
        <Done />
      </OnboardingPage>
      <OnboardingPage tab="canopyfails">
        <CanopyFails />
      </OnboardingPage>
      <OnboardingPage tab="incorrect">
        <Incorrect />
      </OnboardingPage>
      <OnboardingPage tab="dash">
        <Dash />
      </OnboardingPage>
      <OnboardingPage tab="createproperty">
        <CreateProperty />
      </OnboardingPage>
      <OnboardingPage tab="existingproperty">
        <ExistingProperty />
      </OnboardingPage>
    </div>
  )
}

const WrappedOnboarding = () => {
  return(
    <OBContextWrapper>
      <Onboarding />
    </OBContextWrapper>
  )
}

export default WrappedOnboarding;

const LoadingSpinner = ({ load }) => {
  return(
    <Portal>
      <div style={{ width: '100vw', height: '100vh', background: 'white', position: 'absolute', left: 0, top: 0, zIndex: load ? 1001009 : -10, opacity: load ? 1 : 0, transition: 'all 320ms linear' }} />
      <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.35)', zIndex: load ? 1001010 : -10, opacity: load ? 1 : 0, transition: 'all 320ms linear' }} className='jc-ac'>
        <Oval stroke="#0D72C5" fill="#0D72C5" strokeWidth={6} />
      </div>
    </Portal>
  )
};
