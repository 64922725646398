import { useApp } from 'context';
import brelly from "@brelly/sdk";
import CompanySelect from 'components/CompanySelect';
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'Please select the insurance company providing coverage when the loss or damage occurred.';
let preheader = 'Getting Started';
let boxHeader = '';

const ChooseCarrier = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { searchParams, setError, setLoading } = useApp();

  const handleSelect = async (company) => {
    try {
      setLoading(true);
      handleUpdate({ carrier: company })
      if (company?.supportedByCanopy) {
        setOnboardTab('canopy');
      } else {
        setOnboardTab('policytype');
      }
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again")
    }
  }

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox>
        <div className='ja-ac'>
          <CompanySelect customHeight={600} onSelect={handleSelect} inLine={true} />
        </div>
      </OnboardingBox>
      <OnboardingTracker progress={progress} handleUpdate={handleUpdate} />
      <OnboardingTracker percent='10%' />
    </>
  )
}

export default ChooseCarrier;
