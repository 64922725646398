import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { v4 as uuidV4 } from 'uuid';
import { currentToFloat } from 'utils';
import { CustomField } from '../../components';
import MortgageCompanySelect from "components/MortgageCompanySelect";

export const AddMortgageCompany = ({ open, property, handleClose }) => {
  const { claim, handleClaim } = useClaim()
  const { setLoading, setError } = useApp();
  const [loanNumber, setLoanNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [lender, setLender] = useState({})

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.mortgages.create({
        propertyId: property?.id,
        loanNumber,
        amount: currentToFloat(amount),
        lenderId: lender?.id,
      });
      handleClaim(false)
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Mortgage</div>
      <MortgageCompanySelect value={lender} onSelect={setLender} />
      <Input label="Loan Number" value={loanNumber} onChange={setLoanNumber} />
      <Input label="Amount" value={amount} onChange={setAmount} type='number' placeholder="$" />
      <BrellyBtn onClick={handleAdd} className="mt-3" style={{ width: 400, maxWidth: '100%' }}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
