import { useState } from 'react';
import {inventoryCats} from 'constants';
import { DateSelect } from 'components/Select';
import { DropdownSearchBar } from 'components/SearchBar';
import { BrellyBtn, CenterModal } from '../../components';

export const InventoryFilterModal = ({ open, handleClose }) => {
  const [selectedCats, setSelectedCats] = useState([]);
  const [purchaseDate, setPurchaseDate] = useState(null);

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Filter Inventory Items</div>
      <DropdownSearchBar value={selectedCats} onChange={setSelectedCats} options={inventoryCats} label='Category' />
      <BrellyBtn className='mt-4' onClick={handleClose}>Filter</BrellyBtn>
    </CenterModal>
  )
}
