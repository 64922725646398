import { useState } from "react";
import { format } from "date-fns";
import { EditModal } from './EditModal';
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';

export const Details = () => {
  const { policy } = useClaim();
  const [editInfoModal, setEditInfoModal] = useState(false);
  const insuranceCompany = policy?.insuranceCompany || {};
  const effectiveDate = policy?.effectiveDate
    ? format(new Date(policy?.effectiveDate), "MM/dd/yyyy")
    : "Unknown";
  const expiryDate = policy?.expiryDate
    ? format(new Date(policy?.expiryDate), "MM/dd/yyyy")
    : "Unknown";
  const policyNumber = policy?.policyNumber ? policy.policyNumber : "Unknown";

  return (
    <>
      <div
        className="mt-3 brelly-content ps-3 pe-3 pt-2 pb-3"
        style={{ borderRadius: 10 }}
      >
        <div className="jb-ac" style={{ width: "100%", marginBottom: 14 }}>
          <div className="dash-box-title">Information</div>
          <div
            className="brelly-text-btn"
            onClick={() => setEditInfoModal(true)}
            style={{ color: "#00ACB0" }}
          >
            Edit
          </div>
        </div>
        <DetailItem
          title="Insurer Name"
          value={insuranceCompany?.name || "Unknown"}
        />
        <DetailItem title="Policy Number" value={policyNumber} />
        <DetailItem title="Effective Date" value={effectiveDate} />
        <DetailItem title="Expiration Date" value={expiryDate} />
      </div>
      <EditModal
        policy={policy}
        open={editInfoModal}
        handleClose={() => setEditInfoModal(false)}
      />
    </>
  );
};
