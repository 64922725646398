import { useState } from "react";
import { useApp } from 'context';
import brelly from "@brelly/sdk";
import Button from 'components/Button';
import ChecklistCheckbox from 'components/ChecklistCheckbox';
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { DateSelect } from 'components/Select';
import { useOnboarding } from './context';

let header = 'Have you already notified your insurance company about the loss or damage to your property?';
let preheader = 'Your Claim';

const NotifiedCarrier = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, setLoading, setError } = useApp();

  const handleTrue = async () => {
    const claimId = progress?.claimId;

    try {
      setLoading(true)
      await brelly.claims.update(claimId, { carrierNotified: true })
      handleUpdate({ notifiedCarrier: true })
      setOnboardTab('notifiedcarrierdate')
      setLoading(false)
    } catch(err) {
      setError(err?.message)
      setLoading(false)
    }
  };

  const handleFalse = async () => {
    handleUpdate({ notifiedCarrier: false })
    setOnboardTab('mailingaddress')
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox>
        <div className='jb-ac' style={{ width: 600 }}>
          <Button onClick={handleFalse} styles={{ width: 280 }}>
            No
          </Button>
          <Button onClick={handleTrue} styles={{ width: 280 }}>
            Yes
          </Button>
        </div>
      </OnboardingBox>
      <OnboardingTracker percent='80%' />
    </>
  )
}

export default NotifiedCarrier;
