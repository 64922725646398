import { useState, useEffect } from "react";
import Input from "components/Input";
import TextArea from "components/TextArea";
import { BrellyBtn } from "../../components";
import { DateSelect } from "components/Select";
import { CenterModal } from "components/Modals";
import { AddFileModal } from "pages/Dash/Modals";
import { SingleSelect } from "components/Select";
import { paymentTypes } from "constants";
import PayeeSelect from 'components/PayeeSelect';
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { usePayees } from 'hooks';
import Attachment from 'components/AttachmentItem';
import { currentToFloat } from 'utils';

export const AddExpenseModal = ({ open, claim, handleClose }) => {
  if (open) {
    return <Inner open={open} claim={claim} handleClose={handleClose} />
  } else {
    return null;
  }
};

const Inner = ({ open, claim, handleClose }) => {
  const { coverages, handleExpenses, handleCoverages } = useClaim();
  const coverageOptions = coverages?.map((c, idx) => ({ ...c, label: c?.name, value: idx + 1 }));
  const { setLoading, setError } = useApp();
  const [desc, setDesc] = useState("");
  const [amount, setAmount] = useState("");
  const [date, setDate] = useState(null);
  const [addFilesModal, setAddFilesModal] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState({});
  const [selectedPayment, setSelectedPayment] = useState({});
  const [selectedCoverage, setSelectedCoverage] = useState({});
  const [files, setFiles] = useState([]);

  const handleFiles = (_files) => {
    setFiles(_files)
    setAddFilesModal(false)
  }

  const handleAdd = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.claimExpenses.create({
        amount: currentToFloat(amount),
        incurredAt: date,
        description: desc,
        claimId: claim?.id,
        paymentMethod: selectedPayment?.label,
        coverageId: selectedCoverage?.id,
        payeeId: selectedPayee?.id
      });
      if (files?.length > 0) {
        let localFiles = files?.filter((m) => !m?.downloadUrl)?.map((f) => f?.file);
        let remoteFiles = files?.filter((m) => m?.downloadUrl)?.map((f) => f?.id)

        if (localFiles?.length > 0) {
          await brelly.claimExpenses.uploadFiles(data?.id, localFiles)
        }

        if (remoteFiles?.length > 0) {
          await brelly.claimExpenses.addFiles(data?.id, remoteFiles)
        }
      }
      await Promise.all([handleExpenses(), handleCoverages()]);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
      setError("There was an issue creating your expense! Please check your connection and try again");
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Expense</div>
      <Input value={amount} onChange={setAmount} label="Amount" placeholder="$" type="number" />
      <TextArea label="Description" value={desc} onChange={setDesc} style={{ height: 80 }} />
      <DateSelect value={date} onChange={setDate} label="Date" />
      <SingleSelect
        value={selectedCoverage}
        onChange={setSelectedCoverage}
        options={coverageOptions}
        label="Select Coverage"
      />
      <SingleSelect
        value={selectedPayment}
        onChange={setSelectedPayment}
        options={paymentTypes}
        single={true}
        label="Payment Type"
      />
      <PayeeSelect onSelect={setSelectedPayee} />
      <div className='row mt-2'>
        {files?.map((m) => (
          <Attachment key={m?.id} file={m} />
        ))}
      </div>
      <BrellyBtn
        onClick={() => setAddFilesModal(true)}
        className="mt-3"
        style={{ background: "#2D3C57" }}
      >
        Add Proof of Payment
      </BrellyBtn>
      <BrellyBtn onClick={handleAdd} style={{ width: 400 }} className="mt-2">
        Save
      </BrellyBtn>
      <AddFileModal
        open={addFilesModal}
        onSave={handleFiles}
        handleClose={() => setAddFilesModal(false)}
      />
    </CenterModal>
  );
}
