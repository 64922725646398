import { useState, useRef } from "react";
import { useClaim } from "./context";
import CheckBox from "components/CheckBox";
import { BrellyBtn } from "../../components";
import { format, isSameDay } from "date-fns";
import { InlineModal } from "components/Modals";
import { handleDate, formatCurrency } from "utils";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import {
  AddExpenseModal,
  AddReimbursementModal,
  ExpenseReportModal,
} from "pages/Dash/Modals";

const ExpensesBox = () => {
  const { expenses, payments, coverages, claim } = useClaim();
  const transactions = [...expenses, ...payments];
  const modalBtnRef = useRef(null);
  const [filterType, setFilterType] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [expenseModal, setExpenseModal] = useState(false);
  const [reimbursementModal, setReimbursementModal] = useState(false);

  const getTransDay = (t) => {
    if (t?.incurredAt) {
      return format(new Date(t?.incurredAt), "MMM dd, yyyy");
    } else if (t?.paidAt) {
      return format(new Date(t?.paidAt), "MMM dd, yyyy");
    } else {
      return format(new Date(), "MMM dd, yyyy");
    }
  };

  const _transactionDays = transactions?.map((t) => getTransDay(t));
  const transactionDays = [...new Set(_transactionDays)];
  const groupTransactions = transactionDays?.map((td) => ({
    day: td,
    transactions: transactions?.filter((t) =>
      isSameDay(new Date(t.incurredAt || t.paidAt), new Date(td))
    ),
  }));
  const sortedTransactions = groupTransactions?.sort(
    (a, b) => new Date(b.day) - new Date(a.day)
  );

  const formattedCoverages = coverages?.map((c) => ({
    ...c,
    payments: payments?.filter((p) => p?.coverage?.id === c?.id),
    expenses: expenses?.filter((p) => p?.coverage?.id === c?.id),
  }));

  const coverageSum = coverages
    ?.map((c) => c?.premium)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  return (
    <>
      <div className="dash-box">
        <div className="jb-ac">
          <div className="dash-box-title">
            <LiaFileInvoiceDollarSolid
              style={{ marginRight: 8, color: "#00ACB0", marginBottom: 2 }}
            />
            Claim Finances
          </div>
          <div className='je-ac d-none d-lg-flex'>
            <BrellyBtn
              onClick={() => setReportModal(true)}
              style={{
                background: "transparent",
                boxShadow: "none",
                color: "#00ACB0",
                border: "1px solid #00ACB0",
              }}
            >
              Create Expense Report
            </BrellyBtn>
            <BrellyBtn className="ms-3" onClick={() => setExpenseModal(true)}>
              Add Expense
            </BrellyBtn>
            <BrellyBtn
              className="ms-3"
              onClick={() => setReimbursementModal(true)}
            >
              Add Reimbursement
            </BrellyBtn>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <CoverageItem
          coverage={{
            name: "Total Coverage",
            payments: payments,
            expenses: expenses,
            premium: coverageSum,
            isTotal: true,
          }}
        />
      </div>

      <div className="row mt-1">
        {formattedCoverages?.map((c, key) => (
          <div className="col-12 col-lg-4 mt-3" key={key}>
            <CoverageItem coverage={c} />
          </div>
        ))}
      </div>

      <div className="dash-box mt-3">
        <div className="jb-ac">
          <div className="dash-box-title">Transactions</div>
          <div>
            <div
              ref={modalBtnRef}
              onClick={() => setFilterModal(true)}
              className="brelly-text-btn"
              style={{ color: "#00ACB0" }}
            >
              Total Coverages
            </div>
          </div>
        </div>

        {sortedTransactions?.map((day, key) => (
          <div className="mt-2" key={key}>
            <div className="dash-table-header">
              {handleDate(new Date(day?.day), "MMM dd, yyyy")}
            </div>
            {day.transactions?.map((item, itemKey) => (
              <ExpenseItem item={item} key={itemKey} />
            ))}
          </div>
        ))}
      </div>

      <InlineModal
        btnRef={modalBtnRef}
        open={filterModal}
        handleClose={() => setFilterModal(false)}
      >
        <CheckBox
          title="Total Coverages"
          value={filterType === "Total Coverages"}
          setValue={() => setFilterType("Total Coverages")}
        />
        <CheckBox
          title="Dwelling"
          value={filterType === "Dwelling"}
          setValue={() => setFilterType("Dwelling")}
        />
        <CheckBox
          title="Other"
          value={filterType === "Other"}
          setValue={() => setFilterType("Other")}
        />
        <CheckBox
          title="Personal Property"
          value={filterType === "Personal Property"}
          setValue={() => setFilterType("Personal Property")}
        />
        <CheckBox
          title="Additional Living"
          value={filterType === "Additional Living"}
          setValue={() => setFilterType("Additional Living")}
        />
        <CheckBox
          title="Personal Liability"
          value={filterType === "Personal Liability"}
          setValue={() => setFilterType("Personal Liability")}
        />
      </InlineModal>

      <AddExpenseModal
        open={expenseModal}
        claim={claim}
        handleClose={() => setExpenseModal(false)}
      />
      <ExpenseReportModal
        open={reportModal}
        handleClose={() => setReportModal(false)}
      />
      <AddReimbursementModal
        open={reimbursementModal}
        handleClose={() => setReimbursementModal(false)}
      />
    </>
  );
};

export default ExpensesBox;

const ExpenseItem = ({ item }) => {
  let isExpense = !!item?.incurredAt;

  return (
    <div
      className="jb-ac mt-2 brelly-content"
      style={{ borderRadius: 10, height: 50, padding: "0px 24px" }}
    >
      <div>
        <div
          className="dash-table-header"
          style={{ lineHeight: 1, paddingBottom: 2, fontSize: 12 }}
        >
          {item?.coverage?.name}
        </div>
        <div className="dash-table-item" style={{ lineHeight: 1 }}>
          {item.otherParty}
        </div>
      </div>
      <div
        className="brelly-text-btn"
        style={{ color: isExpense ? "red" : "#00ACB0" }}
      >
        {formatCurrency(item.amount)}
      </div>
    </div>
  );
};

const CoverageItem = ({ coverage = {} }) => {
  const { name, expenses, payments, premium, deductible, isTotal } = coverage;
  const total = premium || deductible;

  const totalSpent = expenses
    ?.map((ex) => ex.amount)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
  const totalReturned = payments
    ?.map((ex) => ex.amount)
    .reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

  let fontColor = isTotal ? "white" : "black";
  let percentSpent = (totalSpent / total) * 100;
  let percentReturned = (totalReturned / total) * 100;

  return (
    <div
      className="dash-box"
      style={{ color: fontColor, background: isTotal ? "#00ACB0" : "" }}
    >
      <div className="jb-ac">
        <div
          className="dash-box-title"
          style={{ color: fontColor, fontSize: 17 }}
        >
          {name}
        </div>
        <div
          className="dash-box-title"
          style={{
            color: fontColor,
            fontSize: 17,
            fontWeight: 700,
            lineHeight: 1,
          }}
        >
          {formatCurrency(total)}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: 11,
          borderRadius: 12,
          overflow: "hidden",
          position: "relative",
        }}
        className="mt-1"
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            background: isTotal ? "#EEF4FF" : "#00ACB0",
            borderRadius: 12,
            opacity: 0.42,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <div
          style={{
            height: "100%",
            width: `${percentSpent}%`,
            background: isTotal ? "white" : "rgb(255,0,0)",
            borderRadius: 12,
            boxShadow: "2px 2px 3px rgba(0,0,0,.2)",
            zIndex: percentSpent > percentReturned ? 2 : 3,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
        <div
          style={{
            height: "100%",
            width: `${percentReturned}%`,
            background: isTotal ? "white" : "#00ACB0",
            borderRadius: 12,
            boxShadow: "2px 2px 3px rgba(0,0,0,.2)",
            zIndex: percentReturned > percentSpent ? 2 : 3,
            position: "absolute",
            left: 0,
            top: 0,
          }}
        />
      </div>
      <div
        className="jb-ac brelly-h5 mt-1"
        style={{ color: fontColor, lineHeight: 1, fontSize: 12 }}
      >
        <div>Spent: {formatCurrency(totalSpent)}</div>
        <div>Recieved: {formatCurrency(totalReturned)}</div>
      </div>
    </div>
  );
};
