import { useState, useEffect } from "react";
import { useApp } from 'context';
import brelly from "@brelly/sdk";
import Button from 'components/Button';
import { DateSelect } from 'components/Select';
import ChecklistCheckbox from 'components/ChecklistCheckbox';
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'Got it. We’ll finish by asking a few questions about your claim.';
let preheader = 'Your Claim';

const LossDate = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setLoading, setError } = useApp();
  const [lossDate, setLossDate] = useState(null);

  const handleComplete = async () => {
    try {
      setLoading(true);
      let claim = await brelly.claims.create({ dateOfLoss: lossDate, userId: user?.id });
      handleUpdate({ claimId: claim?.id })
      setOnboardTab('notifiedcarrier');
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again")
    }
  };

  const handleDontKnow = async () => {
    try {
      setLoading(true);
      let claim = await brelly.claims.create({ userId: user?.id });
      handleUpdate({ claimId: claim?.id })
      setOnboardTab('notifiedcarrier');
      setLoading(false);
    } catch(err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again")
    }
  };

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <h1 className='mt-2 mb-3' style={{ textAlign: 'center' }}>When did the loss or damage for this claim occur?</h1>
      <OnboardingBox>
        <DateSelect value={lossDate} onChange={setLossDate} style={{ width: 400 }} label='Date of Loss' />
        <Button onClick={handleComplete} styles={{ width: 400, marginTop: 32 }}>
          Confirm
        </Button>
        <div onClick={handleDontKnow} className='brelly-text-btn mt-2'>I do not know</div>
      </OnboardingBox>
      <OnboardingTracker percent="70%" />
    </>
  )
}

export default LossDate;
