import ProPic from './ProPic';

const ImgTitleSub = ({ img, title, sub, className = '', style = {}, onClick = () => {}, proPicStyle = {} }) => {
  return(
    <div className={`js-ac ${className}`} style={style}>
      <ProPic img={img} user={convertTitle(title)} size={36} style={proPicStyle} />
      <div className='ms-2'>
        <div style={{ lineHeight: 1, fontWeight: 600 }}>{title}</div>
        <div style={{ lineHeight: 1, fontSize: 12 }}>{sub}</div>
      </div>
    </div>
  )
}

export default ImgTitleSub;

const convertTitle = (title = '') => {
  if (title?.length > 0) {
    let split = title?.split(" ");

    if (split?.length > 1) {
      return { firstName: split[0], lastName: split[1] }
    } else {
      return { firstName: title }
    }
  } else {
    return {}
  }
}
