import { useState, useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { BsX } from 'react-icons/bs';

export const InlineModal = ({ open = false, handleClose = () => {}, children, btnRef, style = {}, blurStyle = {} }) => {
  const modalRef = useRef(null);
  const [modalRect, setModalRect] = useState({});
  const [screenRect, setScreenRect] = useState({});
  const [boundingRect, setBoundingRect] = useState({});

  const getRects = () => {
    if (btnRef?.current) {
      setModalRect(modalRef.current?.getBoundingClientRect())
      setBoundingRect(btnRef.current?.getBoundingClientRect())
      setScreenRect({ width: window.innerWidth, height: window.innerHeight })
    }
  }

  const getPosition = () => {
    if (boundingRect?.x && screenRect?.width && modalRect?.x) {
      let btnX = boundingRect.x;
      let btnY = boundingRect.bottom + 6;
      let centerX = btnX + boundingRect.width / 2;
      let modalWidth = modalRect?.width;
      let modalHeight = modalRect?.height;
      let modalX = centerX - modalWidth / 2;
      let modalY = btnY;
      let screenXSafe = screenRect?.width - 20;
      let screenYSafe = screenRect?.height - 20;
      let tooFarX = modalX + modalWidth > screenXSafe;
      let tooFarY = modalY + modalHeight > screenYSafe;
      let tooNearX = modalX < 0;
      let tooNearY = modalY < 0;
      let safeX = tooFarX ? screenXSafe - modalWidth : tooNearX ? 20 : modalX;
      let safeY = tooFarY ? screenYSafe - modalHeight: tooNearY ? 20 : modalY;

      return { left: safeX, top: safeY }
    } else {
      return { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', opacity: 0 }
    }
  }

  useEffect(() => {
    getRects()
  }, [btnRef, modalRef, open])

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', ...blurStyle }} onClick={handleClose} />
        <div ref={modalRef} style={{ position: 'absolute', background: 'white', borderRadius: 18, padding: 18, zIndex: 1001, fontFamily: 'Lato', whiteSpace: 'nowrap', ...getPosition(), ...style }} className='shadow'>
          {children}
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}

export const CenterModal = ({ open = false, handleClose = () => {}, children, style = {}, blurStyle = {}, dontExpand = false }) => {
  let width = window.innerWidth;
  let sizeStyles = (width > 991 || dontExpand) ? { left: '50%', top: '50%', transform: 'translate(-50%, -50%)', borderRadius: 14 } : { left: 0, top: 0, width: '100%', height: '100%' };

  if (open) {
    return(
      <Portal>
        <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', ...blurStyle }} onClick={handleClose} />
        <div style={{ position: 'fixed', background: 'white', padding: 18, zIndex: 1001, ...style, ...sizeStyles }} className='shadow'>
          <div onClick={handleClose}><BsX style={{ position: 'absolute', top: 12, right: 12, color: 'black', fontSize: 18 }} /></div>
          {children}
        </div>
      </Portal>
    )
  } else {
    return null;
  }
}
