import {
  HomeIcon,
  CalendarIcon,
  PhotoIcon,
  BookIcon,
  PersonIcon,
  DocsIcon,
  CircleInfoIcon,
  DoubleCheckIcon,
  LogoutIcon,
  ClaimsIcon,
  MailIcon,
  EstimatesIcon,
} from "components/Icons";
import { CenterModal } from "components/Modals";
import ProPic from "components/ProPic";
import { useState, useRef } from "react";
import { useApp } from "context";
import { useIntercom } from 'react-use-intercom';
import ImgTitleSub, { convertTitle } from "components/ImgTitleSub";
import { Transition } from 'react-transition-group';

const defaultStyle = {
  transition: `all 300ms ease-in-out`,
  transform: "translate(110%, 0%)",
  position: 'fixed', right: 0, top: 0, width: '90%', maxWidth: 500, height: '100vh', background: 'rgb(0, 172, 176)', zIndex: 100000
}

const transitionStyles = {
  entering: { transform: "translate(0%, 0%)" },
  entered:  { transform: "translate(0%, 0%)" },
  exiting:  { transform: "translate(110%, 0%)" },
  exited:  { transform: "translate(110%, 0%)" },
};

export const MobileMenu = ({ open, setOpen }) => {
  const [estimateModal, setEstimateModal] = useState(false);
  const { selectedMembership, selectedOrg } = useApp();
  const { user, handleLogout, tab, setTab } = useApp();
  const { show } = useIntercom();
  const nodeRef = useRef(null);

  const handleNav = (newTab) => {
    setTab(newTab)
    setOpen(false);
  }

  const handleEstimateModal = () => {
    setEstimateModal(true);
    setOpen(false);
  }

  return(
    <>
      {open && <div onClick={() => setOpen(false)} style={{ width: '100%', height: '100vh', background: 'rgba(0,0,0,.2)', left: 0, top: 0, zIndex: 10000, position: 'fixed' }} />}
      <Transition nodeRef={nodeRef} in={open} timeout={300}>
        {state => (
          <div ref={nodeRef} style={{ ...defaultStyle, ...transitionStyles[state] }} className='ps-3 pe-3 pb-3 pt-5'>
            <ImgTitleSub
              className="mt-2"
              img={selectedOrg?.logo?.downloadUrl}
              title={`${user?.firstName} ${user?.lastName}`}
              proPicStyle={{ color: 'white', border: '1px solid #ffffff', background: 'rgb(0, 172, 176)' }}
              style={{ cursor: "pointer", color: 'white', marginBottom: 32, marginLeft: 20 }}
            />
            <MenuBtn
              title="Home"
              value="home"
              tab={tab}
              setTab={handleNav}
              highlight={tab === "claim"}
            />
            <a
              onClick={() => setOpen(false)}
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#ffffff"
              />
              Brelly Resources
            </a>
            <div
              onClick={handleEstimateModal}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Brelly Estimates
            </div>
            <div
              style={{
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setTab("profile")}
              className="js-ac dash-menu-btn"
            >
              <ProPic
                user={user}
                size={24}
                style={{ marginRight: 12, fontSize: 12, color: 'white', border: '1px solid #ffffff', background: 'rgb(0, 172, 176)' }}
              />
              My Profile
            </div>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Help
            </div>
            <div
              onClick={handleLogout}
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#ffffff",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <LogoutIcon style={{ marginRight: 12 }} stroke="#ffffff" />
              Logout
            </div>
          </div>
        )}
      </Transition>
      <EstimatingModal
        open={estimateModal}
        handleClose={() => setEstimateModal(false)}
      />
    </>
  )
}

const Menu = () => {
  const [estimateModal, setEstimateModal] = useState(false);
  const { user, handleLogout, tab, setTab } = useApp();
  const contentHeight = window.innerHeight - 90;
  const { show } = useIntercom();

  return (
    <>
      <div style={{ width: 260, height: "100vh", paddingTop: 24 }} className='d-none d-lg-block'>
        <img
          alt=""
          style={{ height: 56, marginBottom: 24, marginLeft: 26 }}
          src="/assets/logos/logoAB.png"
        />
        <div
          style={{
            height: contentHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <MenuBtn title="My Claims" value="home" tab={tab} setTab={setTab} />
            <div
              onClick={show}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Brelly Chat
            </div>
            <a
              href="https://brelly.com/claim-resources/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn menu-a"
            >
              <BookIcon
                style={{ marginRight: 12, marginTop: 5 }}
                stroke="#64666B"
              />
              Brelly Resources
            </a>
            <div
              onClick={() => setEstimateModal(true)}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Brelly Estimates
            </div>
            <div
              onClick={() => setEstimateModal(true)}
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Pro Connect
            </div>
          </div>
          <div style={{ paddingBottom: 24 }}>
            <div
              style={{
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              onClick={() => setTab("profile")}
              className="js-ac dash-menu-btn"
            >
              <ProPic
                user={user}
                size={24}
                style={{ marginRight: 12, fontSize: 12 }}
              />
              My Profile
            </div>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Help
            </div>
            <div
              onClick={handleLogout}
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <LogoutIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Logout
            </div>
          </div>
        </div>
      </div>
      <EstimatingModal
        open={estimateModal}
        handleClose={() => setEstimateModal(false)}
      />
    </>
  );
};

export default Menu;

const EstimatingModal = ({ open, handleClose }) => {
  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div className='jc-ac flex-col' style={{ height: "90%" }}>
        <h1 style={{ maxWidth: 300, textAlign: "center" }}>On the Way!</h1>
        <h3 style={{ maxWidth: 300, textAlign: "center" }}>
          Brelly is excited to announce that it will soon offer estimating
          services to Brelly Pro customers at a discount. Defensible, timely, and
          insurance-compliant estimates -- all just one click away.
        </h3>
      </div>
    </CenterModal>
  );
};

const MenuBtn = ({ title, value, tab, setTab, highlight }) => {
  let selected = highlight || value === tab;
  let delay = selected ? 320 : 0;
  const width = window.innerWidth;
  const color = width > 991 ? selected ? "#2D3C57" : "#64666B" : '#ffffff';
  const selectedBackground = width > 991 ? "rgba(206, 242, 243, 0.5)" : '';

  const icons = {
    home: (
      <HomeIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    inbox: (
      <MailIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    claims: (
      <ClaimsIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    tasks: (
      <DoubleCheckIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    contacts: (
      <PersonIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    docs: (
      <DocsIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    pics: (
      <PhotoIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    estimates: (
      <EstimatesIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
    calendar: (
      <CalendarIcon
        style={{ marginRight: 12 }}
        stroke={color}
      />
    ),
  };

  return (
    <div
      onClick={() => setTab(value)}
      style={{
        marginTop: 12,
        marginBottom: 12,
        height: 46,
        paddingLeft: 26,
        position: "relative",
        color: color,
        fontFamily: "Lato",
        fontSize: 18,
        cursor: "pointer",
        background: selected ? selectedBackground : "",
        fontWeight: 500,
        transition: `all 200ms ease ${delay}ms`,
      }}
      className="js-ac dash-menu-btn"
    >
      {icons[value]} {title}
      <div
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          width: 4,
          height: 46,
          borderRadius: "42px 0px 0px 42px",
          background: selected ? "rgb(0, 172, 176)" : "",
          transition: `all 260ms ease ${delay}ms`,
        }}
      />
    </div>
  );
};
