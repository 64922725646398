import { useState, useEffect } from "react";
// Internal Imports
import brelly from "@brelly/sdk";

export const usePayees = () => {
  const [payees, setPayees] = useState([]);

  const handlePayees = async (override = false) => {
    if (payees?.length === 0 || override) {
      try {
        let { data } = await brelly.payees.getAll({ limit: 10 });
        if (Array.isArray(data)) {
          let formatted = data?.map((p, idx) => ({ ...p, label: p?.name, value: idx + 1 }));
          setPayees(formatted);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const searchPayees = async (searchVal) => {
    if (searchVal?.length > 0) {
      let { data } = await brelly.payees.getAll({
        filter: { name: { _iLike: `%25${searchVal}%25` } },
      });
      if (Array.isArray(data)) {
        let formatted = data?.map((p, idx) => ({ ...p, label: p?.name, value: idx + 1 }));
        return formatted;
      }
    } else {
      return payees;
    }
  };

  useEffect(() => {
    handlePayees();
  }, []);

  return { payees, searchPayees, handlePayees };
};
