import brelly from "@brelly/sdk";
import { useApp } from 'context';
import Input from "components/Input";
import CheckBox from "components/CheckBox";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import PhonePicker from 'components/PhoneNumber';

const AuthModal = ({ open, progress, handleUpdate, handleClose, setOnboardTab }) => {
  const [verify, setVerify] = useState(false);
  const className = verify ? 'jc-ac shadow-sm' : 'jb-as shadow-sm'

  if (open) {
    return(
      <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 1000, background: 'rgba(0, 172, 176,.6)' }} className='jc-ac'>
        <div style={{ width: 1000, background: 'white', padding: 20, borderRadius: 10, height: 559 }} className={className}>
          {!verify && (
            <>
              <LoginUI progress={progress} handleUpdate={handleUpdate} handleClose={handleClose} setOnboardTab={setOnboardTab} />
              <div style={{ width: 1, height: 519, background: '#F5F5F5' }} />
              <RegisterUI setVerify={setVerify} />
            </>
          )}
          {verify && (
            <VerifyUI setVerify={setVerify} progress={progress} handleUpdate={handleUpdate} handleClose={handleClose} setOnboardTab={setOnboardTab} />
          )}
        </div>
      </div>
    )
  } else {
    return null;
  }
}

export default AuthModal;

const VerifyUI = ({ setVerify, progress, handleUpdate, handleClose, setOnboardTab }) => {
  const { setError, setLoading, setUser } = useApp();
  const [verificationCode, setVerificationCode] = useState("");
  const { invitation, code } = progress;

  const handleVerify = async () => {
    if (verificationCode?.length < 1) {
      setError("Please enter the code we emailed you");
    } else {
      setLoading(true);
      try {
        const user = await brelly.auth.verifyConfirmationCode(verificationCode);
        setUser(user);
        await brelly.projectInvites.accept(invitation?.id, code);
        setLoading(false);
        handleClose();
        if (user?.claims?.length > 0) {
          setOnboardTab('existingclaims')
        } else if (user?.policies?.length > 0) {
          setOnboardTab('existingpolicies')
        } else {
          setOnboardTab('choosecarrier')
        }
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const handleNewCode = async () => {
    await brelly.auth.resendConfirmationCode();
  };

  return(
    <div style={{ width: 450 }} className=''>
      <h3 style={{ color: 'black' }}>Enter the verification code we just sent to your email</h3>
      <Input value={verificationCode} onChange={setVerificationCode} label='Code' className='mt-3' />
      <BrellyBtn onClick={handleVerify} className='mt-3'>Verify</BrellyBtn>
      <div className='jb-ac mt-3'>
        <div onClick={() => setVerify(false)} style={{ fontSize: 14 }} className='brelly-text-btn'>Back</div>
        <div onClick={handleNewCode} style={{ fontSize: 14 }} className='brelly-text-btn'>Send New Code</div>
      </div>
    </div>
  )
}

const RegisterUI = ({ setVerify }) => {
  const { setError, setLoading } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [tncModal, setTncModal] = useState(false);
  const [ppModal, setPPModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sms, setSms] = useState(true);

  const handleRegister = async () => {
    if (!terms) {
      setError("You must agree to the terms & conditions to register");
    } else if (email?.length < 1) {
      setError("Email cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else if (password?.length < 7) {
      setError("Please create a password with at least 7 characters");
    } else if (firstName?.length < 1) {
      setError("Please add your first name");
    } else if (lastName?.length < 1) {
      setError("Please add your last name");
    } else {
      setLoading(true);

      try {
        const data = await brelly.auth.signup(email.toLowerCase(), password, {
          phoneNumber,
          firstName,
          lastName,
          sms,
        });
        setLoading(false);
        setVerify(true);
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    }
  };

  return(
    <div style={{ width: 450 }} className=''>
      <h1 style={{ color: 'black' }}>Register</h1>
      <Input label='First Name' value={firstName} onChange={setFirstName} />
      <Input label='Last Name' value={lastName} onChange={setLastName} />
      <PhonePicker label='Phone Number' value={phoneNumber} onChange={setPhoneNumber} />
      <Input label='Email' value={email} onChange={setEmail} />
      <Input label='Password' value={password} onChange={setPassword} type='password' />
      <Input style={{ marginBottom: 18 }} label='Confirm Password' value={confirmPassword} onChange={setConfirmPassword} type='password' />
      <CheckBox value={terms} setValue={setTerms} title={<div>I agree to Brelly’s <a target="_blank" rel="noreferrer" href='https://brelly.com/terms-of-use/'>Terms of Service</a> and <a target="_blank" rel="noreferrer" href="https://brelly.com/privacy-policy/">Privacy Policy.</a></div>} />
      <CheckBox value={sms} setValue={setSms}
        className="mt-2"
        title="I consent to Brelly sending text messages to the phone number list above.  Standard messaging rates may app."
      />
      <BrellyBtn onClick={handleRegister} className="mt-3">Create Account</BrellyBtn>
    </div>
  )
}

const LoginUI = ({ progress, handleUpdate, handleClose, setOnboardTab }) => {
  const { setError, setUser, setLoading } = useApp();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { invitation, code } = progress;

  const handleLogin = async () => {
    if (email?.length < 1) {
      setError("Email cannot be blank!");
    } else if (password?.length < 1) {
      setError("Password cannot be blank!");
    } else {
      setLoading(true);
      try {
        const user = await brelly.auth.signin(email.toLowerCase(), password);
        setUser(user);
        await brelly.projectInvites.accept(invitation?.id, code);
        setLoading(false);
        handleClose();
        if (user?.claims?.length > 0) {
          setOnboardTab('existingclaims')
        } else if (user?.policies?.length > 0) {
          setOnboardTab('existingpolicies')
        } else {
          setOnboardTab('choosecarrier')
        }
      } catch (err) {
        setLoading(false);
        setError(err?.message);
      }
    }
  };

  return(
    <div style={{ width: 450, height: 519 }} className=''>
      <h1 style={{ color: 'black' }}>Login</h1>
      <Input label='Email' value={email} onChange={setEmail} />
      <Input label='Password' value={password} onChange={setPassword} type='password' />
      <BrellyBtn onClick={handleLogin} className='mt-3'>Login</BrellyBtn>
    </div>
  )
}
