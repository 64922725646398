import { useState } from "react";
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Button from "components/Button";
import ChecklistCheckbox from "components/ChecklistCheckbox";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from "./components";
import { useOnboarding } from './context';

let header = "Thanks. What kind of insurance policy is this?";
let preheader = "Insurance Policy";

const PolicyType = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setError, setLoading } = useApp();
  const [policyType, setPolicyType] = useState("");

  const handleComplete = async () => {
    try {
      setLoading(true);
      let properties = await brelly.users.getProperties();
      handleUpdate({ type: policyType, properties: properties });

      if (properties?.length > 0) {
        setOnboardTab("existingproperty");
      } else {
        setOnboardTab("createproperty");
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("");
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox>
        <ChecklistCheckbox
          value={policyType === "homeowners"}
          onChange={() => setPolicyType("homeowners")}
          falseInner="Homeowners"
          contStyle={{ width: 400 }}
        />
        <ChecklistCheckbox
          value={policyType === "business"}
          onChange={() => setPolicyType("business")}
          falseInner="Business Owner"
          contStyle={{ width: 400 }}
        />
        <ChecklistCheckbox
          value={policyType === "flood"}
          onChange={() => setPolicyType("flood")}
          falseInner="Flood"
          contStyle={{ width: 400 }}
        />
        <ChecklistCheckbox
          value={policyType === "commercial"}
          onChange={() => setPolicyType("commercial")}
          falseInner="Commercial Property/Fire"
          contStyle={{ width: 400 }}
        />
        <Button onClick={handleComplete} styles={{ width: 400, marginTop: 16 }}>
          Confirm
        </Button>
        <div onClick={handleComplete} className="brelly-text-btn mt-2">I'm not sure</div>
      </OnboardingBox>
      <OnboardingTracker percent="25%" />
    </>
  );
};

export default PolicyType;
