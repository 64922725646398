import { useState } from "react";
import { useClaim } from "./context";
import SearchBar from "components/SearchBar";
import { LiaCouchSolid } from "react-icons/lia";
import {
  EditInventoryModal,
  AddInventoryModal,
  InventoryReportModal,
  InventoryFilterModal,
} from "pages/Dash/Modals";
import { handleDate, formatCurrency } from "utils";
import InventoryImg from 'components/InventoryImg';

const InventoryBox = () => {
  const [filters, setFilters] = useState({
    searchVal: '',
    selectedCats: []
  })
  const { asset, personalItems } = useClaim()
  const [addModal, setAddModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const filteredItems = getFiltered(filters, personalItems);

  const updateFilters = (changes = {}) => {
    setFilters({...filters, ...changes})
  }

  return (
    <div className="dash-box">
      <div className="jb-ac">
        <div className="dash-box-title">
          <LiaCouchSolid style={{ marginRight: 8, color: "#00ACB0" }} />
          Contents Inventory
        </div>
        <div className="je-ac">
          <SearchBar className='ms-4 d-none d-lg-flex' value={filters?.searchVal} onChange={(v) => updateFilters({ searchVal: v })} />
          <div
            className="brelly-text-btn ms-4 d-none d-lg-flex"
            style={{ color: "#00ACB0" }}
            onClick={() => setReportModal(true)}
          >
            Create Report
          </div>
          <div
            className="brelly-text-btn ms-4 d-none d-lg-flex"
            style={{ color: "#00ACB0" }}
            onClick={() => setFilterModal(true)}
          >
            Filter
          </div>
          <div
            className="brelly-text-btn ms-4"
            style={{ color: "#00ACB0" }}
            onClick={() => setAddModal(true)}
          >
            Add
          </div>
        </div>
      </div>
      {personalItems?.length > 0 && <TableHeader />}
      {filteredItems?.map((item, key) => (
        <InventoryItem item={item} key={key} />
      ))}
      <AddInventoryModal
        asset={asset}
        open={addModal}
        handleClose={() => setAddModal(false)}
      />
      <InventoryFilterModal
        open={filterModal}
        handleClose={() => setFilterModal(false)}
      />
      <InventoryReportModal
        open={reportModal}
        handleClose={() => setReportModal(false)}
      />
    </div>
  );
};

export default InventoryBox;

const TableHeader = () => {
  return (
    <div className="jb-ac mt-3 d-none d-lg-flex">
      <div className="dash-table-header" style={{ width: "8%" }}></div>
      <div className="dash-table-header" style={{ width: "17%" }}>
        Name
      </div>
      <div className="dash-table-header" style={{ width: "15%" }}>
        Replacement Cost
      </div>
      <div className="dash-table-header" style={{ width: "15%" }}>
        Purchase Price
      </div>
      <div className="dash-table-header" style={{ width: "15%" }}>
        Purchase Date
      </div>
      <div className="dash-table-header" style={{ width: "15%" }}>
        Location
      </div>
      <div className="dash-table-header" style={{ width: "15%" }}>
        Category
      </div>
    </div>
  );
};

const InventoryItem = ({ item }) => {
  const [editModal, setEditModal] = useState(false);

  return (
    <>
      <div
        onClick={() => setEditModal(true)}
        className="row mt-2 brelly-content"
        style={{ borderRadius: 10, height: 50 }}
      >
        <div className='dash-table-item jc-ac col-2 col-lg-1'>
          <InventoryImg item={item} />
        </div>
        <div className='dash-table-item js-ac col-10 col-lg-3'>
          {item?.name}
        </div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>
          {formatCurrency(item?.replacementCost)}
        </div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>
          {formatCurrency(item?.purchasePrice)}
        </div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>
          {item?.location}
        </div>
        <div className='dash-table-item col-2 d-none d-lg-flex'>
          {item?.category}
        </div>
      </div>
      <EditInventoryModal
        item={item}
        open={editModal}
        handleClose={() => setEditModal(false)}
      />
    </>
  );
};

const createSearchStr = (task) => {
  let name = task?.name
  let cat = task?.category
  let str = `${name} ${cat}`
  return str?.toLowerCase()
}

const getFiltered = (filters, items) => {
  let _items = items

  if (filters?.selectedCats?.length > 0) {
    let formatted = filters?.selectedCats?.map((c) => c?.label)
    _items = _items?.filter((t) => formatted?.includes(t?.category))
  }

  if (filters?.searchVal?.length > 0) {
    let formatted = _items?.map((t) => ({ ...t, searchVal: createSearchStr(t) }))
    _items = formatted?.filter((t) => t.searchVal?.includes(filters?.searchVal?.toLowerCase()))
  }

  return _items
}
