export const getIsVideo = (file: any) => {
  const type = file?.fileType;
  if (videoTypes?.includes(type)) {
    return "video";
  } else {
    return "photo";
  }
};

const videoTypes = ["mp4", "mov", "quicktime", "video", "hevc"];

export function urltoFile(url: any, filename: any, mimeType: any) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

export const dataURLtoFile = (dataurl: any, filename: any) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const isDocument = (file: any) => {
  let mimeType = file?.contentType || "";
  let contentType = mimeType?.split("/")[1];
  let docTypes = ["doc", "docx", "xml", "pdf", "csv", "xls", "xlsx", "ppt", "pptx", "txt"];
  return docTypes?.includes(contentType);
};

// export async function urlToFile(url, name, type) {
//   let defaultType = type === "video" ? "video/mp4" : "image/jpeg";
//   const response = await fetch(url);
//   const data = await response.blob();
//   return new File([data], name, {
//     type: data.type || defaultType,
//   });
// }
