// Internal Imports
import brelly from "@brelly/sdk";
import { useApp } from "context";
import { PhotoIcon } from "components/Icons";
import { btnColors } from "./constants";
import { useState, useEffect } from "react";
import { Portal } from "react-portal";
import { BsX } from "react-icons/bs";
import ProPic from "components/ProPic";
import { useWindowSize } from "react-use";

export const DashPage = ({ tab, value, children, padding = true }) => {
  const { width, height } = useWindowSize();
  let selected = tab === value;
  let contentWidth = width - 260;
  let contentHeight = height - 80;
  let delay = selected ? 260 : 0;
  let pd = width > 991 ? "0px 32px 0px 32px" : "0px 16px 0px 16px"

  return (
    <div
      style={{
        height: contentHeight,
        width: '100%',
        padding: padding ? pd : 0,
        borderRadius: "12px 0px 0px 0px",
        position: "absolute",
        top: 80,
        left: 0,
        transform: selected ? "translate(0%, 0%)" : "translate(120%, 0%)",
        transition: `all 300ms linear ${delay}ms`,
        boxShadow: "inset -4px -4px 8px rgba(0,0,0,.038)",
      }}
      className="brelly-content"
    >
      {children}
    </div>
  );
};

export const ProfilePicture = ({ img = "", style = {}, iconSize = 24 }) => {
  if (img) {
    return <img alt="" src={img} style={{ width: 80, height: 80, borderRadius: "50%", ...style }} />;
  } else {
    return (
      <div
        style={{
          width: 80,
          height: 80,
          borderRadius: "50%",
          background: "#00ACB0",
          color: "white",
          ...style,
        }}
        className="jc-ac"
      >
        <PhotoIcon stroke="#ffffff" size={iconSize} />
      </div>
    );
  }
};

export const ProfilePictureUpload = ({ img, style = {}, iconSize = 24, handleChange = () => {} }) => {
  const { setLoading, handleUser, setError, user } = useApp();

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <ProPic img={img} user={user} style={style} iconSize={iconSize} />
      <input
        style={{ width: "100%", height: "100%", opacity: 0, position: "absolute", left: 0, top: 0, zIndex: 100 }}
        type="file"
        onChange={handleChange}
        accept=".png, .jpeg, .jpg, .svg"
      />
      <div
        style={{
          position: "absolute",
          right: 6,
          bottom: 6,
          padding: 4,
          borderRadius: '50%',
          background: "#00ACB0",
        }}
      >
        <PhotoIcon size={iconSize} stroke="#fff" />
      </div>
    </div>
  );
};

export const AdjusterDisplay = ({ claim }) => {
  return (
    <div className="js-ac">
      <ProfilePicture
        style={{ width: 36, height: 36, marginRight: 8 }}
        iconSize={18}
        imageObj={{ downloadURL: claim?.proPic }}
      />
      <div>
        <h3 style={{ lineHeight: 1, marginBottom: 3 }}>
          {claim?.firstName} {claim?.lastName}
        </h3>
        <h3 style={{ lineHeight: 1, marginBottom: 0, fontSize: 13 }}>My Adjuster</h3>
      </div>
    </div>
  );
};

export const ClaimStatus = ({ claim }) => {
  return (
    <div
      style={{
        width: 130,
        height: 32,
        background: btnColors[claim?.status],
        borderRadius: 18,
        color: "white",
      }}
      className="jc-ac"
    >
      <h3 style={{ marginBottom: 0, fontWeight: 700 }}>{claim?.status}</h3>
    </div>
  );
};

export const BrellyBtn = ({ children, style = {}, className = "", onClick = () => {}, btnRef }) => {
  return (
    <div
      ref={btnRef}
      onClick={onClick}
      style={{
        padding: "6px 18px",
        minWidth: 150,
        background: "#00ACB0",
        color: "#fff",
        borderRadius: 10,
        fontSize: 16,
        fontWeight: 600,
        fontFamily: "Lato",
        textShadow: "1px 1px 1px rgba(0,0,0,.1)",
        boxShadow: "1px 1px 2px rgba(0,0,0,.2)",
        cursor: "pointer",
        ...style,
      }}
      className={`jc-ac ${className}`}
    >
      {children}
    </div>
  );
};

export const BrellyIconBtn = ({ children, style = {}, className = "", onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      style={{ height: 36, width: 36, border: "1.5px solid #00ACB0", borderRadius: 8, ...style }}
      className={`jc-ac ${className}`}
    >
      {children}
    </div>
  );
};

export const InlineModal = ({ open = false, handleClose = () => {}, children, btnRef }) => {
  const [boundingRect, setBoundingRect] = useState({});

  const getPosition = () => {
    if (boundingRect?.x) {
      return {
        left: boundingRect.x + boundingRect.width / 2,
        top: boundingRect.bottom + 6,
        transform: "translate(-50%, 0%)",
      };
    } else {
      return { left: "50%", top: "50%", transform: "translate(-50%, -50%)", opacity: 0 };
    }
  };

  useEffect(() => {
    if (btnRef?.current) {
      setBoundingRect(btnRef.current?.getBoundingClientRect());
    }
  }, [btnRef, open]);

  if (open) {
    return (
      <Portal>
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,.025)",
          }}
          onClick={handleClose}
        />
        <div
          style={{
            position: "absolute",
            background: "white",
            borderRadius: 18,
            padding: 18,
            zIndex: 1001,
            fontFamily: "Lato",
            ...getPosition(),
          }}
          className="shadow"
        >
          {children}
        </div>
      </Portal>
    );
  } else {
    return null;
  }
};

export const CenterModal = ({ open = false, handleClose = () => {}, children }) => {
  if (open) {
    return (
      <Portal>
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0,0,0,.025)",
          }}
          onClick={handleClose}
        />
        <div
          style={{
            position: "absolute",
            background: "white",
            borderRadius: 18,
            padding: 18,
            zIndex: 1001,
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
          className="shadow"
        >
          <div onClick={handleClose}>
            <BsX style={{ position: "absolute", top: 12, right: 12, color: "black", fontSize: 18 }} />
          </div>
          {children}
        </div>
      </Portal>
    );
  } else {
    return null;
  }
};
