import { useState } from 'react';
import Input from 'components/Input';
import { currentToFloat } from 'utils';
import TextArea from 'components/TextArea';
import { BrellyBtn } from '../../components';
import { DateSelect } from 'components/Select';
import { CenterModal } from 'components/Modals';
import { AddFileModal } from 'pages/Dash/Modals';
import { SingleSelect } from 'components/Select';
import {payees, coverages, paymentTypes} from 'constants';

import brelly from "@brelly/sdk";
import { useApp } from 'context';
import { useClaim } from 'pages/Dash/Claims/Claim/context';

export const AddReimbursementModal = ({ open, handleClose }) => {
  const { coverages, handlePayments, handleCoverages } = useClaim();
  const coverageOptions = coverages?.map((c, idx) => ({ ...c, label: c?.name, value: idx + 1 }))
  const { setLoading, setError, claimId } = useApp();
  const [desc, setDesc] = useState('');
  const [amount, setAmount] = useState('');
  const [date, setDate] = useState(null);
  const [uploadModal, setUploadModal] = useState(false);
  const [selectedPayee, setSelectedPayee] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [selectedCoverage, setSelectedCoverage] = useState([]);

  const handleAdd = async () => {
    try {
      setLoading(true);
      await brelly.claimPayments.create({
        paidAt: date,
        amount: currentToFloat(amount),
        description: desc,
        claimId: claimId,
        type: selectedPayment?.label,
        coverageId: selectedCoverage?.id
      })
      setLoading(false);
      handleClose()
    } catch(err) {
      setLoading(false);
      setError("There was an issue creating your expense! Please check your connection and try again")
    }
  }

  return(
    <>
      <CenterModal open={open} handleClose={handleClose}>
        <div style={{ fontWeight: 600, fontSize: 20 }}>Add Reimbursement</div>
        <Input value={amount} onChange={setAmount} label='Amount' placeholder="$" type='number' />
        <TextArea label='Description' value={desc} onChange={setDesc} style={{ height: 80 }} />
        <DateSelect value={date} onChange={setDate} label='Date' />
        <SingleSelect value={selectedCoverage} onChange={setSelectedCoverage} options={coverageOptions} label='Select Coverage' />
        <SingleSelect value={selectedPayment} onChange={setSelectedPayment} options={paymentTypes} single={true} label='Payment Type' />
        <SingleSelect value={selectedPayee} onChange={setSelectedPayee} options={payees} single={true} label='Payee' />
        <BrellyBtn onClick={() => setUploadModal(true)} className='mt-3' style={{ background: '#2D3C57' }}>Add Proof of Payment</BrellyBtn>
        <BrellyBtn onClick={handleAdd} style={{ width: 400 }} className='mt-4'>Save</BrellyBtn>
      </CenterModal>
      <AddFileModal open={uploadModal} handleClose={() => setUploadModal(false)} />
    </>
  )
}
