import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { v4 as uuidv4 } from "uuid";
import Button from 'components/Button';
import Menu from './OnboardingDashMenu';
import { useWindowSize } from "react-use";
import { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { IoArrowUndoOutline } from 'react-icons/io5';
import { getFileTypeIcon } from 'components/FileIcons';
import { BsX, BsFiletypePdf, BsFiletypeJpg } from "react-icons/bs";
import { useOnboarding } from './context';

const OnboardingDash = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, setLoading, setError, searchParams } = useApp();
  const history = useHistory();
  const { width, height } = useWindowSize();
  const contentWidth = width - 260;
  const contentHeight = window.innerHeight - 80;
  const ready = progress?.docsDone && progress?.imgsDone && progress?.emailsDone;
  const [docs, setDocs] = useState([]);
  const [imgs, setImgs] = useState([]);
  const spClaimId = searchParams.get("claimId")
  const claimId = spClaimId || progress?.claimId;

  const handleExistingFiles = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.claims.getFiles(claimId);
      if (data?.length > 0) {
        handleUpdate({ uploads: data?.files })
        let _imgs = data?.filter((d) => d?.contentType?.includes('image') || d?.contentType?.includes('video'))
        let _docs = data?.filter((d) => !d?.contentType?.includes('image') && !d?.contentType?.includes('video'))
        setImgs(_imgs?.map((f) => ({ ...f, remote: true })))
        setDocs(_docs?.map((f) => ({ ...f, remote: true })))
      }
      setLoading(false);
    } catch(err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleExistingFiles()
  }, [])

  const handleDelete = async (file, type) => {
    let allFiles = [...imgs, ...docs];

    if (type === 'imgs') {
      let remaining = imgs?.filter((img) => img?.name !== file.name)
      setImgs(remaining)
    } else {
      let remaining = docs?.filter((img) => img?.name !== file.name)
      setDocs(remaining)
    }
    try {
      setLoading(true);
      let toDelete = allFiles?.filter((f) => f?.name === file?.name)
      await Promise.all(toDelete?.map((f) => brelly.files.delete(f?.id)))
      setLoading(false);
    } catch(err) {
      setLoading(false);
      console.log(err);
    }
  }

  const handleNewMedia = async (ev, type) => {
    const arr = Array.from(ev.target.files);
    if (arr?.length > 0) {
      const formatted = arr?.map((f) => ({ file: f, id: uuidv4() }));

      if (type === 'imgs') {
        setImgs([...imgs, ...formatted]);
      } else {
        setDocs([...docs, ...formatted]);
      }

      try {
        setLoading(true);
        let uploads = await brelly.claims.uploadFiles(claimId, arr);
        let existing = progress?.uploads || [];
        handleUpdate({ uploads: [...existing, ...uploads] })
        setLoading(false);
      } catch (err) {
        setError(err.message)
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{ width: width, height: height, background: "#ffffff" }}
      className="js-as"
    >
      <Menu />
      <div
        style={{ width: contentWidth, height: height, position: "relative" }}
      >
        <Header />
        <div
          style={{
            height: contentHeight,
            width: '100%',
            padding: "24px 32px 0px 32px",
            borderRadius: "18px 0px 0px 0px",
            position: "absolute",
            top: 80,
            left: 0,
            boxShadow: "inset -4px -4px 8px rgba(0,0,0,.038)",
            overflowY: 'auto'
          }}
          className="brelly-content pb-5"
        >
          <div className='jb-ac'>
            <div>
              <h1 style={{ marginBottom: 0, textTransform: 'capitalize' }}>Welcome {user?.firstName}!</h1>
              <div className='mt-1' style={{ maxWidth: 600 }}>Let’s finish onboarding your claim for your adjuster. <b>Complete the three steps below then click “Complete Onboarding”</b></div>
            </div>
            <Button onClick={() => history.push(`/dash?tab=home&claim=${claimId}&claimtab=checklist`)} disabled={!ready}>Complete Onboarding</Button>
          </div>
          <DocsSection docs={docs} progress={progress} handleUpdate={handleUpdate} handleDelete={handleDelete} handleNewMedia={handleNewMedia} />
          <ImgsSection imgs={imgs} progress={progress} handleUpdate={handleUpdate} handleDelete={handleDelete} handleNewMedia={handleNewMedia} />
          <EmailSection progress={progress} handleUpdate={handleUpdate} />
        </div>
      </div>
    </div>
  );
}

export default OnboardingDash;

const EmailSection = ({ progress, handleUpdate }) => {
  const hide = progress?.emailsDone;

  return(
    <div className="dash-box mt-4">
      <div className="jb-ac">
        <div style={{ color: '#00ACB0' }} className="dash-box-title mt-1">Emails</div>
      </div>
      <div className='mt-3 mb-3'>Forward any emails that are related to your insurance or your claim to the email address provided by your pro. This includes all emails from your insurance company, your insurance company’s adjuster, and contractors. It does not include emails from your attorney (if applicable).</div>

      {!hide && (
        <div className='je-ac mb-3'>
          <div className='brelly-text-btn' onClick={() => handleUpdate({ emailsDone: true })}>
            Got It!
          </div>
        </div>
      )}
    </div>
  )
}

const DocsSection = ({ docs, progress, handleUpdate, handleDelete, handleNewMedia }) => {
  const hide = progress?.docsDone;

  return(
    <div className="dash-box mt-4">
      <div className="jb-ac">
        <div style={{ color: '#00ACB0' }} className="dash-box-title mt-1">Documents</div>
      </div>
      {!hide && (
        <div className='row mt-3'>
          <div className='col-6 jb-as flex-col' style={{ height: 420 }}>
            <div>
              <div style={{ fontWeight: 600 }}>Upload any claim-related documents, such as:</div>
              <Point point='Your insurance declaration pages (usually only a few pages)' />
              <Point point='Your complete insurance policy (usually at least 30-40 pages)' />
              <Point point='Documents from contractors (inspections reports, estimates, bids)' />
              <Point point='Other documents from your insurance company (letters, inspection reports, settlement offers)' />
            </div>
            <div style={{ width: '100%', height: 240, background: '#D0FEFF', border: '1px solid #00ACB0', borderRadius: 12, position: 'relative' }} className='mt-3 jc-ac flex-col'>
              <input onChange={(e) => handleNewMedia(e, 'docs')} type='file' style={{ opacity: 0, left: 0, top: 0, position: 'absolute', width: '100%', height: '100%' }} accept=".doc, .docx, .pdf, .csv, .xls, xlsx, .ppt, .pptx, .txt" multiple={true} />
              <BsFiletypePdf style={{ fontSize: 32, color: '#00ACB0' }} />
            </div>
          </div>
          <div className='col-6' style={{ height: 420, overflowY: 'auto' }}>
            {docs?.map((d, key) => <Doc d={d} key={key} handleDelete={handleDelete} />)}

            {docs?.length === 0 && (
              <div className='jc-ac' style={{ height: '90%', textAlign: 'center' }}>
                <div style={{ maxWidth: '80%' }}>You have not uploaded any <b>documents</b> yet.</div>
              </div>
            )}
          </div>
        </div>
      )}
      {!hide && (
        <div className='je-ac mt-3 mb-3'>
          <div className='brelly-text-btn' onClick={() => handleUpdate({ docsDone: true })}>
            {docs?.length > 0 ? "Mark Done" : "Mark Not Applicable"}
          </div>
        </div>
      )}
      {hide && (
        <div className='jb-ac mt-3 mb-3'>
          <b>{docs?.length} Documents</b>
          <div onClick={() => handleUpdate({ docsDone: false })} className='je-ac'>
            <IoArrowUndoOutline className='me-3' style={{ fontSize: 24, color: '#00ACB0' }} />
            <Button>{docs?.length > 0 ? "Marked Done" : "Marked Not Applicable"}</Button>
          </div>
        </div>
      )}
    </div>
  )
}

const ImgsSection = ({ imgs, progress, handleUpdate, handleDelete, handleNewMedia }) => {
  const hide = progress?.imgsDone;

  return(
    <div className="dash-box mt-4">
      <div className="jb-ac">
        <div style={{ color: '#00ACB0' }} className="dash-box-title mt-1">Photos & Videos</div>
      </div>
      {!hide && (
        <div className='row mt-3'>
          <div className='col-6 jb-as flex-col' style={{ height: 420 }}>
            <div style={{ fontWeight: 600 }}>Upload any photos or videos showing the condition of your property before or after the loss.</div>
            <div style={{ width: '100%', height: 300, background: '#D0FEFF', border: '1px solid #00ACB0', borderRadius: 12, position: 'relative' }} className='mt- flex-col jc-ac'>
              <input onChange={(e) => handleNewMedia(e, 'imgs')} type='file' style={{ opacity: 0, left: 0, top: 0, position: 'absolute', width: '100%', height: '100%' }} accept=".jpg, .jpeg, .png, .mov, .mp4, .svg" multiple={true} />
              <BsFiletypeJpg style={{ fontSize: 32, color: '#00ACB0' }} />
            </div>
          </div>
          <div className='col-6' style={{ height: 420, overflowY: 'auto' }}>
            {imgs?.map((d, key) => <Img img={d} key={key} handleDelete={handleDelete} />)}

            {imgs?.length === 0 && (
              <div className='jc-ac' style={{ height: '90%', textAlign: 'center' }}>
                <div style={{ maxWidth: '80%' }}>You have not uploaded any <b>photos or videos</b> yet.</div>
              </div>
            )}
          </div>
        </div>
      )}
      {!hide && (
        <div className='je-ac mt-3 mb-3'>
          <div className='brelly-text-btn' onClick={() => handleUpdate({ imgsDone: true })}>
            {imgs?.length > 0 ? "Mark Done" : "Mark Not Applicable"}
          </div>
        </div>
      )}
      {hide && (
        <div className='jb-ac mt-3 mb-3'>
          <b>{imgs?.length} Images & Videos</b>
          <div onClick={() => handleUpdate({ imgsDone: false })} className='je-ac'>
            <IoArrowUndoOutline className='me-3' style={{ fontSize: 24, color: '#00ACB0' }} />
            <Button>{imgs?.length > 0 ? "Marked Done" : "Marked Not Applicable"}</Button>
          </div>
        </div>
      )}
    </div>
  )
}

const Img = ({ img, handleDelete }) => {
  let src = img?.remote ? img.downloadUrl : URL.createObjectURL(img?.file);
  let name = img?.remote ? img.name : img?.file?.name;
  let size = img?.remote ? img.size : img?.file?.size;

  return(
    <div className='jb-ac mb-2'>
      <div className='js-ac'>
        <img style={{ width: 40, height: 40, borderRadius: 4, objectFit: 'cover' }} alt='' src={src} />
        <div className='ms-2'>
          {name}
          <div style={{ fontSize: 10, lineHeight: 1 }}>{Math.round(size / 1000000)}mb</div>
        </div>
      </div>
      <div className='jc-ac' onClick={() => handleDelete(img, 'imgs')}>
        <BsX style={{ fontSize: 20 }} />
      </div>
    </div>
  )
}

const Doc = ({ d, handleDelete }) => {
  let type = d?.remote ? d.contentType : d?.file?.type;
  let size = d?.remote ? d.size : d?.file?.size;
  let name = d?.remote ? d?.name : d?.file?.name

  return(
    <div className='jb-ac mb-2'>
      <div className='js-ac'>
        {getFileTypeIcon(type, { fontSize: 32, color: '#00ACB0' })}
        <div className='ms-2'>
          {name}
          <div style={{ fontSize: 10, lineHeight: 1 }}>{Math.round(size / 1000000)}mb</div>
        </div>
      </div>
      <div className='jc-ac' onClick={() => handleDelete(d)}>
        <BsX style={{ fontSize: 20 }} />
      </div>
    </div>
  )
}

const Header = () => {
  return (
    <div
      style={{
        height: 80,
        width: "100%",
        paddingRight: 42,
        cursor: "pointer",
      }}
      className="jb-ac"
    >

    </div>
  );
};

const Point = ({ point }) => {
  return(
    <div className='js-as'><div style={{ width: 5, height: 5, borderRadius: '50%', background: 'black', marginRight: 8, marginTop: 9, flexShrink: 0 }} />{point}</div>
  )
}
