import { format, subDays } from 'date-fns';

const _claims = [
  {
    ph: 'Trevor Johnson',
    address: '473 Lampart Way',
    num: '213213214213',
    carrier: 'State Farm',
    lossType: 'Fire, Freezing',
    status: 'Stuck',
    owners: [{}, {}],
    stage: 'On Hold',
    mine: true,
    le: format(subDays(new Date(), 2), 'MMM dd, yyyy'),
    img: 'https://img.staticmb.com/mbcontent/images/uploads/2022/12/Most-Beautiful-House-in-the-World.jpg'
  },
  {
    ph: 'Jacquelyn Broussard',
    address: '1824 Pierremont Rd.',
    num: '123213123222',
    carrier: 'State Farm',
    lossType: 'Fire, Freezing',
    status: 'Stuck',
    owners: [{}, {}, {}, {}],
    stage: 'On Hold',
    mine: true,
    le: format(subDays(new Date(), 4), 'MMM dd, yyyy'),
    img: 'https://res.cloudinary.com/brickandbatten/images/f_auto,q_auto/v1675439478/wordpress_assets/SmallHouseExteriors-Twitter-card-B-LOGO/SmallHouseExteriors-Twitter-card-B-LOGO.jpg?_i=AA'
  },
  {
    ph: 'Rouses Grocery',
    address: '8900 SW Expressway',
    num: '4245346435',
    carrier: 'State Farm',
    lossType: 'Fire, Freezing',
    status: 'Stuck',
    owners: [{}, {}, {}],
    stage: 'On Hold',
    le: format(subDays(new Date(), 5), 'MMM dd, yyyy'),
    business: true,
    img: 'https://images.squarespace-cdn.com/content/v1/543ee0a5e4b0d2da24b72265/1631715638299-B2IMTHEUK65HYZHADV2O/FOU_8456b.jpg?format=2500w'
  },
  {
    ph: 'David Yancey',
    address: '823 Johns Bluff Circle',
    num: '312312354543',
    carrier: 'State Farm',
    lossType: 'Fire, Freezing',
    status: 'Stuck',
    owners: [{}],
    stage: 'On Hold',
    archived: true,
    le: format(subDays(new Date(), 10), 'MMM dd, yyyy'),
    img: 'https://www.theplancollection.com/admin/CKeditorUploads/Images/Plan1041195MainImage_8_7_2016_3_1000.jpg'
  }
]

const roles = [
  { label: 'All Roles', value: 20, all: true },
  { label: 'Policyholder', value: 1 },
  { label: 'Public Adjuster', value: 2 },
  { label: 'Insurance Adjuster', value: 3 },
  { label: 'Insurance Agent', value: 4 },
  { label: 'Contractor, General', value: 5 },
  { label: 'Contractor, Roofer', value: 6 },
  { label: 'Contractor, Restoration', value: 7 },
  { label: 'Contractor, Mechanical', value: 8 },
  { label: 'Contractor, Other', value: 9 },
  { label: 'Mortgagee/Lender', value: 10 },
  { label: 'Inspector', value: 11 },
  { label: 'Attorney', value: 12 },
  { label: 'Expert, Engineering', value: 13 },
  { label: 'Expert, Hygienist', value: 14 },
  { label: 'Expert, Accountant', value: 15 },
  { label: 'Expert, Other', value: 16 },
  { label: 'Appraiser/Umpire', value: 17 },
  { label: 'Engineer', value: 18 },
  { label: 'Other', value: 19 }
]

const users = [
  {
    email: "jacquelyn.broussard@gmail.com",
    phone: "+43 (781) 248-6537",
    "location": {
      "street": "3655 manchester road",
      "city": "winchester",
      "state": "berkshire",
      "postcode": "YB2 8EJ"
    },
    first: "Jacquelyn",
    last: "Broussard",
    pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQu3Ju9VsHczt8TaeJELmGl8zkkcFbB81UDcQ&usqp=CAU",
    policyholder: true,
    claims: [_claims[1]],
    role: 'Policyholder'
  },
  {
    email: "robert.louis@worldwide-adjusters.com",
    phone: "+1 (504) 957-3721",
    "location": {
      "street": "3391 pilevangen",
      "city": "overby lyng",
      "state": "danmark",
      "postcode": 88520
    },
    first: "Robert",
    last: "Louis",
    pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQXHcxkwvow9AVZtNmO2d0xi7bEDa0Zd6j7ZkO9X7iK&s",
    company: 'Worldwide Adjusters',
    claims: [_claims[1], _claims[0]],
    role: 'Public Adjuster'
  },
  {
    email: "j.kanter1@abcroofers.com",
    phone: "+1 (504) 529-9054",
    "location": {
      "street": "6489 hermiankatu",
      "city": "parikkala",
      "state": "northern savonia",
      "postcode": 77761
    },
    first: "Jeffery",
    last: "Kanter",
    pic: "https://img.freepik.com/free-photo/waist-up-portrait-handsome-serious-unshaven-male-keeps-hands-together-dressed-dark-blue-shirt-has-talk-with-interlocutor-stands-against-white-wall-self-confident-man-freelancer_273609-16320.jpg?w=2000",
    company: 'ABC Roofers',
    claims: [_claims[2]],
    role: 'Contractor, Roofer'
  },
  {
    email: "support@rouses.com",
    phone: "+1 (978) 617-5489",
    "location": {
      "street": "7846 chester road",
      "city": "wakefield",
      "state": "shropshire",
      "postcode": "U43 3QT"
    },
    first: "Rouses",
    last: "Grocery",
    pic: "https://media.wwltv.com/assets/WWL/images/889b7bfb-daac-4c31-b7c3-2b75d3581aff/889b7bfb-daac-4c31-b7c3-2b75d3581aff_1140x641.jpg",
    policyholder: true,
    company: 'Rouses Grocery',
    claims: [_claims[2]],
    role: 'Policyholder'
  },
  {
    email: "kayla.hall@wolfeconstruction.com",
    phone: "+1 (504) 704-2189",
    "location": {
      "street": "1935 aldwins road",
      "city": "whangarei",
      "state": "gisborne",
      "postcode": 22546
    },
    first: "kayla",
    last: "hall",
    pic: "https://theadultman.com/wp-content/uploads/2021/06/High-value-woman_-Attractive-brunette-girl-in-yellow-top-smiling-.jpg",
    company: 'Wolfe Construction',
    claims: [_claims[3]],
    role: 'Contractor, General'
  },
  {
    email: "jimmie.simmons@worldwide-adjusters.com",
    phone: "+1 (617) 905-2386",
    "location": {
      "street": "1790 the grove",
      "city": "bangor",
      "state": "isle of wight",
      "postcode": "D0 5PJ"
    },
    first: "jimmie",
    last: "simmons",
    // pic: "https://www.cdc.gov/injury/images/pressroom/sme/GeryGuy_285x360.png?_=24205",
    company: 'Worldwide Adjusters',
    claims: [_claims[0], _claims[2]],
    role: 'Public Adjuster'
  },
  {
    email: "benedikt.hein@nola-electric.com",
    phone: "+1 (504) 612-2378",
    "location": {
      "street": "5245 kirchstraße",
      "city": "börde",
      "state": "hessen",
      "postcode": 99021
    },
    first: "benedikt",
    last: "hein",
    pic: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQF1tRDgXsBF0IOU54ubBUbKnU5IKRE5qvuYg&usqp=CAU",
    company: 'NOLA Electricians',
    claims: [_claims[1], _claims[2]],
    role: 'Contractor, Mechanical'
  },
  {
    email: "trevor.johnson213@gmail.com",
    phone: "+1 (504) 298-7824",
    "location": {
      "street": "6574 rue cyrus-hugues",
      "city": "berolle",
      "state": "genève",
      "postcode": 8691
    },
    first: "Trevor",
    last: "Johnson",
    pic: "https://nytco-assets.nytimes.com/2019/04/NYTCO-2019-CelesteSloman-GuyGriggs-0077_v1-headshot-square.jpg",
    policyholder: true,
    claims: [_claims[0]],
    role: 'Policyholder'
  },
  {
    email: "alston@brelly.com",
    phone: "+1 (504) 298-7824",
    "location": {
      "street": "6574 rue cyrus-hugues",
      "city": "berolle",
      "state": "genève",
      "postcode": 8691
    },
    first: "Alston",
    last: "Walker",
    pic: "https://media.licdn.com/dms/image/D5603AQH0wHLiTR1EPw/profile-displayphoto-shrink_800_800/0/1678210872194?e=1696464000&v=beta&t=Rc3cI08ctlI_5dwPV2FeKrXrGxC6DF3huDcP3LKu3_k",
    policyholder: true,
    claims: [_claims[0]],
    role: 'Policyholder'
  }
]

const claimTimelineEvents = [
  {
    day: 'Yesterday',
    date: format(subDays(new Date(), 1), 'MMM dd'),
    events: [
      { time: '2:42pm', type: 'task', by: users[1], title: 'Draft Updated Proof of Loss', name: 'Draft Updated Proof of Loss' },
      { time: '1:00pm', type: 'doc', by: users[1], title: 'John_Doe_Proof_of_loss.pdf' },
      { time: '9:15pm', type: 'call', from: users[0], to: users[1], subject: 'roofing estimates' },
      { time: '7:30am', type: 'event', title: 'Home Inspection', name: 'Home Inspection' }
    ]
  },
  {
    day: 'Today',
    date: format(subDays(new Date(), 0), 'MMM dd'),
    events: [
      { time: '8:15am', type: 'email', from: users[1], to: users[4], subject: 'FW: 1452 State Street Renovation' },
      { time: '7:30am', type: 'email', from: users[2], to: users[1], subject: '1452 State Street Renovation' }
    ]
  }
]

const getOwners = (claim: any) => {
  let formattedUsers = users?.map((u) => ({ ...u, claimNums: u?.claims?.map((uc) => uc?.num) }));
  return formattedUsers?.filter((u) => u?.claimNums?.includes(claim?.num));
}
const claims = _claims?.map((c) => ({ ...c, owners: getOwners(c) }))

const lossTypes = [
  { label: 'Wind', value: 1 },
  { label: 'Structural', value: 2 },
  { label: 'Debris Impact', value: 3 },
  { label: 'Other Impact', value: 4 },
  { label: 'Water', value: 5 },
  { label: 'Fire', value: 6 },
  { label: 'Smoke', value: 7 },
  { label: 'Lighting', value: 8 },
  { label: 'Hail', value: 9 },
  { label: 'Freezing', value: 10 },
  { label: 'Snow/Ice', value: 11 },
  { label: 'Theft', value: 12 },
  { label: 'Vandalism', value: 13 },
  { label: 'Other', value: 14 },
]

const contactRoles = [
  { label: 'Policyholder', value: 1 },
  { label: 'Public Adjuster', value: 2 },
  { label: 'Insurance Adjuster', value: 3 },
  { label: 'Insurance Agent', value: 4 },
  { label: 'Contractor, General', value: 5 },
  { label: 'Contractor, Roofer', value: 6 },
  { label: 'Contractor, Restoration', value: 7 },
  { label: 'Contractor, Mechanical', value: 8 },
  { label: 'Contractor, Other', value: 9 },
  { label: 'Mortgagee/Lender', value: 10 },
  { label: 'Inspector', value: 11 },
  { label: 'Attorney', value: 12 },
  { label: 'Expert, Engineering', value: 13 },
  { label: 'Expert, Hygienist', value: 14 },
  { label: 'Expert, Accountant', value: 15 },
  { label: 'Expert, Other', value: 16 },
  { label: 'Appraiser/Umpire', value: 17 },
  { label: 'Engineer', value: 18 },
  { label: 'Other', value: 19 }
]

const inventoryCats = [
  { label: "Appliances", value: 1 },
  { label: "Art", value: 2 },
  { label: "Books", value: 3 },
  { label: "Cash", value: 4 },
  { label: "Clothing", value: 5 },
  { label: "Collectables", value: 6 },
  { label: "Computers & Accessories", value: 7 },
  { label: "Electronics", value: 8 },
  { label: "Equitment", value: 9 },
  { label: "Food", value: 10 },
  { label: "Furniture", value: 11 },
  { label: "Healthcare Items & Medications", value: 12 },
  { label: "Housewares", value: 13 },
  { label: "Jewelery", value: 14 },
  { label: "Pet Supplies", value: 15 },
  { label: "School Supplies", value: 16 },
  { label: "Stereos", value: 17 },
  { label: "Televisions", value: 18 },
  { label: "Tools", value: 19 }
]

const coverages = [
  { label: 'Coverage A - Dwelling', value: 1 },
  { label: 'Coverage B - Other Structures', value: 2 },
  { label: 'Coverage C - Personal Property', value: 3 },
  { label: 'Coverage D - Loss of Use', value: 4 },
]

const paymentTypes = [
  { label: 'Cash', value: 1},
  { label: 'Check', value: 2},
  { label: 'Card', value: 3},
  { label: 'Wire', value: 4},
]

const payees = [
  { label: 'Home Depot', value: 1},
  { label: 'State Farm', value: 2},
  { label: 'Harry Fox', value: 3},
  { label: 'Holiday Inn', value: 4},
]

export { claims, users, contactRoles, inventoryCats, claimTimelineEvents, coverages, paymentTypes, payees, lossTypes, roles };

export {
  roofOptions,
  constructionOptions,
  lastTwoHundredYears,
  oneToTen,
  sidingOptions,
  roomTypes,
  colorOptions,
  colorOptionsArr,
  personalItemCategories,
  interactionTypes,
  assetAttributeTypes
} from "./options";
