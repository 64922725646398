import { useState } from "react";
import { format } from 'date-fns';
import { LossTypeItem } from './Item';
import { EditModal } from './EditModal';
import { LightningIcon } from "components/Icons";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { DetailItem } from 'pages/Dash/Claims/Claim/Details/components';

const getDol = (claim) => {
  if (claim?.dateOfLoss) {
    return format(new Date(claim?.dateOfLoss), "MM/dd/yyyy")
  } else {
    return "Unknown"
  }
}

export const Claim = () => {
  const { claim } = useClaim();
  const perilType = claim?.perilType;
  const lossTypes = perilType?.split(",");
  const [lossTypeModal, setLossTypeModal] = useState(false);

  return (
    <>
      <div className="dash-box dash-box-padded mt-2 mt-lg-0">
        <div className="jb-ac" style={{ marginBottom: 10 }}>
          <div className="dash-box-title">
            <LightningIcon style={{ marginRight: 8 }} stroke="#00ACB0" />
            Claim
          </div>
          <div
            className="brelly-text-btn"
            onClick={() => setLossTypeModal(true)}
            style={{ color: "#00ACB0" }}
          >
            Edit
          </div>
        </div>
        <DetailItem title='Claim Number' value={claim?.claimNumber} />
        <DetailItem title='Loss Date' value={getDol(claim)} />
        <div className="dash-table-item mt-3" style={{ color: "#64666B" }}>
          Loss Types
        </div>
        <div className="js-ac" style={{ flexWrap: "wrap" }}>
          {lossTypes?.map((lt, key) => (
            <LossTypeItem type={lt} key={key} />
          ))}
        </div>
      </div>
      <EditModal
        open={lossTypeModal}
        lossTypes={lossTypes}
        handleClose={() => setLossTypeModal(false)}
      />
    </>
  );
};
