import { useState, useRef } from "react";
import {
  getMonth,
  getDaysInMonth,
  startOfMonth,
  getDay,
  endOfMonth,
  getYear,
  isSameDay,
  format,
  addMonths,
  subMonths,
  addYears,
  subYears,
  isSameMonth,
  isSameYear,
  setYear,
  setMonth,
} from "date-fns";
import {
  IoChevronForwardOutline,
  IoChevronBackOutline,
  IoChevronDownOutline,
} from "react-icons/io5";
import { InlineModal } from "components/Modals";

const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const yearOptions = [
  subYears(new Date(), 7),
  subYears(new Date(), 6),
  subYears(new Date(), 5),
  subYears(new Date(), 4),
  subYears(new Date(), 3),
  subYears(new Date(), 2),
  subYears(new Date(), 1),
  subYears(new Date(), 0),
  addYears(new Date(), 1),
  addYears(new Date(), 2),
  addYears(new Date(), 3),
  addYears(new Date(), 4),
];

const Calendar = ({ value = new Date(), onChange = () => {}, style = {} }) => {
  const today = new Date();
  const labelRef = useRef(null);
  const [monthYearModal, setMonthYearModal] = useState(false);
  const { paddingDays, days, endPaddingDays } = createDays(value);
  const seventh = 200 / 7;
  const time = value ? format(new Date(value), 'hh:mm') : '12:00';

  const nextMonth = () => {
    onChange(addMonths(value, 1));
  };

  const lastMonth = () => {
    onChange(subMonths(value, 1));
  };

  return (
    <div style={{ width: 200, fontSize: 14, lineHeight: 1, ...style }}>
      <div className="jb-ac mb-4" style={{ color: "black" }} ref={labelRef}>
        <div style={{ fontSize: 16, fontWeight: 600 }} className="js-ac">
          {format(value, "MMMM yyyy")}
          <IoChevronDownOutline
            onClick={() => setMonthYearModal(true)}
            style={{ color: "#9DA3AE", marginLeft: 4, fontSize: 14 }}
          />
        </div>
        <div className="js-ac" style={{ color: "#9DA3AE" }}>
          <div onClick={lastMonth}>
            <IoChevronBackOutline />
          </div>
          <div onClick={nextMonth}>
            <IoChevronForwardOutline />
          </div>
        </div>
      </div>
      <div
        className="js-ac mb-1"
        style={{ fontSize: 10, fontWeight: 500, color: "#9DA3AE" }}
      >
        {daysOfWeek.map((day, key) => (
          <div key={key} style={{ width: seventh }} className="jc-ac">
            {day}
          </div>
        ))}
      </div>
      <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        {paddingDays?.map((day, key) => (
          <div
            onClick={() => onChange(day?.date)}
            key={key}
            style={{
              width: seventh,
              height: seventh,
              cursor: "pointer",
              borderRadius: "50%",
              color: getColor(day, value, true),
              background: getBg(day, value),
            }}
            className="jc-ac"
          >
            {day?.day}
          </div>
        ))}
        {days?.map((day, idx) => (
          <div
            onClick={() => onChange(day?.date)}
            key={idx}
            style={{
              width: seventh,
              height: seventh,
              cursor: "pointer",
              borderRadius: "50%",
              color: getColor(day, value),
              background: getBg(day, value),
            }}
            className="jc-ac"
          >
            {day?.day}
          </div>
        ))}
        {endPaddingDays?.map((day, key) => (
          <div
            onClick={() => onChange(day?.date)}
            key={key}
            style={{
              width: seventh,
              height: seventh,
              cursor: "pointer",
              borderRadius: "50%",
              color: getColor(day, value, true),
              background: getBg(day, value),
            }}
            className="jc-ac"
          >
            {day?.day}
          </div>
        ))}
      </div>
      <div className="jc-ac mt-2" style={{}}>
        <div
          className="brelly-text-btn"
          style={{ fontSize: 12 }}
          onClick={() => onChange(today)}
        >
          Reset
        </div>
      </div>
      <MonthYearModal
        open={monthYearModal}
        handleClose={() => setMonthYearModal(false)}
        btnRef={labelRef}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default Calendar;

const MonthYearModal = ({ open, btnRef, handleClose, value, onChange }) => {
  const handleYear = (date) => {
    let year = getYear(date);
    onChange(setYear(value, year));
  };

  const handleMonth = (month) => {
    onChange(setMonth(value, month));
  };

  return (
    <InlineModal btnRef={btnRef} open={open} handleClose={handleClose} style={{ zIndex: 200001 }} blurStyle={{ zIndex: 200000 }}>
      <div className="jb-ac" style={{ width: 120 }}>
        <div style={{ width: "50%" }}>
          {monthsOfYear?.map((m, idx) => (
            <div
              key={idx}
              onClick={() => handleMonth(idx)}
              style={{
                width: "100%",
                background: isSameMonth(
                  new Date(`${idx + 1}/01/${getYear(value)}`),
                  value
                )
                  ? "rgb(233, 245, 255)"
                  : "",
                cursor: "pointer",
              }}
              className="jc-ac"
            >
              {m}
            </div>
          ))}
        </div>
        <div style={{ width: "50%" }}>
          {yearOptions?.map((m, idx) => (
            <div
              key={idx}
              onClick={() => handleYear(m)}
              style={{
                width: "100%",
                background: isSameYear(m, value) ? "rgb(233, 245, 255)" : "",
                cursor: "pointer",
              }}
              className="jc-ac"
            >
              {format(m, "yyyy")}
            </div>
          ))}
        </div>
      </div>
    </InlineModal>
  );
};

const createDays = (value) => {
  let firstOfMonth = startOfMonth(value);
  let selectedMonth = getMonth(firstOfMonth) + 1;
  let selectedYear = getYear(firstOfMonth);
  let daysInLastMonth = getDaysInMonth(selectedMonth - 1);
  let paddingDays = [...Array(getDay(firstOfMonth)).keys()]
    .map((day, idx) => ({
      date: new Date(
        `${selectedMonth - 1}/${daysInLastMonth - (idx + 1)}/${selectedYear}`
      ),
      day: daysInLastMonth - (idx + 1),
    }))
    .reverse();
  let lastDay = endOfMonth(firstOfMonth);
  let endPaddingDays = [...Array(6 - getDay(lastDay)).keys()].map(
    (day, idx) => ({
      date: new Date(`${selectedMonth + 1}/${idx + 1}/${selectedYear}`),
      day: idx + 1,
    })
  );
  let daysToShow = getDaysInMonth(firstOfMonth);
  let days = [...Array(daysToShow).keys()].map((day, idx) => ({
    date: new Date(`${selectedMonth}/${idx + 1}/${selectedYear}`),
    day: idx + 1,
  }));

  return {
    paddingDays: paddingDays,
    days: days,
    endPaddingDays: endPaddingDays,
  };
};

const getColor = (day, value, padding) => {
  if (isSameDay(value, day?.date)) {
    return "white";
  } else if (padding) {
    return "#9DA3AE";
  } else {
    return "black";
  }
};

const getBg = (day, value) => {
  const today = new Date();

  if (isSameDay(value, day?.date)) {
    return "#00ACB0";
  } else if (isSameDay(today, day?.date)) {
    return "rgb(233, 245, 255)";
  } else {
    return "";
  }
};
