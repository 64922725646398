import { useApp } from "context";
import { useState, useEffect } from "react";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import PhoneNumberPicker from "components/PhoneNumber";
import LocationPicker from "components/LocationPicker2";
import { DashPage, ProfilePictureUpload } from "./components";
import ProPic from "components/ProPic";
import { format } from "date-fns";
import { BsCheck, BsX } from "react-icons/bs";
import { useWindowSize } from 'react-use';

const ProfilePage = () => {
  const { user, tab, setLoading, setError, handleUser } = useApp();
  const { width, height } = useWindowSize();
  const isLarge = width > 991;
  const scrollArea = isLarge ? height - 262 : '';
  const [accountTab, setAccountTab] = useState("account");
  const isSelected = tab === "profile";

  const handleProfilePic = async (ev) => {
    let file = ev.target.files[0];

    try {
      setLoading(true);
      await brelly.users.uploadProfilePic(user?.id, file);
      handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue uploading your logo, please try again!");
    }
  };

  return (
    <DashPage tab={tab} value="profile" padding={false}>
      <div
        style={{
          width: "100%",
          height: 180,
          background: "#00ACB0",
          borderRadius: "18px 0px 0px 0px",
          paddingLeft: 64,
        }}
        className="js-ac d-none d-lg-flex"
      >
        <h1 style={{ color: "#ffffff" }}>My Profile</h1>
      </div>
      <div style={{ paddingLeft: isLarge ? 64 : 18, paddingRight: isLarge ? 42 : 18 }}>
        <div
          style={{
            width: "100%",
            padding: "22px 0px",
            background: "#ffffff",
            marginTop: isLarge ? -40 : 40,
            borderRadius: 24,
            boxShadow: "3px 3px 8px rgba(0,0,0,.03)",
            cursor: "pointer",
            height: scrollArea,
          }}
          className="js-ac"
        >
          <div className="container" style={{ height: "100%" }}>
            <div className="row" style={{ height: "100%" }}>
              <div
                className="col-12 col-lg-3 js-ac flex-col"
                style={{
                  borderRight: "1px solid rgba(0,0,0,.06)",
                  padding: isLarge ? 24 : 0,
                }}
              >
                <ProfilePictureUpload img={user?.profilePic?.downloadUrl} handleChange={handleProfilePic} style={{ width: 140, height: 140 }} />
                <h2 style={{ marginTop: 16 }}>
                  {user?.firstName} {user?.lastName}
                </h2>
              </div>
              <div className="col-12 col-lg-9" style={{ height: "100%", paddingLeft: 32, paddingRight: 32 }}>
                {isSelected && <AccountSection accountTab={accountTab} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashPage>
  );
};

export default ProfilePage;

const AccountTabBtn = ({ value, label, accountTab, setAccountTab }) => {
  let isSelected = value === accountTab;

  return (
    <h3
      onClick={() => setAccountTab(value)}
      style={{
        marginTop: 24,
        fontSize: 15,
        color: isSelected ? "#00ACB0" : "#64666B",
        fontWeight: 700,
        textAlign: "left",
        width: "100%",
      }}
    >
      {label}
    </h3>
  );
};

const AccountSection = ({ accountTab }) => {
  const { user, handleUser, setLoading, setError } = useApp();
  const isSelected = accountTab === "account";
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [email, setEmail] = useState(user?.email || "");
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const userId = user?.id;
  const { width } = useWindowSize();
  const isLarge = width > 991;

  useEffect(() => {
    if (userId) {
      setFirstName(user?.firstName || "");
      setLastName(user?.lastName || "");
      setPhoneNumber(user?.phoneNumber || "");
      setEmail(user?.email || "");
    }
  }, [userId]);

  const handleUpdate = async () => {
    try {
      setLoading(true);
      await brelly.users.update("current", {
        email: email,
        lastName: lastName,
        firstName: firstName,
        phoneNumber: phoneNumber,
      });
      await handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue updating your account, please try again");
    }
  };

  // const handlePasswordChange = async () => {
  //   if (!currentPassword) {
  //     setError("Please enter your current password");
  //   } else if (!newPassword) {
  //     setError("Please enter a new password");
  //   } else if (newPassword === currentPassword) {
  //     setError("Current password and new password cannot be the same");
  //   } else {
  //     try {
  //       setLoading(true);
  //       await brelly.auth.changePassword({
  //         oldPassword: currentPassword,
  //         newPassword: newPassword,
  //       });
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       setError("There was an issue updating your account, please try again");
  //     }
  //   }
  // };

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: isLarge ? 22 : 0,
          display: isSelected ? "" : "none",
        }}
      >
        {isLarge && (
          <div className="dash-box-title" style={{ color: "black", marginBottom: 24 }}>
            Account
          </div>
        )}
        <div className="row">
          <div className="col-12 col-lg-6">
            <Input label="First Name" value={firstName} onChange={setFirstName} />
          </div>
          <div className="col-12 col-lg-6">
            <Input label="Last Name" value={lastName} onChange={setLastName} />
          </div>
          <div className="col-12 col-lg-6">
            <PhoneNumberPicker label="Phone Number" value={phoneNumber} onChange={setPhoneNumber} />
          </div>
          <div className="col-12 col-lg-6">
            <Input label="Email" value={email} onChange={setEmail} />
          </div>
        </div>
        <div className="row je-ac mt-4">
          <div className="col-12 col-lg-6">
            <BrellyBtn onClick={handleUpdate}>
              Save
            </BrellyBtn>
          </div>
        </div>
      </div>
    </>
  );
};

// <div className="jb-ac mt-4">
//   <div style={{ width: "49%" }}>
//     <Input label="Current Password" value={currentPassword} onChange={setCurrentPassword} />
//   </div>
//   <div style={{ width: "49%" }}>
//     <Input label="New Password" value={newPassword} onChange={setNewPassword} />
//   </div>
// </div>
// <div className="je-ac mt-4">
//   <BrellyBtn style={{ width: "49%" }} onClick={handlePasswordChange}>
//     Update
//   </BrellyBtn>
// </div>

const CompanySection = ({ accountTab }) => {
  const { setOrg, memberships } = useApp();
  const isSelected = accountTab === "companies";
  const [createModal, setCreateModal] = useState(false);

  return (
    <>
      <div
        style={{
          width: "100%",
          paddingTop: 22,
          display: isSelected ? "" : "none",
        }}
      >
        <div className="jb-ac" style={{ marginBottom: 24 }}>
          <div className="dash-box-title" style={{ color: "black" }}>
            Current Companies
          </div>
          <div className="brelly-text-btn" onClick={() => setCreateModal(true)}>
            Create a Company
          </div>
        </div>
        <div>
          {memberships?.map((membership, key) => (
            <div
              key={key}
              onClick={() => setOrg(membership?.id)}
              className="p-3 mb-1 js-ac"
              style={{
                background: "#D1EAFF",
                borderRadius: 10,
                fontWeight: 600,
              }}
            >
              <div className="me-2">
                <ProPic img={membership?.organization?.coverPhoto} />
              </div>
              <div style={{ width: "50%" }} className="js-ac">
                {membership?.organization?.name}
              </div>
              <div style={{ width: "25%" }} className="js-ac">
                {membership?.role}
              </div>
              <div style={{ width: "25%" }} className="je-ac">
                {format(new Date(membership?.createdAt), "MM/dd/yyyy")}
              </div>
            </div>
          ))}
        </div>
        <h3 className="mt-3 mb-2" style={{ fontSize: 15, color: "#64666B", fontWeight: 700 }}>
          Invitations
        </h3>
        <div>
          <InvitationItem />
        </div>
      </div>
      <CreateOrgModal open={createModal} setOpen={setCreateModal} />
    </>
  );
};

const InvitationItem = ({ invite }) => {
  const { setLoading, setError, handleUser } = useApp();

  const handleAccept = async () => {
    try {
      setLoading(true);
      await brelly.membershipInvites.accept(invite?.id);
      await handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue accepting this membership, please try again");
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      await brelly.membershipInvites.reject(invite?.id);
      await handleUser();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue rejecting this membership, please try again");
    }
  };

  return (
    <div className="p-3 mb-1 jb-ac brelly-content" style={{ borderRadius: 10, fontWeight: 600 }}>
      <div className="js-ac">
        <img
          alt=""
          src="/assets/logos/symbolAB.png"
          style={{
            width: 32,
            height: 32,
            borderRadius: "50%",
            objectFit: "contain",
            marginRight: 12,
            background: "white",
          }}
          className="shadow-sm"
        />
        <div>
          <div style={{ fontSize: 13, fontWeight: 400, lineHeight: 1 }}>
            {invite?.sender?.firstName} invited you to
          </div>
          {invite?.org?.name}
        </div>
      </div>
      <div onClick={handleAccept} className="je-ac" style={{ color: "white", fontSize: 24 }}>
        <div
          style={{
            width: 32,
            height: 32,
            background: "#00ACB0",
            borderRadius: "50%",
            marginRight: 12,
          }}
          className="jc-ac"
        >
          <BsCheck />
        </div>
        <div
          onClick={handleReject}
          style={{
            width: 32,
            height: 32,
            background: "#ff0000",
            borderRadius: "50%",
          }}
          className="jc-ac"
        >
          <BsX />
        </div>
      </div>
    </div>
  );
};

const CreateOrgModal = ({ open, setOpen }) => {
  const { user, setLoading, handleMemberships, setError } = useApp();
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreate = async () => {
    try {
      setLoading(true);
      await brelly.organizations.create({
        ownerId: user?.id,
        name: name,
        website: website,
        phoneNumber: phoneNumber,
        description: description,
      });
      handleMemberships();
      setLoading(false);
      handleClose();
    } catch (err) {
      setError(err?.message);
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <Input label="Name" value={name} onChange={setName} placeholder="Bill" />
      <Input label="Website" value={website} onChange={setWebsite} placeholder="Theodore" />
      <Input label="Phone Number" value={phoneNumber} onChange={setPhoneNumber} placeholder="Walton" />
      <Input
        label="Description"
        value={description}
        onChange={setDescription}
        placeholder="Portland Trailblazers"
      />
      <BrellyBtn style={{ width: 300 }} className="mt-4" onClick={handleCreate}>
        Create
      </BrellyBtn>
    </CenterModal>
  );
};
