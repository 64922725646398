// External Imports
import { useState, useContext, createContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useApp } from 'context';

// Internal Imports
import brelly from "@brelly/sdk";

const OBContext = createContext();

export default function OBContextWrapper({ children }) {
  const history = useHistory();
  const [progress, setProgress] = useState({});
  const { searchParams, setLoading } = useApp();
  const [forwardPages, setForwardPages] = useState([]);
  const [previousPages, setPreviousPages] = useState([]);
  const obtab = searchParams.get("obtab");

  const handleUpdate = (changes) => {
    setProgress({ ...progress, ...changes });
  }

  const setOnboardTab = (tab) => {
    setPreviousPages([...previousPages, obtab])
    searchParams.set("obtab", tab);
    history.replace({ search: searchParams.toString() });
  }

  const goBack = () => {
    if (previousPages?.length > 0) {
      let last = previousPages?.find((p, idx) => idx === previousPages?.length - 1);
      let remaining = previousPages?.filter((p, idx) => idx !== previousPages?.length - 1);
      setPreviousPages(remaining);
      setForwardPages([obtab, ...forwardPages])
      searchParams.set("obtab", last);
      history.replace({ search: searchParams.toString() });
    }
  }

  const goForward = () => {
    if (forwardPages?.length > 0) {
      let next = forwardPages?.find((p, idx) => idx === 0);
      let remaining = forwardPages?.filter((p, idx) => idx !== 0);
      setForwardPages(remaining);
      setOnboardTab(next)
    }
  }

  const onLocalState = {
    obtab,
    goBack,
    progress,
    goForward,
    forwardPages,
    handleUpdate,
    setOnboardTab,
    previousPages,
    setForwardPages,
    setPreviousPages
  };

  return <OBContext.Provider value={onLocalState}>{children}</OBContext.Provider>;
}

export function useOnboarding() {
  return useContext(OBContext);
}
