import {format, isDate} from 'date-fns';

export const handleDate = (date: any, display = 'MM/dd/yyyy') => {
  let dateObject = new Date(date);

  if (isDate(dateObject)) {
    return format(dateObject, display)
  } else {
    return null;
  }
}

export const formatDollars = (decimal: any) => {
  if (decimal) {
    return  `$${decimal}`
  } else {
    return decimal
  }
}

export const formatDecimal = (_value: any) => {
  if (_value) {
    let value = `${_value}`
    let numeric = value.replace(/\D/g,'');
    return parseFloat(numeric).toLocaleString("en-US");
  } else {
    return _value
  }
}

export const currentToFloat = (value: string) => {
  if (value) {
    if (typeof value === 'number') {
      return value
    } else {
      return Number(value.replace(/[^0-9.-]+/g,""));
    }
  } else {
    return ""
  }
}

export const formatCurrency = (num: any) => {
  if (num) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(num)
  } else {
    return 0
  }
}

export const formatNumber = (num: any) => {
  if (num) {
    return new Intl.NumberFormat().format(num)
  } else {
    return 0
  }
}

export const bytesToMb = (bytes: number) => {
  if (bytes) {
    let total = bytes / 1000000
    return `${total.toFixed(2)}mb`
  } else {
    return ''
  }
}
