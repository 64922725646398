import { useState } from "react";
import { LinkItem } from './Item';
import { LinksIcon } from "components/Icons";
import { AddLinkModal } from "pages/Dash/Modals";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { EmptyState } from 'pages/Dash/Claims/Claim/Details/components';

export const Links = () => {
  const { claim } = useClaim();
  const [addLinkModal, setAddLinkModal] = useState(false);
  const links = claim?.links || [];
  const formattedLinks = links?.map((l) => l?.split("==="));

  return (
    <>
      <div className="dash-box dash-box-padded">
        <div className="jb-ac" style={{ marginBottom: 10 }}>
          <div className="dash-box-title">
            <LinksIcon style={{ marginRight: 8 }} stroke="#00ACB0" />
            Important Links
          </div>
        </div>
        {formattedLinks?.map((link, key) => (
          <LinkItem key={key} link={link} />
        ))}
        <EmptyState type="Links" cb={setAddLinkModal} arr={links} />
      </div>
      <AddLinkModal
        open={addLinkModal}
        handleClose={() => setAddLinkModal(false)}
      />
    </>
  );
};
