import { useState, useEffect, useRef } from "react";
import Calendar from "./Calender";
import { Portal } from "react-portal";
import CheckBox from "components/CheckBox";
import { BrellyBtn } from "../pages/Dash/components";
import { BsChevronDown, BsCalendar2Week, BsClock } from "react-icons/bs";
import { format, setMinutes, getHours, getMinutes, setHours } from "date-fns";

export const SingleSelect = ({
  value = {},
  onChange = () => {},
  options = [],
  style = {},
  placeholder,
  label,
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value;
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    return option?.value === value?.value;
  };

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleSelect = (val) => {
    setFocus(false);
    onChange(val);
  };

  return (
    <div className="" style={{ position: "relative" }}>
      {label && (
        <div
          style={{
            fontSize: 12,
            paddingLeft: 4,
            lineHeight: 1,
            marginBottom: 2,
          }}
          className="mt-2"
        >
          {label}
        </div>
      )}
      <div
        style={{ position: "absolute", right: 8, top: 20 }}
        onClick={() => setFocus(true)}
      >
        <BsChevronDown style={{ color: "#00ACB0" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          cursor: "pointer",
          outline: focus ? "1px solid #00ACB0" : "",
          ...style,
        }}
      >
        {value?.label}
      </div>

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 14,
              paddingLeft: 12,
              paddingRight: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                      marginTop: idx === 0 ? 12 : 6,
                      marginBottom: idx === options?.length - 1 ? 12 : 0,
                    }}
                    className="js-ac ps-2"
                  >
                    <CheckBox
                      title={option.label}
                      value={isSelected(option)}
                      setValue={() => {}}
                    />
                  </div>
                ))}
              {options?.length === 0 && (
                <div className="jc-ac mb-2">No Options</div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export const MultiSelect = ({
  value = [],
  onChange = () => {},
  options = [],
  style = {},
  chevronStyle = {},
  placeholder,
  label,
  wrapperClass = "",
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueLength = value?.length;
  const valueLabels = value?.map((v) => v?.label);
  const valueStr = valueLabels?.join(", ");
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  const isSelected = (option) => {
    const formattedValues = value?.map((v) => v.value);
    return !!formattedValues?.includes(option?.value);
  };

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueLength]);

  const handleSelect = (option) => {
    if (option?.all) {
      onChange([option]);
    } else {
      let nonAllValues = value?.filter((v) => !v?.all && v?.label !== "All");
      let valueValues = nonAllValues?.map((v) => v?.value);

      if (valueValues?.includes(option?.value)) {
        let remaining = nonAllValues?.filter((v) => v?.value !== option?.value);
        onChange(remaining);
      } else {
        onChange([...nonAllValues, option]);
      }
    }
  };

  return (
    <div
      className={wrapperClass}
      style={{ position: "relative", width: "100%" }}
    >
      {label && (
        <div
          style={{
            fontSize: 12,
            paddingLeft: 4,
            lineHeight: 1,
            marginBottom: 2,
          }}
          className="mt-2"
        >
          {label}
        </div>
      )}
      <div
        style={{
          position: "absolute",
          right: 0,
          bottom: 7,
          paddingRight: 8,
          paddingLeft: 3,
          background: "#F5F5F5",
          ...chevronStyle,
        }}
        onClick={() => setFocus(true)}
      >
        <BsChevronDown style={{ color: "#00ACB0" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          cursor: "pointer",
          outline: focus ? "1px solid #00ACB0" : "",
          overflowX: "scroll",
          whiteSpace: "nowrap",
          minWidth: 208,
          width: "100%",
          ...style,
        }}
      >
        {valueStr}
      </div>
      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 14,
              padding: 12,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x,
              top: boundingRect.bottom + 6,
              width: boundingRect.width,
            }}
            className="shadow"
          >
            <div style={{ maxHeight: maxHeight, overflowY: "auto" }}>
              {options?.length > 0 &&
                options.map((option, key) => (
                  <div
                    key={key}
                    onClick={() => handleSelect(option)}
                    style={{
                      width: "100%",
                      height: 38,
                      borderRadius: 10,
                      background: "white",
                      fontSize: 14,
                      cursor: "pointer",
                    }}
                    className="mb-2 js-ac ps-2"
                  >
                    <CheckBox
                      title={option.label}
                      value={isSelected(option)}
                      setValue={() => {}}
                    />
                  </div>
                ))}
              {options?.length === 0 && (
                <div className="jc-ac mb-2">No Options</div>
              )}
            </div>
            <BrellyBtn
              style={{ marginTop: 12 }}
              onClick={() => setFocus(false)}
            >
              Done
            </BrellyBtn>
          </div>
        </Portal>
      )}
    </div>
  );
};

export const DateSelect = ({ value, onChange = () => {}, options = [], style = {}, placeholder, label, blur = false }) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus, valueKey])

  return(
    <div className='' style={{ position: 'relative' }}>
      {label && <div style={{ fontSize: 12, paddingLeft: 4, lineHeight: 1, marginBottom: 2 }} className='mt-2'>{label}</div>}
      <div style={{ position: 'absolute', right: 8, bottom: 9 }} onClick={() => setFocus(true)}>
        <BsCalendar2Week style={{ color: '#00ACB0' }} />
      </div>
      <div ref={inputRef} onClick={() => setFocus(true)} className='dash-input js-ac' placeholder={placeholder} style={{ color: blur ? "hsl(0, 0%, 50%)" : "", cursor: 'pointer', outline: focus ? '1px solid #0D72C5' : '', ...style }}>
        {value && format(value, 'MMM dd, yyyy')}
      </div>

      {focus && (
        <Portal>
          <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 1009 }} onClick={() => setFocus(false)} />
          <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 14, padding: 16, zIndex: 1010, fontFamily: 'Lato', left: boundingRect.x + boundingRect?.width / 2, top: boundingRect.bottom + 6, width: 232, transform: 'translate(-50%, 0%)' }} className='shadow'>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              <Calendar value={value || new Date()} onChange={onChange} />
            </div>
          </div>
        </Portal>
      )}
    </div>
  )
}

export const TimeSelect = ({
  value = new Date(),
  onChange = () => {},
  options = [],
  style = {},
  placeholder,
  label,
}) => {
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const valueKey = value?.value;
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const maxHeight = screenHeight - inputBottom - 110;
  const _hours = getHours(value);
  const isPm = _hours > 12;
  const hours = isPm ? _hours - 12 : _hours;
  const minutes = getMinutes(value);

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect());
    }
  }, [inputRef, focus, valueKey]);

  const handleHours = (inputHours) => {
    let newHours = isPm ? inputHours + 12 : inputHours;
    let newValue = setHours(value, newHours);
    onChange(newValue);
  };

  const handleMinutes = (inputMinutes) => {
    let newValue = setMinutes(value, inputMinutes);
    onChange(newValue);
  };

  const handlePm = () => {
    if (!isPm) {
      let newValue = setHours(value, _hours + 12);
      onChange(newValue);
    }
  };

  const handleAm = () => {
    if (isPm) {
      let newValue = setHours(value, _hours - 12);
      onChange(newValue);
    }
  };

  return (
    <div className="" style={{ position: "relative" }}>
      {label && (
        <div
          style={{
            fontSize: 12,
            paddingLeft: 4,
            lineHeight: 1,
            marginBottom: 2,
          }}
          className="mt-2"
        >
          {label}
        </div>
      )}
      <div
        style={{ position: "absolute", right: 8, top: 19 }}
        onClick={() => setFocus(true)}
      >
        <BsClock style={{ color: "#00ACB0" }} />
      </div>
      <div
        ref={inputRef}
        onClick={() => setFocus(true)}
        className="dash-input js-ac"
        placeholder={placeholder}
        style={{
          cursor: "pointer",
          outline: focus ? "1px solid #00ACB0" : "",
          ...style,
        }}
      >
        {format(value, "K:mm bb")}
      </div>

      {focus && (
        <Portal>
          <div
            style={{
              position: "fixed",
              left: 0,
              top: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,.025)",
              zIndex: 1009,
            }}
            onClick={() => setFocus(false)}
          />
          <div
            style={{
              position: "absolute",
              background: "#F5F5F5",
              borderRadius: 14,
              padding: 16,
              zIndex: 1010,
              fontFamily: "Lato",
              left: boundingRect.x + boundingRect?.width / 2,
              top: boundingRect.bottom + 6,
              width: 224,
              transform: "translate(-50%, 0%)",
            }}
            className="shadow"
          >
            <div
              style={{ maxHeight: maxHeight, overflowY: "auto", padding: 2 }}
              className="jc-as"
            >
              <input
                value={hours}
                onChange={(e) => handleHours(e.target.value)}
                type="number"
                style={{
                  width: 60,
                  height: 40,
                  background: "white",
                  textAlign: "center",
                  paddingLeft: 0,
                }}
                className="dash-input me-2"
              />
              <input
                value={minutes}
                onChange={(e) => handleMinutes(e.target.value)}
                type="number"
                style={{
                  width: 60,
                  height: 40,
                  background: "white",
                  textAlign: "center",
                  paddingLeft: 0,
                }}
                className="dash-input me-3"
              />
              <div
                style={{
                  cursor: "pointer",
                  height: 40,
                  border: "1px solid #00ACB0",
                  borderRadius: 10,
                  width: 60,
                  fontSize: 12,
                  fontWeight: 600,
                  overflow: "hidden",
                }}
              >
                <div
                  onClick={handleAm}
                  style={{
                    width: "100%",
                    height: 20,
                    background: isPm ? "" : "rgb(233, 245, 255)",
                    color: isPm ? "rgb(100, 102, 107)" : "#00ACB0",
                    borderBottom: "1px solid #00ACB0",
                  }}
                  className="jc-ac"
                >
                  AM
                </div>
                <div
                  onClick={handlePm}
                  style={{
                    width: "100%",
                    height: 20,
                    background: isPm ? "rgb(233, 245, 255)" : "",
                    color: isPm ? "#00ACB0" : "rgb(100, 102, 107)",
                  }}
                  className="jc-ac"
                >
                  PM
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};
