import { IoIosArrowRoundBack, IoIosArrowRoundForward } from 'react-icons/io';
import { IoChevronBackOutline, IoChevronForward } from 'react-icons/io5'
import { Transition } from 'react-transition-group';
import { useRef, useState, useEffect } from 'react';
import { useOnboarding } from './context';
import { useApp } from 'context';

export const OnboardingPage = ({ tab, children }) => {
  const { forwardPages, previousPages, goBack, goForward, obtab } = useOnboarding();
  const { load } = useApp();
  const nodeRef = useRef(null);
  const canGoForward = forwardPages?.length > 0;
  const canGoBack = previousPages?.length > 0;
  const [localOpen, setLocalOpen] = useState(false);
  const lastPage = previousPages[previousPages?.length -1]
  const open = tab === obtab;

  const getPosition = () => {
    if (open) {
      return { transform: 'translate(0%, 0%)', opacity: 1 }
    } else if (previousPages?.includes(tab)) {
      return { transform: 'translate(-150%, 0%)', opacity: 0 }
    } else {
      return { transform: 'translate(150%, 0%)', opacity: 0 }
    }
  }

  useEffect(() => {
    if (open) {
      setLocalOpen(true)
    } else if (lastPage === tab) {
      setTimeout(() => setLocalOpen(false), 500)
    }
  }, [open, load])

  return(
    <div style={{ ...defaultStyle, ...getPosition() }} className='jc-ac flex-col'>
      {localOpen && (
        <>
          {children}
          {tab !== "dash" && (
            <div className='jc-ac mt-4'>
              <div onClick={goBack} style={{ width: 34, height: 34, borderRadius: '50%', background: '#00ACB0', color: 'white', opacity: canGoBack ? 1 : 0.35 }} className='me-3 jc-ac'>
                <IoChevronBackOutline />
              </div>
              <div onClick={goForward} style={{ width: 34, height: 34, borderRadius: '50%', background: '#00ACB0', color: 'white', opacity: canGoForward ? 1 : 0.35 }} className='ms-3 jc-ac'>
                <IoChevronForward />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export const OnboardingHeader = ({ header, subheader, preheader }) => {
  const maxWidth = window.innerWidth * 0.9;
  const width = Math.min(820, maxWidth);

  return(
    <>
      {preheader && <div style={{ fontSize: 18, color: '#00ACB0', fontFamily: 'Lato', marginTop: -26 }}>{preheader}</div>}
      {header && <h1 style={{ maxWidth: width, textAlign: 'center' }}>{header}</h1>}
      {subheader && <h2 style={{ maxWidth: width, textAlign: 'center' }}>{subheader}</h2>}
    </>
  )
}

export const OnboardingBox = ({ header, children, style = {} }) => {
  const maxWidth = window.innerWidth * 0.9;
  const barWidth = Math.min(648, maxWidth);

  return(
    <div style={{ padding: '29px 32px', background: 'white', borderRadius: 12, marginTop: 18, width: barWidth, ...style }} className='shadow jc-ac flex-col'>
      {header && <h2 style={{ textAlign: 'center', maxWidth: 500 }}>{header}</h2>}
      {children}
    </div>
  )
}

const defaultStyle = {
  transition: `all 500ms ease-in-out`,
  width: '100vw',
  height: "100vh",
  position: 'fixed',
  opacity: 0,
  left: 0,
  top: 0
}

export const OnboardingTracker = ({ percent }) => {
  if (percent) {
    return(
      <>
        <div style={{ width: 600, maxWidth: '90%', position: 'relative', height: 2, borderRadius: 16, background: '#D8D8D8', marginTop: 48 }}>
          <div style={{ width: percent, height: 4, left: 0, top: -1, borderRadius: 16, position: 'absolute' }} className='green-bg' />
        </div>
      </>
    )
  } else {
    return null;
  }
}

export const DetailItem = ({ label, value, last = false, style = {} }) => {
  return(
    <div style={style}>
      <DetailLabel label={label} />
      <DetailValue value={value} last={last} />
    </div>
  )
}

const DetailLabel = ({ label }) => {
  return(
    <h3 className='truncate1' style={{ marginBottom: 0, fontWeight: 600, color: 'rgb(157, 163, 174)', fontSize: 12, letterSpacing: '.15mm' }}>{label}</h3>
  )
}

const DetailValue = ({ value, last }) => {
  return(
    <h3 className='truncate1' style={{ marginBottom: 0, fontWeight: 500, fontSize: 16, marginTop: 4, paddingRight: last ? 0 : 10 }}>{value || "-"}</h3>
  )
}
