import { useApp } from 'context';
import brelly from "@brelly/sdk";
import { useState } from "react";
import Input from 'components/Input';
import Button from 'components/Button';
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from './components';
import { useOnboarding } from './context';

let header = 'What is your claim number?';
let preheader = 'Your Claim';
let boxHeader = '';

const ClaimNumber = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { setError, setLoading } = useApp();
  const [claimNumber, setClaimNumber] = useState("");

  const handleNext = async () => {
    if (claimNumber) {
      try {
        setLoading(true);
        await brelly.claims.update(progress?.claimId, { claimNumber: claimNumber });
        setOnboardTab('mailingaddress');
        setLoading(false);
      } catch(err) {
        setLoading(false);
        setError(err?.message)
      }
    } else {
      setOnboardTab('mailingaddress');
    }
  }

  return(
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        <Input
          label='Claim Number'
          value={claimNumber}
          onChange={setClaimNumber}
          style={{ width: 400 }}
        />
        <Button onClick={handleNext} styles={{ width: 400, marginTop: 32 }}>
          Confirm
        </Button>
        <div onClick={handleNext} className='brelly-text-btn mt-2'>I do not know</div>
      </OnboardingBox>
      <OnboardingTracker percent='90%' />
    </>
  )
}

export default ClaimNumber;
