import { useEffect } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import Button from "components/Button";
import { useHistory } from "react-router-dom";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker } from "./components";
import { useOnboarding } from './context';

const Done = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const history = useHistory();
  const { user, setLoading, setError, handleUser, searchParams } = useApp();
  const claimId = progress?.claimId || null;
  const propertyId = progress?.propertyId || null;
  const policyId = progress?.policyId || null;
  const mailingAddressId = progress?.mailingAddressId || null;

  useEffect(() => {
    if (obtab === "done") {
      handleUser();
    }
  }, [obtab]);

  const handleDone = async () => {
    if (!claimId) {
      if (!progress?.invitation?.id) {
        setError(
          "There seems to be an issue completing your request, please reopen the link from your email and try again"
        );
      } else if (user?.claims?.length > 0) {
        setOnboardTab("existingclaims");
      } else {
        setOnboardTab("lossdate");
      }
    } else if (!propertyId) {
      if (user?.properties?.length > 0) {
        setOnboardTab("existingproperty");
      } else {
        setOnboardTab("createproperty");
      }
    } else if (!mailingAddressId) {
      setOnboardTab("mailingaddress");
    } else {
      searchParams.set("claimId", claimId);
      history.replace({ search: searchParams.toString() });
      if (progress?.policyId) {
        try {
          setLoading(true);
          await brelly.claims.update(claimId, { policyId: policyId, propertyId: propertyId });
          await brelly.projectInvites.complete(progress?.invitation?.id, {
            claimId: claimId,
            policyId: policyId,
            propertyId: propertyId,
            mailingAddressId: mailingAddressId,
          });
          setLoading(false);
          setOnboardTab("dash");
        } catch (err) {
          setLoading(false);
          setError(err?.message);
        }
      } else {
        try {
          setLoading(true);
          const { carrier, policyType } = progress;
          const policy = await brelly.policies.create({
            insuranceCompanyId: carrier?.id,
            type: policyType,
            userId: user?.id,
            claimId: claimId,
            propertyId: propertyId,
          });
          await brelly.claims.update(claimId, { policyId: policy?.id, propertyId: propertyId });
          await brelly.projectInvites.complete(progress?.invitation?.id, {
            claimId: claimId,
            policyId: policy?.id,
            propertyId: propertyId,
            mailingAddressId: mailingAddressId,
          });
          setLoading(false);
          setOnboardTab("dash");
        } catch (err) {
          setLoading(false);
          setError(err?.message);
        }
      }
    }
  };

  return (
    <>
      <OnboardingHeader header="" preheader="" />
      <OnboardingBox>
        <h1 style={{ textAlign: "center" }}>
          Congrats, you’re done with questions! We’ll complete onboarding in your personal dashboard.
        </h1>
        <Button styles={{ width: 400, marginTop: 32 }} onClick={handleDone}>
          Go to Onboarding Dashboard
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="100%" />
    </>
  );
};

export default Done;
