import { useState } from "react";
import { useApp } from "context";
import ProPic from "components/ProPic";
import ClaimImg from 'components/ClaimImg';
import CheckBox from "components/CheckBox";
import SearchBar from "components/SearchBar";
import { FilterIcon } from "components/Icons";
import { BrellyBtn } from "components/Button";
import { BsChevronDown } from "react-icons/bs";
import { AddClaimModal, FilterModal } from "pages/Dash/Modals";

const ClaimsPage = () => {
  const { setClaim, claims, user } = useApp();

  return (
    <div style={{ paddingTop: 18, height: '100%', overflow: 'auto' }}>
      <h1 style={{ marginBottom: 0, textTransform: 'capitalize' }}>Welcome {user?.firstName}!</h1>
      {claims?.map((claim) => (
        <ClaimItem key={claim?.id} claim={claim} setClaim={setClaim} />
      ))}
    </div>
  );
};

export default ClaimsPage;

const ClaimItem = ({ claim, setClaim }) => {
  let projects = claim?.projects || [];

  return (
    <div className="dash-box mt-2 dash-box-padded js-as"
      style={{ cursor: "pointer", overflow: 'hidden' }}
      onClick={() => setClaim(claim?.id)}
    >
      <ClaimImg claim={claim} />
      <div className='jb-as flex-col' style={{ height: 95 }}>
        <b className='truncate1'>{claim?.property?.address?.fullAddress || "ADDRESS"}</b>
        <div className='js-ac'>
          {projects?.map((p, idx) => (
            <div className='js-ac me-4' style={{ flexShrink: 0 }} key={idx}>
              <ProPic img={p?.organization?.logo?.downloadUrl} user={{ firstName: p?.organization?.name }} />
              <div className="ms-2">{p?.organization?.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const DetailItem = ({ value = "", width }) => {
  let _value = value || "Unknown";

  return (
    <div className="dash-table-item" style={{ width: width }}>
      {_value}
    </div>
  );
};
