import { useState, useEffect } from "react";
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import Button from "components/Button";
import { CenterModal } from "components/Modals";
import ChecklistCheckbox from "components/ChecklistCheckbox";
import { OnboardingHeader, OnboardingBox, OnboardingTracker } from "./components";
import { useOnboarding } from './context';

let preheader = "Insurance Information";

const getSubheader = (page) => {
  if (page === 1) {
    return "We seamlessly connect with your insurance company to get the info and docs you need for your claim."
  } else if (page === 2) {
    return "Choose an MFA option"
  } else {
    return getMFALabel()
  }
}

const Canopy = () => {
  const { progress, setOnboardTab } = useOnboarding();
  const carrier = progress?.carrier;
  const header = `Log into ${progress?.carrier?.name}`;
  const createUrl = carrier?.canopyData?.createAccountUrl;
  const resetPassUrl = carrier?.canopyData?.resetPasswordUrl
  const hasResetAndCreate = !!resetPassUrl && !!createUrl;
  const [page, setPage] = useState(1);

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} subheader={getSubheader(page)} />
      <OnboardingBox>
        {page === 1 && <Page1 carrier={carrier} setPage={setPage} />}
        {page === 2 && <Page2 progress={progress} setPage={setPage} />}
        {page === 3 && <Page3 progress={progress} setPage={setPage} />}
        <div className='jc-ac mt-2'>
          <div onClick={() => setOnboardTab("policytype")} className='brelly-text-btn'>Enter Information Manually</div>
        </div>
      </OnboardingBox>
      {hasResetAndCreate && (
        <div className='jb-ac mt-3' style={{ width: 620, maxWidth: '100%' }}>
          {createUrl && <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href={createUrl} className='brelly-text-btn'>Create {carrier?.name} Account</a>}
          {resetPassUrl && <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer" href={resetPassUrl} className='brelly-text-btn'>Forgot {carrier?.name} Password</a>}
        </div>
      )}
      <OnboardingTracker percent="20%" />
    </>
  );
};

export default Canopy;

const Page1 = ({ carrier, setPage }) => {
  const { handleUpdate, setOnboardTab } = useOnboarding();
  const { setLoading, setError, handleUser, addToast } = useApp();
  const [insurerUsername, setInsurerUsername] = useState("");
  const [insurerPassword, setInsurerPassword] = useState("");
  const [insurerThirdField, setInsurerThirdField] = useState("");
  const [tosModal, setTosModal] = useState(false);

  const handleComplete = async () => {
    try {
      setLoading(true);
      addToast("Hold Tight  We're working to connect with your insurance company, this could take a minute.")
      let { data } = await brelly.canopy.consentAndConnect({
        insuranceCompanyId: carrier?.id,
        insurerUsername: insurerUsername?.toLowerCase(),
        insurerPassword: insurerPassword,
        insurerThirdField,
      });

      let status = data?.status;
      if (status === "SUCCESS") {
        await handleUser();
        let properties = await brelly.users.getProperties();
        handleUpdate({ canopyResponse: { ...data }, properties: properties });
        let propertiesWithPolicies = properties?.filter((a) => a?.policies?.length > 0);
        if (propertiesWithPolicies?.length > 0) {
          setOnboardTab("canopypolicies");
        } else {
          setOnboardTab("policytype");
        }
      } else if (status === "IDENTITY_VERIFICATION_OPTIONS") {
        setPage(2);
        handleUpdate({ mfaOptions: data });
      } else if (status === "IDENTITY_VERIFICATION") {
        setPage(3);
        handleUpdate({ mfaVerify: data });
      } else if (status === "NOT_AUTHENTICATED") {
        handleUpdate({ canopyResponse: data });
        if (data?.message?.length > 0) {
          setError(data?.message);
        } else {
          setError("Could not login with those credentials");
        }
      } else {
        handleUpdate({ canopyResponse: data });
      }
    } catch (err) {
      setOnboardTab("canopyfails");
    } finally {
      setLoading(false);
    }
  };

  return(
    <>
      <Input
        label={carrier?.canopyData?.usernameFieldPlaceholder || "Email"}
        value={insurerUsername}
        onChange={setInsurerUsername}
        enterKeyHint="next"
        labelBg={{ dark: "#1a1a1a", light: "#ffffff" }}
        placeholder={carrier?.canopyData?.usernameFieldPlaceholder}
        style={{ width: 400 }}
      />
      <Input
        label="Password"
        value={insurerPassword}
        onChange={setInsurerPassword}
        type="password"
        enterKeyHint="next"
        labelBg={{ dark: "#1a1a1a", light: "#ffffff" }}
        placeholder="********"
        style={{ width: 400 }}
      />
      {carrier?.thirdFieldPlaceholder && (
        <Input
          label={carrier?.thirdFieldPlaceholder}
          value={insurerThirdField}
          onChange={setInsurerThirdField}
          enterKeyHint="next"
          labelBg={{ dark: "#1a1a1a", light: "#FAFAFA" }}
          placeholder={carrier?.canopyData?.thirdFieldPlaceholder}
          style={{ width: 400 }}
        />
      )}
      <div className='jc-ac mt-1 brelly-text-btn' onClick={() => setTosModal(true)}>Canopy Connect Terms of Service</div>
      <Button onClick={handleComplete} styles={{ width: 400, marginTop: 24 }}>
        Submit
      </Button>
      <CanopyTosModal open={tosModal} handleClose={() => setTosModal(false)} />
    </>
  )
}

const Page2 = ({ progress, setPage }) => {
  const { handleUpdate } = useOnboarding();
  const { setLoading, setError } = useApp();
  const [mfaType, setMfaType] = useState("");
  const mfaOptions = progress?.mfaOptions?.mfaOptions
    ? Object.entries(progress.mfaOptions.mfaOptions)?.map(([key, value]) => ({
        type: key,
        label: value,
      }))
    : [];

  const handleMfaSelect = async () => {
    if (mfaType) {
      try {
        setLoading(true);
        let { data } = await brelly.canopy.selectMFAOption({
          connectionId: progress?.mfaOptions?.connectionId,
          connectionJwt: progress?.mfaOptions?.connectionJwt,
          mfaOption: mfaType,
        });
        handleUpdate({ mfaVerify: data });
        setPage(3);
      } catch (err) {
        setError("There was a problem submitting your selection, please try again");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please select an option");
    }
  };

  return(
    <div>
      {mfaOptions?.map((option, key) => (
        <ChecklistCheckbox
          key={key}
          contStyle={{ width: 400 }}
          value={mfaType === option?.type}
          onChange={() => setMfaType(option.type)}
          falseInner={typeof option?.label === "string" ? option.label : ""}/>
      ))}
      <Button onClick={handleMfaSelect} styles={{ width: 400, marginTop: 32 }}>
        Next
      </Button>
    </div>
  )
}

const Page3 = ({ progress, setPage }) => {
  const { handleUpdate, setOnboardTab } = useOnboarding();
  const { setLoading, setError, handleUser, addToast } = useApp();
  const [mfaValue, setMfaValue] = useState("");

  const getPlaceHolder = () => {
    if (progress?.mfaVerify?.mfaType === "SECURITY_QUESTION") {
      return 'ANSWER'
    } else if (progress?.mfaVerify?.mfaType === "CARRIER_PIN") {
      return 'PIN'
    } else {
      return 'MFA CODE'
    }
  }

  const handleMfaSubmit = async () => {
    try {
      setLoading(true);
      addToast("Hold Tight  We're working to connect with your insurance company, this could take a minute.")
      let { data } = await brelly.canopy.verifyMFA({
        connectionId: progress?.mfaVerify?.connectionId,
        connectionJwt: progress?.mfaVerify?.connectionJwt,
        mfaValue: mfaValue,
      });
      let properties = await brelly.users.getProperties();
      handleUpdate({ canopyResponse: { ...data }, properties: properties });
      let propertiesWithPolicies = properties?.filter((a) => !!a?.policyId);
      if (propertiesWithPolicies?.length > 0) {
        setOnboardTab("canopypolicies");
      } else {
        setOnboardTab("policytype");
      }
    } catch (err) {
      setError(err?.message);
    } finally {
      setLoading(false);
    }
  };

  return(
    <div>
      <Input placeholder={getPlaceHolder()} value={mfaValue} onChange={setMfaValue} />
      <Button onClick={handleMfaSubmit} styles={{ width: 400, marginTop: 32 }}>
        Submit
      </Button>
    </div>
  )
}

const getMFALabel = (progress) => {
  const mfaVerify = progress?.mfaVerify;
  if (mfaVerify?.mfaType && typeof mfaVerify.mfaType === "string") {
    if (mfaVerify.mfaType === "EMAIL_OTP") {
      return "Please enter the code sent to your selected email address";
    } else if (mfaVerify.mfaType === "SMS_OTP") {
      return "Please enter the code sent to your selected phone number";
    } else if (mfaVerify.mfaType === "VOICE_OTP") {
      return "Please enter the code your hear";
    } else if (mfaVerify.mfaType === "CARRIER_PIN") {
      return "Please enter your insurance pin";
    } else if (mfaVerify.mfaType === "SECURITY_QUESTION") {
      return mfaVerify.mfaSecurityQuestion || "Please answer your insurance provider's security question";
    } else if (mfaVerify.mfaType === "OTHER_OTP") {
      return mfaVerify.mfaInput || "Please enter your verification code";
    } else {
      return "Please enter your verification code";
    }
  } else {
    return "Please enter your verification code";
  }
};

const CanopyTosModal = ({ open, handleClose }) => {
  const [tos, setTos] = useState({});

  useEffect(() => {
    const handleTos = async () => {
      try {
        let { data } = await brelly.canopy.getToS();
        setTos(data?.terms_html);
      } catch (err) {}
    };
    handleTos();
  }, [open]);

  return (
    <CenterModal open={open} handleClose={handleClose} style={{ background: '#f6f9fc' }}>
      <div style={{ maxHeight: 500, overflowY: 'auto', background: '#f6f9fc' }}>
        <div dangerouslySetInnerHTML={{ __html: tos }} />
      </div>
    </CenterModal>
  );
};
