import { useApp } from 'context';
import { Transition } from 'react-transition-group';
import { useRef, useEffect, useState } from 'react';

const Toasts = () => {
  const { toasts, setToasts } = useApp();

  const removeToast = (toast = {}) => {
    let remaining = toasts?.filter((t) => t?.id !== toast?.id) || [];
    setToasts(remaining);
  }

  return toasts?.map((t, idx) => <Toast toast={t} key={t?.id} idx={idx} toasts={toasts} setToasts={setToasts} removeToast={removeToast} />)
}

export default Toasts;

const Toast = ({ toast, idx, removeToast, updatePost }) => {
  const [unlock, setUnlock] = useState(false);
  const [open, setOpen] = useState(false);
  const nodeRef = useRef(null);
  const top = idx * 56 + 8;
  const animationTop = Math.max(top, 150);

  useEffect(() => {
    setOpen(true);
    setTimeout(() => setOpen(false), 15000)
    setTimeout(() => setUnlock(true), 15300)
    //eslint-disable-next-line
  }, [])

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setUnlock(true), 300)
  }

  useEffect(() => {
    if (!open && unlock) {
      removeToast(toast)
    }
    //eslint-disable-next-line
  }, [unlock, open])

  const transitionStyles = {
    entering: { transform: `translate(-50%, 0px)` },
    entered:  { transform: `translate(-50%, 0px)` },
    exiting:  { transform: `translate(-50%, -${animationTop}px)` },
    exited:  { transform: `translate(-50%, -${animationTop}px)` },
  };

  const defaultStyle = {
    transition: `all 300ms ease-in-out`,
    transform: `translate(-50%, -${animationTop}px)`,
    width: '90%', maxWidth: 700, height: 48, borderRadius: 4, background: '#333333', position: 'fixed', left: '50%', top: top, color: 'white', fontWeight: 600
  }

  return(
    <Transition nodeRef={nodeRef} in={open} timeout={300}>
      {state => (
        <div ref={nodeRef} style={{ ...defaultStyle, ...transitionStyles[state] }} className='p-3 jb-ac shadow'>
          {toast?.message}
          <div onClick={handleClose} className='brelly-text-btn hover-btn'>Dismiss</div>
        </div>
      )}
    </Transition>
  )
}
