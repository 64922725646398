import { IoCheckmarkOutline } from "react-icons/io5";

const CheckBox = ({ title, value, setValue = () => {}, className = "" }: any) => {
  return (
    <div
      onClick={(e) => setValue(!value, e)}
      className={`js-as ${className}`}
      style={{
        fontFamily: "Lato",
        cursor: "pointer",
        lineHeight: 1.2,
        fontSize: 14,
      }}
    >
      <div
        style={{
          width: 16,
          height: 16,
          border: value ? "" : "1px solid #00ACB0",
          background: value ? "#00ACB0" : "",
          borderRadius: 4,
          marginRight: 6,
          flexShrink: 0,
        }}
        className="jc-ac"
      >
        {value && <IoCheckmarkOutline style={{ color: "white", fontSize: 16 }} />}
      </div>
      {title}
    </div>
  );
};

export default CheckBox;
