import { useApp } from "context";
import brelly from "@brelly/sdk";
import Button from "components/Button";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker, DetailItem } from "./components";
import { useOnboarding } from './context';

let header =
  "Now we’ll ask about the property where the loss occurred. First, is the property for this claim listed below?";
let preheader = "Claim Property";
let boxHeader = "";

const ExistingProperty = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setError, setLoading } = useApp();
  let properties = progress?.properties;

  const handleNone = () => {
    setOnboardTab("createproperty");
  };

  const handlePropertySelect = async (asset) => {
    try {
      setLoading(true);
      handleUpdate({ propertyId: asset?.id, lossAddress: asset?.address });
      setOnboardTab("lossdate");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError("There was an issue updating your project, please try again");
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        {properties?.map((asset, key) => (
          <div
            key={key}
            className="jb-ac itemhover"
            style={{ marginBottom: 12, cursor: "pointer" }}
            onClick={() => handlePropertySelect(asset)}
          >
            <DetailItem style={{ width: "100%" }} label="Address" value={asset?.address?.fullAddress} />
          </div>
        ))}
        <div onClick={handleNone} className="brelly-text-btn">
          No, none of these
        </div>
      </OnboardingBox>
      <OnboardingTracker percent="45%" />
    </>
  );
};

export default ExistingProperty;
