import { useClaim } from "pages/Dash/Claims/Claim/context";
import { useApp } from "context";
import { useState } from "react";
import brelly from "@brelly/sdk";
import { CenterModal } from "components/Modals";
import Input from "components/Input";
import { BrellyBtn } from "components/Button";
import { DateSelect } from "components/Select";
import CompanySelect from "components/CompanySelect";

export const EditModal = ({ open, handleClose }) => {
  const { setLoading } = useApp();
  const { handleClaim, policyId } = useClaim();
  const [effective, setEffective] = useState(new Date());
  const [expiration, setExpiration] = useState(new Date());
  const [insurer, setInsurer] = useState("");
  const [policyNum, setPolicyNum] = useState("");

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.policies.update(policyId, {
        effectiveDate: effective,
        expiryDate: expiration,
        policyNumber: policyNum,
      });
      await handleClaim(false);
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Claim Info</div>
      <CompanySelect onSelect={setInsurer} />
      <Input
        value={policyNum}
        onChange={setPolicyNum}
        label="Policy Number"
      />
      <DateSelect
        value={effective}
        onChange={setEffective}
        label="Effective Date"
      />
      <DateSelect
        value={expiration}
        onChange={setExpiration}
        label="Expiration Date"
      />
      <BrellyBtn className="mt-4" onClick={handleEdit}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
