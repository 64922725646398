import brelly from "@brelly/sdk";
import { useApp } from "context";
import { format } from "date-fns";
import Button from "components/Button";
import { OnboardingPage, OnboardingHeader, OnboardingBox, OnboardingTracker, DetailItem } from "./components";
import { useOnboarding } from './context';

let header = "Okay. Is the policy for this claim one of the policies below?";
let preheader = "Insurance Policy";
let boxHeader = "";

const ExistingPolicies = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { properties, searchParams, setError, setLoading } = useApp();
  const policies = properties?.map((a) => a?.policies?.map((ap) => ({ ...ap, address: a?.address })))?.flat()

  const handleNone = () => {
    setOnboardTab("choosecarrier");
  };

  const handlePolicySelect = async (policy) => {
    try {
      setLoading(true);
      handleUpdate({ policyId: policy?.id, propertyId: policy?.propertyId });
      setOnboardTab("lossdate");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        {policies?.map((policy, key) => (
          <div
            key={key}
            className="jb-ac itemhover"
            style={{ marginBottom: 12, cursor: "pointer" }}
            onClick={() => handlePolicySelect(policy)}
          >
            <DetailItem style={{ width: "30%" }} label="Carrier" value={policy?.insuranceCompany?.name} />
            <EffectiveDate policy={policy} />
            <DetailItem last={true} style={{ width: "20%" }} label="Policy Type" value={policy?.type} />
          </div>
        ))}
        <div onClick={handleNone} className="brelly-text-btn">
          No, none of these
        </div>
      </OnboardingBox>
      <OnboardingTracker percent="15%" />
    </>
  );
};

export default ExistingPolicies;

const EffectiveDate = ({ policy }) => {
  if (policy?.effectiveDate) {
    return (
      <DetailItem
        style={{ width: "20%" }}
        label="Effective Date"
        value={format(new Date(policy?.effectiveDate), "MM/dd/yyyy")}
      />
    );
  } else {
    return <DetailItem style={{ width: "20%" }} label="Effective Date" value="-" />;
  }
};
