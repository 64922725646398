import { Details } from './Details';
import { Coverages } from './Coverages';
import { Deductibles } from './Deductibles';
import { InsuranceIcon } from "components/Icons";

export const Insurance = () => {
  return(
    <div className="dash-box mt-2 dash-box-padded">
      <div className="jb-ac">
        <div className="dash-box-title">
          <InsuranceIcon style={{ marginRight: 8 }} stroke="#00ACB0" />
          Insurance
        </div>
      </div>
      <Details />
      <Coverages />
      <Deductibles />
    </div>
  )
}
