import brelly from "@brelly/sdk";
import { useApp } from "context";
import Input from "components/Input";
import { useState, useEffect } from "react";
import { BrellyBtn } from "components/Button";
import { CenterModal } from "components/Modals";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import MortgageCompanySelect from "components/MortgageCompanySelect";

export const EditModal = ({ asset, open, handleClose }) => {
  const { setLoading } = useApp();
  const { handleClaim } = useClaim();
  const [mortgagee, setMortgagee] = useState("");
  const [mortgageNum, setMortgageNum] = useState("");

  useEffect(() => {
    if (open) {
      setMortgagee(asset?.mortgagee || {});
      setMortgageNum(asset?.mortgageLoanNumber || "");
    }
    //eslint-disable-next-line
  }, [open]);

  const handleEdit = async () => {
    try {
      setLoading(true);
      await brelly.properties.update(asset?.id, {
        mortgageeId: mortgagee?.id,
        mortgageLoanNumber: mortgageNum,
      });
      await handleClaim();
      setLoading(false);
      handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Edit Mortgage</div>
      <div style={{ width: "100%", maxWidth: 400 }}>
        <MortgageCompanySelect value={asset?.mortgagee} onSelect={setMortgagee} />
        <Input value={mortgageNum} onChange={setMortgageNum} label="Loan Number" />
      </div>
      <BrellyBtn className="mt-4" style={{ width: 400, maxWidth: "100%" }} onClick={handleEdit}>
        Save
      </BrellyBtn>
    </CenterModal>
  );
};
