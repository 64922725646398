import { useState } from 'react';
import { subYears } from 'date-fns';
import {inventoryCats} from 'constants';
import { DateSelect } from 'components/Select';
import { DropdownSearchBar } from 'components/SearchBar';
import { BrellyBtn, CenterModal } from '../../components';

import brelly from "@brelly/sdk";
import { useApp } from 'context';

export const InventoryReportModal = ({ open, handleClose }) => {
  const { setLoading } = useApp();
  const [selectedCats, setSelectedCats] = useState([]);
  const [from, setFrom] = useState(subYears(new Date(), 1));
  const [to, setTo] = useState(new Date());

  const handleCreate = async () => {
    try {
      setLoading(true);
      await brelly.projects.createInventoryDamageReport({ from: from, to: to, selectedCats: selectedCats })
      setLoading(false);
      handleClose()
    } catch(err) {
      console.log(err);
      setLoading(false);
    }
  }

  return(
    <CenterModal open={open} handleClose={handleClose}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Create Report</div>
      <DropdownSearchBar value={selectedCats} onChange={setSelectedCats} options={inventoryCats} label='Category' />
      <DateSelect value={from} onChange={setFrom} label='From' />
      <DateSelect value={to} onChange={setTo} label='To' />
      <BrellyBtn className='mt-4' onClick={handleCreate}>Create</BrellyBtn>
    </CenterModal>
  )
}
