import { useApp } from "context";
import { useState } from "react";
import brelly from "@brelly/sdk";
import Button from "components/Button";
import { OnboardingPage, OnboardingHeader, OnboardingBox, DetailItem, OnboardingTracker } from "./components";
import LocationPicker from "components/LocationPicker2";
import { useOnboarding } from './context';

let header = "Please provide the address where this loss occurred, as it appears on your policy.";
let preheader = "Claim Property";
let boxHeader = "";

const CreateProperty = () => {
  const { obtab, progress, handleUpdate, setOnboardTab } = useOnboarding();
  const { user, searchParams, setError, setLoading } = useApp();
  const [propertyAddress, setPropertyAddress] = useState("");

  const handleNext = async () => {
    try {
      setLoading(true);
      let propertyData = await brelly.addresses.create(propertyAddress);
      let property = await brelly.properties.create({ addressId: propertyData?.id, userId: user?.id });
      handleUpdate({ propertyId: property?.id });
      setOnboardTab("ownership");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(err?.message);
    }
  };

  return (
    <>
      <OnboardingHeader header={header} preheader={preheader} />
      <OnboardingBox header={boxHeader} style={{ width: 900 }}>
        <LocationPicker
          label="Address"
          style={{ width: 400 }}
          value={propertyAddress}
          setValue={setPropertyAddress}
        />
        <Button onClick={handleNext} styles={{ width: 400, marginTop: 32 }}>
          Confirm
        </Button>
      </OnboardingBox>
      <OnboardingTracker percent="50%" />
    </>
  );
};

export default CreateProperty;
