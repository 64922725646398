//External Imports
import { useState, useEffect, useRef } from "react";

//Internal Imports
import { Portal } from 'react-portal';
import Input from "components/Input";
import brelly from "@brelly/sdk";
import { useApp } from "context";
import ProPic from 'components/ProPic';

const CompanySelect = ({ value, onSelect, inLine }) => {
  const { setLoading, setError } = useApp();
  const [searchVal, setSearchVal] = useState("");
  const [companies, setCompanies] = useState([]);
  const [boundingRect, setBoundingRect] = useState({});
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);
  const screenHeight = window?.innerHeight;
  const inputBottom = boundingRect?.bottom || 0;
  const availableSpace = screenHeight - inputBottom - 30
  const maxHeight = Math.min(availableSpace, 400);

  const isSelected = (option) => {
    return option?.value === value?.value
  }

  useEffect(() => {
    if (inputRef?.current) {
      setBoundingRect(inputRef.current?.getBoundingClientRect())
    }
  }, [inputRef, focus])

  const handleCompanies = async () => {
    try {
      let { data } = await brelly.insuranceCompanies.getAll();
      setCompanies(data);
    } catch(err) {
      // setError("Could not find carrier options, please check your connection and try again")
    }
  }

  useEffect(() => {
    handleCompanies()

    if (value?.name) {
      setSearchVal(value?.name)
    }
  }, [])

  const handleResults = () => {
    if (searchVal?.length > 0) {
      // return companies
      return companies?.filter((c) => c?.name?.toLowerCase().includes(searchVal?.toLowerCase()))
    } else {
      return companies
    }
  }

  const createNew = async () => {
    try {
      setLoading(true);
      let { data } = await brelly.insuranceCompanies.create({
        name: searchVal
      })
      onSelect(data);
      setLoading(false);
    } catch(err) {
      setLoading(false);
    }
  }

  const handleSelect = (company) => {
    onSelect(company);
    setFocus(false)
    setSearchVal(company?.name)
  }

  return (
    <div style={{ width: "100%", position: "relative" }}>
      <Input
        inputRef={inputRef}
        autoComplete="off"
        value={searchVal}
        placeholder="Search"
        onChange={setSearchVal}
        label="Insurer Name"
        style={{ textTransform: "capitalize", width: 400 }}
        onFocus={() => setFocus(true)}
      />

      {(focus && !inLine) && (
        <Portal>
          <div style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.025)', zIndex: 1009 }} onClick={() => setFocus(false)} />
          <div style={{ position: 'absolute', background: '#F5F5F5', borderRadius: 14, paddingLeft: 12, paddingRight: 12, zIndex: 1010, fontFamily: 'Lato', left: boundingRect.x, top: boundingRect.bottom + 6, width: boundingRect.width }} className='shadow'>
            <div style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
              {handleResults()?.map((result, key) => (
                <SearchResult key={key} result={result} onSelect={handleSelect} />
              ))}
              <div className="js-ac" onClick={createNew} style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}>
                <ProPic user={{ firstName: searchVal }} style={{ marginRight: 10 }} />
                Create {searchVal}
              </div>
            </div>
          </div>
        </Portal>
      )}

      {inLine && (
        <div className='pt-2' style={{ maxHeight: maxHeight, overflowY: 'auto' }}>
          {handleResults()?.map((result, key) => (
            <SearchResult key={key} result={result} onSelect={handleSelect} />
          ))}
          <div className="js-ac" onClick={createNew} style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}>
            <ProPic user={{ firstName: searchVal }} style={{ marginRight: 10 }} />
            Create {searchVal}
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanySelect;

const SearchResult = ({ onSelect, result }) => {
  return (
    <div
      className="js-ac itemhover"
      onClick={() => onSelect(result)}
      style={{ width: "100%", height: 54, textTransform: "capitalize", cursor: "pointer" }}
    >
      <img
        alt=""
        src={result?.branding?.icon}
        style={{
          width: 32,
          height: 32,
          objectFit: "cover",
          marginRight: 10,
          borderRadius: "50%",
        }}
      />
      {result?.name}
    </div>
  );
};
