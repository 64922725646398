import { BsX, BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { FileUploader } from "react-drag-drop-files";
import { CenterModal } from "components/Modals";
import { BrellyBtn } from "components/Button";
import { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import Attachment from 'components/AttachmentItem';

export const SimpleFileModal = ({
  open,
  onSave,
  accept = ".jpg, .jpeg, .png, .doc, .docx, .pdf, .csv, .xls, xlsx, .mov, .mp4, .ppt, .pptx, .txt, .svg",
  multiple = true,
  handleClose,
}) => {
  const [media, setMedia] = useState([]);

  const handleRemove = (mtr) => {
    let remaining = media?.filter((m) => m?.id !== mtr);
    setMedia(remaining);
  };

  useEffect(() => {
    if (open) {
      setMedia([]);
    }
  }, [open]);

  const handleSave = () => {
    if (multiple) {
      onSave(media?.map((m) => m?.file))
    } else {
      handleClose()
    }
  }

  return (
    <CenterModal open={open} handleClose={handleClose} style={{ width: 600 }}>
      <div style={{ fontWeight: 600, fontSize: 20 }}>Add Files</div>
      <Uploader
        onSave={onSave}
        media={media}
        accept={accept}
        setMedia={setMedia}
        multiple={multiple}
        handleClose={handleClose}
      />
      <div className="row mt-2">
        {media?.map((m) => (
          <Attachment key={m?.id} file={m} handleDelete={() => handleRemove(m?.id)} />
        ))}
      </div>
      {multiple && (
        <BrellyBtn
          className="mt-3"
          onClick={handleSave}
        >
          Done
        </BrellyBtn>
      )}
    </CenterModal>
  );
};

export const Uploader = ({ media, setMedia, accept, onSave, multiple, handleClose, style = {} }) => {
  const handleChange = (files) => {
    if (multiple) {
      const arr = Array.from(files);
      const formatted = arr?.map((f) => ({ file: f, id: uuidv4() }));
      setMedia([...media, ...formatted]);
    } else {
      onSave(files)
      handleClose()
    }
  };

  return (
    <div
      style={{
        width: '100%',
        height: 140,
        border: "1px dashed #00adb0",
        background: "#cef2f3",
        borderRadius: 10,
        position: "relative",
        ...style,
      }}
      className="mt-3 jc-ac"
    >
      <FileUploader accept={accept} classes="drag-input" multiple={multiple} handleChange={handleChange} />
      <div
        style={{
          color: "#00adb0",
          fontFamily: "Lato",
          fontWeight: 600,
          lineHeight: 1.2,
          fontSize: 14,
        }}
      >
        <div style={{ textDecoration: "underline" }}>Click here to add</div>
        <div>or drag and drop</div>
      </div>
    </div>
  );
};
