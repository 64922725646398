// External Imports
import { useState } from "react";
import { useHistory } from "react-router";
import { useIonToast } from "@ionic/react";

// Internal Imports
import { useApp } from "context";
import brelly from "@brelly/sdk";
import Input from "components/Input";
import AuthPage from "components/AuthImage";
import { BrellyBtn } from "components/Button";

const VerifySignUp = () => {
  const history = useHistory();
  // @ts-expect-error TS(2339): Property 'setError' does not exist on type 'unknow... Remove this comment to see the full error message
  const { setError, setUser, setLoading } = useApp();
  const [code, setCode] = useState("");
  const [present] = useIonToast();

  const presentToast = () => {
    present({
      message: "A new code was sent to your email",
      duration: 1500,
      position: "bottom",
    });
  };

  const handleVerify = async () => {
    if (code?.length < 1) {
      setError("Please enter the code we emailed you");
    } else {
      setLoading(true);
      try {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        const user = await brelly.auth.verifyConfirmationCode(code);
        if (user && user.id) {
          setUser(user);
          setLoading(false);
          history.push("/dash");
        } else {
          setLoading(false);
          setError("Something went wrong, please try again!");
        }
      } catch (err) {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        if (err.message === "User cannot be confirmed. Current status is CONFIRMED") {
          setLoading(false);
          history.push("/dash");
        } else {
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const handleNewCode = async () => {
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    await brelly.auth.resendConfirmationCode();
    presentToast();
  };

  return (
    <AuthPage title="Verification Code">
      <h3 style={{ color: "black" }}>Enter the verification code we just sent to your email</h3>
      <Input value={code} onChange={setCode} label="Code" className="mt-3" />
      <BrellyBtn onClick={handleVerify} className="mt-3">
        Verify
      </BrellyBtn>
      <div className="jb-ac mt-3">
        <div onClick={() => history.push("/register")} style={{ fontSize: 14 }} className="brelly-text-btn">
          Back
        </div>
        <div onClick={handleNewCode} style={{ fontSize: 14 }} className="brelly-text-btn">
          Send New Code
        </div>
      </div>
    </AuthPage>
  );
};

export default VerifySignUp;
