import {
  DoubleCheckIcon,
  LogoutIcon,
  EstimatesIcon,
  CircleInfoIcon,
} from "components/Icons";
import { CenterModal } from "components/Modals";
import ProPic from "components/ProPic";
import { useState } from "react";
import { useApp } from "context";
import { useIntercom } from 'react-use-intercom';

const Menu = () => {
  const [estimateModal, setEstimateModal] = useState(false);
  const { user, handleLogout, tab, setTab } = useApp();
  const contentHeight = window.innerHeight - 90;
  const { show } = useIntercom();

  return (
    <>
      <div style={{ width: 260, height: "100vh", paddingTop: 24 }}>
        <img
          alt=""
          style={{ height: 56, marginBottom: 24, marginLeft: 26 }}
          src="/assets/logos/logoAB.png"
        />
        <div
          style={{
            height: contentHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <div
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#2D3C57",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                background: "#CEF2F3",
                fontWeight: 500,
              }}
              className="js-ac dash-menu-btn"
            >
              <DoubleCheckIcon style={{ marginRight: 12 }} stroke="#64666B" /> Onboarding Checklist
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  width: 4,
                  height: 46,
                  borderRadius: "42px 0px 0px 42px",
                  background: "#00ACB0"
                }}
              />
            </div>
            <div
              style={{
                marginTop: 12,
                marginBottom: 12,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
                textDecoration: "none",
              }}
              className="js-ac dash-menu-btn"
              onClick={show}
            >
              <EstimatesIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Brelly Chat
            </div>
          </div>
          <div style={{ paddingBottom: 24 }}>
            <div
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <CircleInfoIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Help
            </div>
            <div
              onClick={handleLogout}
              style={{
                marginTop: 4,
                height: 46,
                paddingLeft: 26,
                position: "relative",
                color: "#64666B",
                fontFamily: "Lato",
                fontSize: 18,
                cursor: "pointer",
              }}
              className="js-ac dash-menu-btn"
            >
              <LogoutIcon style={{ marginRight: 12 }} stroke="#64666B" />
              Logout
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
