import { ConfirmDeleteModal, EditLinkModal } from "pages/Dash/Modals";
import { useClaim } from "pages/Dash/Claims/Claim/context";
import { TrashIcon, EditIcon } from "components/Icons";
import { CopyBtn } from './CopyBtn';
import { useState } from "react";
import brelly from "@brelly/sdk";
import { useApp } from "context";

export const LinkItem = ({ link }) => {
  const { setLoading, setError } = useApp();
  const { claim, handleClaim } = useClaim();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editLinkModal, setEditLinkModal] = useState(false);
  const links = claim?.links || [];
  const name = link[0];
  const url = link[2];
  const id = link[1];

  const handleDelete = async () => {
    try {
      setLoading(true);
      let remaining = links?.filter((l) => !l?.includes(id));
      await brelly.claims.update(claim?.id, { links: remaining });
      handleClaim()
      setLoading(false);
    } catch(err) {
      setError("There was an issue deleting this link, please check your connection and try again")
      setLoading(false);
    }
  }

  return (
    <>
      <div className="jb-ac mt-3">
        <a
          href={`https://${url}`}
          target="blank"
          className="dash-table-item"
          style={{ fontWeight: 600, textDecoration: "underline" }}
        >
          {name}
        </a>
        <div className="je-ac">
          <CopyBtn url={url} />
          <div onClick={() => setDeleteModal(true)}>
            <TrashIcon size="18" stroke="#ff0000" />
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        open={deleteModal}
        onDelete={handleDelete}
        handleClose={() => setDeleteModal(false)}
      />
      <EditLinkModal
        id={id}
        url={url}
        name={name}
        open={editLinkModal}
        handleClose={() => setEditLinkModal(false)}
      />
    </>
  );
};
