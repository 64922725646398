import { useState, useEffect } from "react";
import { CopyIcon } from "components/Icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const CopyBtn = ({ url }) => {
  const [copied, setCopied] = useState(false);

  const handleCopied = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2200);
  };

  return (
    <div className="me-3" style={{ position: "relative" }}>
      <CopyToClipboard text={url} onCopy={handleCopied}>
        <CopyIcon size="18" stroke="#00ACB0" />
      </CopyToClipboard>
      {copied && (
        <div
          style={{
            zIndex: 10,
            cursor: "pointer",
            position: "absolute",
            left: "50%",
            bottom: 0,
            transform: "translate(-50%, 100%)",
            padding: "4px 12px 4px 12px",
            fontSize: 14,
            fontWeight: 500,
            fontFamily: "Lato",
            borderRadius: 5,
            background: "white",
          }}
          className="shadow"
        >
          Copied!
        </div>
      )}
    </div>
  );
};
